import { useEffect } from 'react';

import analyticStore from 'stores/analytics.store';
import farmStore from 'stores/farm.store';
import nutritionSolutionStore from 'stores/nutritionSolutionStore.store';
import orderStore from 'stores/order.store';
import plantStore from 'stores/plant.store';
import recipeStore from 'stores/recipe.store';

import userStore from 'stores/user.store';
import warehouseStore from 'stores/warehouse.store';
import SocketHandler from 'util/SocketHandler';
import mobxify from 'util/hocs/mobxify';
import { AnalyticWebsocket } from 'util/websocket/analytics.websocket';
import { FarmWebsocket } from 'util/websocket/farm.websocket';
import { NutritionSolutionGroupWebSocket } from 'util/websocket/nutritionSolutionGroup.websocket';
import { OrderWebsocket } from 'util/websocket/order.websocket';
import { OrderGroupWebSocket } from 'util/websocket/orderGroup.websocket';
import { PlantWebsocket } from 'util/websocket/plant.websocket';
import { PlantGroupWebsocket } from 'util/websocket/plantGroup.websocket';
import { RecipeWebsocket } from 'util/websocket/recipe.websocket';
import { RecipeGroupWebsocket } from 'util/websocket/recipeGroup.websocket';
import { WarehouseWebsocket } from 'util/websocket/warehouse.websocket';

interface WebsocketProviderProps {
  children: any;
}

const WebsocketProvider = ({ children }: WebsocketProviderProps) => {
  useEffect(() => {
    userStore?.loadMe();
  }, []);

  useEffect(() => {
    if (
      !analyticStore ||
      !orderStore ||
      !plantStore ||
      !farmStore ||
      !warehouseStore ||
      !recipeStore ||
      !nutritionSolutionStore
    )
      return;
    if (!userStore?.currentUser || !userStore?.currentUser.organization) return;
    const currentOrganization = userStore?.currentUser.organization;
    if (!currentOrganization?._id) return;

    SocketHandler.instance().connect();

    new AnalyticWebsocket(analyticStore, orderStore, currentOrganization._id);
    new OrderWebsocket(orderStore, currentOrganization._id);
    new OrderGroupWebSocket(orderStore, currentOrganization._id);
    new PlantWebsocket(plantStore, currentOrganization._id);
    new PlantGroupWebsocket(plantStore, currentOrganization._id);
    new FarmWebsocket(farmStore, currentOrganization._id);
    new WarehouseWebsocket(warehouseStore, currentOrganization._id);
    new RecipeWebsocket(recipeStore, currentOrganization._id);
    new RecipeGroupWebsocket(recipeStore, currentOrganization._id);
    new NutritionSolutionGroupWebSocket(
      nutritionSolutionStore,
      currentOrganization._id,
    );
  }, [userStore?.currentUser]);
  return <div>{children}</div>;
};

export default mobxify(
  WebsocketProvider,
  'analyticStore',
  'orderStore',
  'plantStore',
  'farmStore',
  'userStore',
  'warehouseStore',
  'recipeStore',
  'nutritionSolutionStore',
);
