enum ModalName {
  // order related modals
  CHOOSE_ORDER = 'CHOOSE_ORDER',
  DELETE_ORDER = 'DELETE_ORDER',
  CREATE_ORDER = 'CREATE_ORDER',
  // plant related modals
  CREATE_PLANTGROUP = 'CREATE_PLANTGROUP',
  PLANT_FORM = 'PLANT_FORM',
  DELETE_PLANT = 'DELETE_PLANT',
  CREATE_PLANT_RECIPE = 'CREATE_PLANT_RECIPE',
  ADD_PLANT_TYPE = 'ADD_PLANT_TYPE',
  // recipe related modals
  DELETE_RECIPE = 'DELETE_RECIPE',
  RECIPE_GROUP = 'RECIPE_GROUP',
  ARCHIVE_RECIPE = 'ARCHIVE_RECIPE',
  // nutrition solution related modals
  DELETE_NUTRITION_SOLUTION = 'DELETE_NUTRITION_SOLUTION',
  NUTRITION_SOLUTION = 'NUTRITION_SOLUTION',
  NUTRITION_SOLUTION_GROUP = 'NUTRITION_SOLUTION_GROUP',
  // warehouse related modals
  WAREHOUSE = 'WAREHOUSE',
}

export default ModalName;
