import classNames from 'classnames';
import CardSelectDropDown, { DropDownOption } from './CardSelect';
import mobxify from 'util/hocs/mobxify';

interface CardProps {
  children: React.ReactNode;
  dropDownOptions?: DropDownOption[];
}

const ListCard = ({ children, dropDownOptions }: CardProps) => {
  return (
    <li
      className={classNames([
        'bg-white',
        'col-span-1 flex flex-col text-center rounded-lg shadow hover:shadow-lg overflow-hidden relative',
      ])}
    >
      {dropDownOptions && (
        <div className="top-2 right-2 absolute z-10">
          <CardSelectDropDown dropDownOptions={dropDownOptions} />
        </div>
      )}
      {children}
    </li>
  );
};

export default mobxify(ListCard, 'modalStore', 'recipeStore');
