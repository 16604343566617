import { useFormContext } from 'react-hook-form';
import ValidatedDurationInput from './ValidatedDurationInput';
import { determineInvalidity } from '../modal/InputValidation/ValidationUtils';
import { useEffect } from 'react';

interface ValidatedDurationPickerProps {
  className?: string;
  value?: number;
  inputClassName?: string;
  index: number;
  isDisabled?: boolean;
}

const RecipeDurationPicker = ({
  index,
  isDisabled = false,
}: ValidatedDurationPickerProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (isDisabled) return;
    if (!watch(`phases.${index}.duration`)) return;
    const currentDuration = watch(`phases.${index}.duration`);
    if (watch(`phases.${index}.duration_type`) === 'days') {
      setValue(`phases.${index}.duration`, Math.ceil(currentDuration / 24));
    } else {
      setValue(`phases.${index}.duration`, currentDuration * 24);
    }
  }, [watch(`phases.${index}.duration_type`)]);

  const truthy = determineInvalidity(errors, 'duration', 'phases', index);
  const border = truthy
    ? 'border focus:outline-none focus:border-red-400 border-red-300 bg-red-50 py-2 px-3 rounded-md shadow-sm sm:text-sm'
    : 'border focus:outline-none focus:border-green-400 border-gray-300 py-2 px-3 rounded-md shadow-sm sm:text-sm';
  return (
    <div
      className={`sm:border-gray-200 flex justify-between items-center mt-4`}
    >
      <label
        htmlFor={'duration'}
        className="text-base font-medium text-gray-700"
      >
        {'Duration'}
      </label>
      <div className="flex w-3/4">
        <ValidatedDurationInput
          index={index}
          inputKey={`phases.${index}.duration`}
          valueName={`phases.${index}.duration`}
          register={register}
          border={border}
          isDisabled={isDisabled}
          defaultValue={0}
          required={true}
        />
      </div>
    </div>
  );
};

export default RecipeDurationPicker;
