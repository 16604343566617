import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { RecipeGroup } from 'models/RecipeGroup.model';
import { RecipeStore } from 'stores/recipe.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface RecipeGroupWebsocketResponse {
  data: { id: RecipeGroup['_id'] };
}

export class RecipeGroupWebsocket {
  private recipeStore: RecipeStore;
  private organizationId = '';

  constructor(recipeStore: RecipeStore, organizationId: string) {
    this.recipeStore = recipeStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.RECIPE_GROUP}`,
        (...args: WebsocketMessage<RecipeGroupWebsocketResponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.findOne(obj);
            }

            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              this.update();
            }

            if (obj.command === WEBSOCKETCOMMANDS.DELETED) {
              this.find(obj);
            }
          }
        },
      );
  }

  private async findOne(obj: RecipeGroupWebsocketResponse) {
    const { id } = obj.data;
    if (!this.recipeStore?.recipeGroup) return;
    this.recipeStore.getRecipe(id);
  }

  private async find(obj: RecipeGroupWebsocketResponse) {
    const { id } = obj.data;
    this.recipeStore?.getRecipeGroups(id, false);
  }

  private async update() {
    const url = window.location.pathname;
    const secondLastIndex = url.lastIndexOf('/', url.lastIndexOf('/') - 1);
    const id = url.substring(secondLastIndex + 1).split('/')[0];
    const isArchived = url.includes('archive');

    this.recipeStore?.getRecipeGroups(id, isArchived);
  }
}
