import { action, makeObservable, observable } from 'mobx';
import { Farm } from 'models/NavbarItem.model';
import { toast } from 'react-toastify';
import Axios from 'util/Axios';

export class FarmStore {
  @observable farms?: Farm[];
  @observable currentFarm?: Farm;

  constructor() {
    makeObservable(this);
  }

  @action
  async loadFarms() {
    try {
      const response = await Axios.get('/farms');
      if (response.status !== 200) throw new Error('No farms found');
      this.setFarms(response.data);
    } catch (error) {
      toast.error("Couldn't load farms: " + (error as Error).message);
    }
  }

  @action
  async healthCheck(farmId: string) {
    if (!farmId) return;
    const response = await Axios.get<{ status: 'OK' }>(
      `/farms/${farmId}/health`,
    );
    if (response.status === 200) {
      return response.data;
    }
  }

  @action
  async getFarm(id: string) {
    const response = await Axios.get('/farms/' + id);
    if (response.status === 200) {
      this.currentFarm = response.data;
    }
  }

  @action
  setFarms(farms: Farm[]) {
    this.farms = farms;
  }
}

const farmStore = new FarmStore();
export default farmStore;
