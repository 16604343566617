import { useCallback, useState } from 'react';

import ImageViewer from 'react-simple-image-viewer';
import { Image } from 'models/Image.model';
import Glider from '../Glider/Glider';

interface GraphImagesViewProps {
  images: Image[];
}

const GraphImagesView = ({ images }: GraphImagesViewProps) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const getImage = (image: Image, quality: 'low' | 'high' = 'high') => {
    const preferredImage = image?.[quality];

    const second = quality === 'low' ? 'high' : 'low';
    const alternative = image?.[second] || '';

    return preferredImage || alternative;
  };

  return (
    <div className="w-1/2 ml-5">
      <Glider
        config={{
          type: 'slider',
          keyboard: true,
          perView: 3,
          focusAt: 1,
        }}
        maxHeight="200px"
      >
        {images.map((img, i) => (
          <img
            src={getImage(img, 'low')}
            onClick={() => openImageViewer(i)}
            alt=""
            key={i}
          />
        ))}
      </Glider>

      {isViewerOpen && (
        <div className="p-4 z-50">
          <ImageViewer
            src={images.map((image) => getImage(image))}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
              zIndex: 50,
            }}
            closeOnClickOutside={true}
          />
        </div>
      )}
    </div>
  );
};

export default GraphImagesView;
