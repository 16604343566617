import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { Farm } from 'models/NavbarItem.model';
import { ObjectId } from 'models/PlantGroup.model';
import { FarmStore } from 'stores/farm.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface WSResponse {
  data: {
    farmId: Farm['_id'];
  };
}
export class FarmWebsocket {
  private farmStore: FarmStore;
  private organizationId = '';

  constructor(farmStore: FarmStore, organizationId: string) {
    this.farmStore = farmStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.FARM}`,
        (...args: WebsocketMessage<WSResponse>[]) => {
          for (const obj of args) {
            if (obj?.command !== WEBSOCKETCOMMANDS.CREATED) return;
            if (!obj?.data?.farmId) return;

            this.findFarm(obj.data.farmId);
          }
        },
      );
  }

  private findFarm(farmId: ObjectId) {
    this.farmStore?.getFarm(farmId);
  }
}
