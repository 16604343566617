import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import farmStore from '../../stores/farm.store';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { FarmStatus } from 'models/NavbarItem.model';
import { Tooltip } from 'react-tooltip';
import { CloudAppParams } from 'routes';

interface FarmOption {
  label: string;
  value: string;
}

const FarmSelection = () => {
  const { farmId } = useParams<CloudAppParams>();
  const navigate = useNavigate();

  const [currentFarm, setCurrentFarm] = useState<FarmOption>();
  const [farms, setFarms] = useState<FarmOption[]>([]);
  const [farmStatus, setFarmStatus] = useState<FarmStatus>();

  useEffect(() => {
    if (!farmStore?.farms) return;
    const farmOptions = farmStore.farms.map((farm) => ({
      label: farm.name,
      value: farm._id,
    }));
    setFarms(farmOptions);
    if (!farmId) return;

    const newCurrentFarm = farmOptions.find((farm) => farm.value === farmId);

    setCurrentFarm(newCurrentFarm);
    checkHealth(farmId);
    axios.defaults.headers.common['farm-id'] = farmId;
  }, [farmStore.farms, farmId]);

  const handleFarmSelection = (farm: FarmOption) => {
    setCurrentFarm(farm);
    checkHealth(farm.value);
    navigate(`/${farm.value}/plantGroups`);
  };

  const checkHealth = async (id: string) => {
    try {
      setFarmStatus(FarmStatus.LOADING);
      const response = await farmStore.healthCheck(id);
      response?.status === 'OK' && setFarmStatus(FarmStatus.ONLINE);
    } catch {
      setFarmStatus(FarmStatus.OFFLINE);
    }
  };

  return (
    <div className="flex flex-row w-full justify-between mb-2 pl-2">
      <Menu as="div" className="relative inline-block text-left w-full">
        <Menu.Button
          className="flex w-full justify-between text-green-700 hover:text-green-800 h-6"
          data-testid="farm-selector"
        >
          <div className="flex flex-row items-center">
            <label className="font-bold text-base hover:cursor-pointer ">
              {currentFarm?.label || <Skeleton width={120} height={20} />}
            </label>{' '}
            {farmStatus === FarmStatus.OFFLINE && (
              <XMarkIcon
                className="text-red-600 h-5 w-5 ml-2.5"
                data-tooltip-id="health-check-tooltip"
                data-tooltip-content="Farm connection Failed."
              />
            )}
            {farmStatus === FarmStatus.ONLINE && (
              <CheckIcon
                className="text-green-600 h-5 w-5 ml-2.5"
                data-tooltip-id="health-check-tooltip"
                data-tooltip-content="Farm connection established."
              />
            )}
            <Tooltip id="health-check-tooltip" />
          </div>
          <Skeleton count={10} />
          <ChevronDownIcon className="h-5 w-5 self-end ml-1" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 absolute right-2 bg-white w-full mt-1 rounded-md shadow-lg p-2 flex flex-col">
            {farms.map((farm) => (
              <Menu.Item key={farm.value} as="div" className="p-2 w-full">
                {({ active }) => (
                  <div
                    className={`${active && 'text-green-700 cursor-pointer'}`}
                    onClick={() => {
                      handleFarmSelection(farm);
                    }}
                  >
                    <p className="truncate">{farm.label}</p>
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default FarmSelection;
