import { inject, observer } from 'mobx-react';
import { ComponentType, lazy, Suspense, useEffect, useState } from 'react';
import ModalName from 'enums/ModalName.enum';
import { orderModalTabKey, orderModalTabs, orderTabKey } from 'util/tabNames';
import useQuerySetter from 'util/useQuerySetter';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import OrderNavbar from '../OrderPartNavbar/OrderPartNavbar';
import GenericModal from './GenericModal';

const loadOrderModalGraphs = () => import('./OrderModal/OrderModalGraphs');
const loadOrderModalDetails = () => import('./OrderModal/OrderModalDetails');
const loadEvents = () => import('./OrderModal/Events/Events');

const lazyLoadComponent: Record<orderModalTabs, () => Promise<unknown>> = {
  [orderModalTabs.events]: loadEvents,
  [orderModalTabs.general]: loadOrderModalDetails,
  [orderModalTabs.graphs]: loadOrderModalGraphs,
};

const OrderModalGraphs = lazy(loadOrderModalGraphs);
const OrderModalDetails = lazy(loadOrderModalDetails);
const Events = lazy(loadEvents);

const orderModalTabsLookup: Record<orderModalTabs, ComponentType> = {
  [orderModalTabs.general]: OrderModalDetails,
  [orderModalTabs.graphs]: OrderModalGraphs,
  [orderModalTabs.events]: Events,
};

const OrderModal = () => {
  const [activeTab, setActiveTab] = useState<orderModalTabs>(
    orderModalTabs.general,
  );
  const { query, setQuery } = useQuerySetter();
  const ActiveComponent = orderModalTabsLookup[activeTab];

  useEffect(() => {
    if (query) {
      if (
        query.get(orderTabKey) != null &&
        query.get(orderModalTabKey) != null
      ) {
        setActiveTab(query.get(orderModalTabKey) as orderModalTabs);
      }
    }
  }, [query.toString()]);

  return (
    <GenericModal
      modalName={ModalName.CHOOSE_ORDER}
      customContainerStyle="choose-order-part-width modal-height"
    >
      <div className="bg-white h-full p-3 flex flex-col">
        <OrderNavbar
          active={activeTab}
          onChange={(tab: orderModalTabs) => {
            setActiveTab(tab as orderModalTabs);
            setQuery([{ term: orderModalTabKey, value: tab }], {
              onlyReplaceStatedTerms: true,
            });
          }}
          eagerLoad={lazyLoadComponent}
        />

        <Suspense fallback={<LoadingScreen />}>
          <ActiveComponent />
        </Suspense>
      </div>
    </GenericModal>
  );
};

export default inject('orderStore')(observer(OrderModal));
