import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';

interface ValidatedDatePickerProps {
  valueName: string;
  phaseIndex?: number;
  options?: any;
  rules: any;
  control?: any;
  watch: any;
  className?: string;
}

const ValidatedDatePicker = ({
  valueName,
  watch,
  rules,
  control,
  className,
}: ValidatedDatePickerProps) => {
  const watchedDate: any = watch(valueName);
  if (!watchedDate) {
    return <></>;
  }

  const selectedDate = new Date(watchedDate);
  return (
    <div className={className}>
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            value={value}
            className={
              'w-full border focus:outline-none focus:border-green-400 border-gray-300  rounded-md shadow-sm py-2 px-3 sm:text-sm b'
            }
            selected={selectedDate}
            dateFormat="dd.MM.yyyy"
            onChange={(date: any) => onChange(date)}
          />
        )}
        name={valueName}
      />
    </div>
  );
};

export default ValidatedDatePicker;
