import { PlusIcon } from '@heroicons/react/24/outline';
import Grid from 'components/Grid/Grid';
import ModalName from 'enums/ModalName.enum';
import { useMemo, useState } from 'react';
import modalStore from 'stores/modal.store';
import nutritionSolutionStore, {
  NutritionSolutionDto,
} from 'stores/nutritionSolutionStore.store';
import PlantRecipeSearch from '../../plant-details/PlantRecipe/PlantRecipeSearch';
import NutritionSolutionCard from './NutritionSolutionCard';
import mobxify from 'util/hocs/mobxify';

const filterNutritionSolutions =
  (search: string) => (solution: NutritionSolutionDto) =>
    search
      ? JSON.stringify(solution).toLowerCase().includes(search.toLowerCase())
      : true;

const NutritionSolutionGroupDetails = () => {
  const name = nutritionSolutionStore.currentNutritionSolutionGroup?.name;

  const [filter, setFilter] = useState('');
  const nutritionSolutions = useMemo(
    () =>
      nutritionSolutionStore.currentNutritionSolutionGroup?.nutritionSolutions?.filter(
        filterNutritionSolutions(filter),
      ),
    [filter, name, nutritionSolutionStore.currentNutritionSolutionGroup],
  );

  return (
    <div className="w-full h-full">
      <div className="mt-1">
        <div className="relative border-b border-gray-200 sm:pb-0 p-4 bg-white">
          <div
            className="flex flex-row items-center mb-4 justify-between"
            style={{ paddingTop: 1, paddingBottom: 1 }}
          >
            <div className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
              {name}
            </div>
          </div>
        </div>
        <div className="overflow-auto h-screen bg-gray-50">
          <div className="p-5 pb-0 w-full">
            <PlantRecipeSearch onChange={setFilter} />
          </div>
          <div className="flex flex-row flex-wrap flex-grow w-full">
            <Grid>
              <li
                className="min-h-[12rem] w-full flex flex-col text-center bg-white hover:shadow-lg rounded-lg shadow box p-3 cursor-pointer"
                onClick={() => {
                  modalStore?.openModal(ModalName.NUTRITION_SOLUTION);
                }}
              >
                <button className="flex p-8 justify-center items-center border-2 border-dotted h-full w-full flex-col rounded-lg  hover:border-solid text-gray-400 hover:text-gray-600">
                  <PlusIcon className="w-7 h-7" />
                  <div className="w-16 text-center">
                    Add a new nutrition solution
                  </div>
                </button>
              </li>
              {nutritionSolutions?.map(
                (nutritionSolution: NutritionSolutionDto, index: number) => (
                  <NutritionSolutionCard
                    nutritionSolution={nutritionSolution}
                    key={index}
                  />
                ),
              )}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mobxify(
  NutritionSolutionGroupDetails,
  'nutritionSolutionStore',
  'modalStore',
);
