import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  AnalyticsData,
  AnalyticsDataValuesInterface,
} from 'models/AnalyticsData.model';
import { Image } from 'models/Image';
import { getTypeForKey } from 'util/KeyMappers';
import returnNotEmpty from 'util/returnNotEmpty';
import GraphImagesView from './GraphImagesView';
import { extraTypeFilter } from '../modal/OrderModal/Events/AnalyticsPanel';
import analyticStore from 'stores/analytics.store';
import mobxify from 'util/hocs/mobxify';

interface GraphSideNavProps {
  analyticData: AnalyticsData | null;
  startDate?: Date;
}

type DayjsDateType = Parameters<typeof dayjs>[0];

export function absoluteDateDiff(
  date1: DayjsDateType,
  date2: DayjsDateType,
  unit = 'days',
) {
  if (unit !== 'days') throw new Error('Only days are supported');
  if (!date1 || !date2) throw new Error('Dates are required');

  const diff = dayjs(date1).diff(dayjs(date2), 'hours') / 24;
  const rounded = Math.round(diff);

  const absolute = Math.abs(rounded);

  return absolute;
}

const GraphSideNav = ({ analyticData, startDate }: GraphSideNavProps) => {
  const data = useMemo(() => analyticData?.data, [analyticData]);

  const images: Image[] = useMemo(
    () =>
      returnNotEmpty([
        ...(data?.detailedImages || []),
        ...(data?.rootImages || []),
        ...(data?.trayImages || []),
      ]) || [],
    [analyticData],
  );

  if (!data) {
    return null;
  }

  const showDay = (startDate?: Date, compareDate?: Date) => {
    if (!startDate || !compareDate) {
      return false;
    }

    const start = dayjs(startDate);
    const compare = dayjs(compareDate);

    return `Day ${absoluteDateDiff(start, compare) + 1}`;
  };

  return (
    <div className="flex flex-row justify-between">
      <div className="w-1/2 mr-5 overflow-x-auto">
        <div className="py-3 px-6 border-b border-gray-100">
          <h3 className="mt-1 text-lg leading-6 font-medium text-gray-900">
            {showDay(startDate, data.date)} Analytics
          </h3>
          <p className="inline text-xs">
            {dayjs(data.date).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>

        <table className="rounded-md min-w-full divide-y divide-gray-700">
          <tbody className="bg-white">
            {(Object.keys(data) as (keyof AnalyticsDataValuesInterface)[])
              .filter((entry) => {
                if (!analyticStore?.analyticDataTypes) return false;
                const type = getTypeForKey(
                  analyticStore?.analyticDataTypes,
                  entry,
                );
                if (extraTypeFilter.includes(type.key)) return false;
                return type.showInDiagram;
              })
              .map((key, index: number) => {
                if (!analyticStore?.analyticDataTypes) return null;
                const type = getTypeForKey(
                  analyticStore?.analyticDataTypes,
                  key,
                );
                if (key === 'comment') {
                  return (
                    <tr key={index}>
                      <td className="text-sm font-medium text-gray-500 truncate max-w-4">
                        {type.value}
                      </td>
                      <td className="text-sm text-right">
                        {data[key] + (type.unit ? ' ' + type.unit : '')}
                      </td>
                    </tr>
                  );
                }
                if (!type.showInDiagram) {
                  return <tr key={index}></tr>;
                }

                return (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? 'hover:bg-gray-200'
                        : 'bg-gray-50 hover:bg-gray-200'
                    }
                  >
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {type.value}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                      {data[key] + (type.unit ? ' ' + type.unit : '')}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <GraphImagesView images={images} />
    </div>
  );
};

export default mobxify(GraphSideNav, 'analyticStore');
