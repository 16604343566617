import whiteSpaceToDash from 'util/whiteSpaceToDash';
import ValidatedSelect from './ValidatedSelect';

interface ValidatedSelectLabel {
  label: string;
  options: any;
  rules: any;
  hasError: boolean;
  valueName: string;
  className?: string;
  inputClassName?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  labelStyle?: string;
  maxHeight?: number;
}
export function ValidatedSelectLabel({
  valueName,
  label,
  options,
  rules,
  hasError,
  inputClassName,
  isMulti,
  isDisabled,
  labelStyle,
  className,
  maxHeight,
}: ValidatedSelectLabel) {
  const labelStyling = `block text-base font-medium text-gray-700 w-1/4 ${labelStyle}`;

  return (
    <div className={' sm:border-gray-200 flex items-center ' + className}>
      <label
        htmlFor={whiteSpaceToDash(label)}
        className={`block text-base font-medium text-gray-700 ${labelStyling}`}
      >
        {label}
      </label>
      <ValidatedSelect
        name={label}
        isMulti={isMulti}
        hasError={hasError}
        valueName={valueName}
        rules={rules}
        options={options}
        isDisabled={isDisabled}
        maxHeight={maxHeight}
        className={inputClassName}
      />
    </div>
  );
}
