export interface Farm {
  _id: string;
  name: string;
  href: string;
  current: boolean;
}

export interface NavbarItem {
  name: string;
  href: string;
  farmId: string;
  current: boolean;
  status: FarmStatus;
}

export enum FarmStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  LOADING = 'LOADING',
}
