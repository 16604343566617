import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import Glide, { Options } from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import Slide from './Slide';
import { RUN } from './GliderEvents';
import './Glider.css';

interface Props {
  config?: Partial<Options>;
  hideBullets?: boolean;
  children?: ReactNode[];
  maxHeight?: string | number;
}

const initialConfig: Partial<Options> = {
  type: 'slider',
  startAt: 0,
  perView: 3,
};

const Glider: FC<Props> = ({
  children,
  hideBullets = false,
  config = initialConfig,
  maxHeight = '100%',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const gliderRef = useRef<Glide | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const glide = new Glide(ref.current, { ...initialConfig, ...config });
    glide.mount();
    setActiveSlide(config.startAt || 0);

    glide.on(RUN, () => {
      setActiveSlide(glide.index || 0);
    });

    gliderRef.current = glide;

    return () => {
      glide.destroy();
    };
  }, [gliderRef, children]);

  return (
    <div
      className={'glide flex flex-col cursor-pointer'}
      style={{ maxHeight }}
      ref={ref}
    >
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {children?.map((child, i) => (
            <Fragment key={i}>
              <Slide slide={child} />
            </Fragment>
          ))}
        </ul>
      </div>
      {/* <div className="glide">
        <div data-glide-el="controls">
          <button data-glide-dir="<<">start</button>
          <button data-glide-dir=">>">end</button>
        </div>
      </div> */}
      {!hideBullets && (
        <div
          className="glide__bullets glider-bullets"
          style={{ height: 0 }}
          data-glide-el="controls[nav]"
        >
          {children?.map((_, i) => (
            <button
              key={i}
              aria-label="bullet"
              data-glide-dir={`=${i}`}
              className={`Glide-bullet glide__bullet glider-bullet ${
                activeSlide === i ? 'selected' : ''
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
export default Glider;
