import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  createContainer,
  InterpolationPropType,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryVoronoiContainerProps,
  VictoryZoomContainerProps,
} from 'victory';
import { AnalyticsData } from 'models/AnalyticsData.model';
import { generateColorBasedOnString } from 'util/ColorGenerator';
import Toggle from '../toggle/Toggle';
import FilterDropDown from './FilterDropDown';
import GraphSideNav from './GraphSideNav';
dayjs.extend(customParseFormat);

interface GraphProps {
  filters: Record<string, boolean>;
  lines: Line[];
  separationLines: SeparatorLine[];
  analytics: AnalyticsData[];
  hideFilter?: boolean;
  onFilterChanged: (newFilter: any) => void;
}

export interface Line {
  key: string;
  date: Date;
  dateWithDay: string;
  value: number;
  values: { x: Date; y: number; dateWithDay: string }[];
  offSetColour?: boolean;
}

export interface SeparatorLine {
  key: string;
  date: string;
}

const Graph = ({ lines, filters, analytics, onFilterChanged }: GraphProps) => {
  const [zoom, setZoom] = useState(false);
  const [interpolation] = useState<InterpolationPropType>('linear');
  const [showFullDate, setShowFullDate] = useState(false);
  const [currentSet, setCurrentSet] = useState<AnalyticsData | null>(null);
  const [labelFormat, setLabelFormat] = useState<
    'key' | 'value' | 'keyAndValue' | 'none'
  >('keyAndValue');

  const [boxPlotFilters] = useState<string[]>([
    'BBCH-Code',
    'Height',
    'Diameter',
  ]);

  const VictoryZoomVoronoiContainer = createContainer<
    VictoryZoomContainerProps,
    VictoryVoronoiContainerProps
  >('zoom', 'voronoi');
  useEffect(() => {
    if (analytics && analytics.length > 0) {
      setCurrentSet(analytics[analytics.length - 1]);
    }
  }, [analytics]);

  const showBoxPlot = () => {
    return boxPlotFilters.some(
      (boxPlotFilter: string) => filters[boxPlotFilter],
    );
  };

  const generateLines = (showDateString = showFullDate) => {
    if (!lines) {
      return <div></div>;
    }
    return lines.map((line) => {
      if (Object.keys(filters)?.length !== 0 && !filters[line.key]) {
        return null;
      }
      return (
        <VictoryLine
          key={line.key}
          interpolation={interpolation}
          style={{
            data: {
              stroke: generateColorBasedOnString(line.key, line.offSetColour),
              strokeWidth: 2,
            },
            parent: { border: '1px solid #ccc' },
          }}
          data={line.values}
          x={(d) => (showDateString ? d.date : d.dateWithDay)}
          y={(d) => d.value}
        />
      );
    });
  };

  const getLastDay = (data: Line[]) => {
    let result = 1;
    if (data && data.length > 0) {
      for (const entry of data) {
        for (const value of entry.values) {
          const newDate = Number(value.dateWithDay.split(' ')[1]);
          if (newDate > result) {
            result = newDate;
          }
        }
      }
      return result;
    }
  };

  const generateDayLines = () => {
    if (!lines || lines.length === 0) {
      return ['Day 1'];
    }
    const tmp = [];
    const lastDay = getLastDay(lines);
    const dayNumber = Number(lastDay) + 1;
    for (let days = 1; days <= dayNumber; days++) {
      tmp.push(`Day ${days}`);
    }
    return tmp;
  };

  const generateScatter = (showDateString = showFullDate) => {
    if (!lines) {
      return <div></div>;
    }

    return lines.map((line: any, key: number) => {
      if (!filters[line.key]) {
        return <div key={key}></div>;
      }

      return (
        <VictoryScatter
          key={key}
          labels={({ datum }: any) => {
            if (datum.key) {
              if (labelFormat === 'key') return `${datum.key}`;
              if (labelFormat === 'keyAndValue')
                return `${datum.key}: ${datum.value}`;
              if (labelFormat === 'value') return `${datum.value}`;
              if (labelFormat === 'none') return '';
            }
            return '';
          }}
          style={{
            data: {
              backgroundColor: generateColorBasedOnString(
                line.key,
                line.offSetColour,
              ),
            },
          }}
          size={({ active }) => (active ? 7 : 4)}
          data={line.values}
          x={(d) => (showDateString ? d.date : d.dateWithDay)}
          y={(d) => d.value}
        />
      );
    });
  };

  const highlightAnalytics = async (points: any) => {
    if (!points?.length) return;

    const entry = await findEntryForGraph(points[0].date);
    // setCurrentSet(entry);
    localStorage.setItem('currentSet', JSON.stringify(entry));
  };

  const findEntryForGraph = async (date: string) => {
    return (
      analytics.find(
        (a: AnalyticsData) =>
          dayjs(a.data.date).format('HH:mm DD.MM.YY') === date,
      ) || {}
    );
  };

  const HandleDataClick = () => {
    const value = localStorage.getItem('currentSet');
    if (!value) return;

    const parsedValue = JSON.parse(value);

    const foundAnalytics = analytics.find(
      (data) => data._id === parsedValue._id,
    );
    if (!foundAnalytics) throw new Error('Analytics not found');

    setCurrentSet(parsedValue);
  };

  return (
    <>
      <div className="flex flex-col overflow-y-scroll overflow-x-hidden h-fit">
        <div className="pb-14 h-full row-span-4 top-0 z-40">
          <div className="w-full flex justify-end p-2 items-center [&>*]:p-4">
            <Toggle value={zoom} setValue={() => setZoom(!zoom)} label="Zoom" />
            <Toggle
              value={showFullDate}
              setValue={() => setShowFullDate((bool) => !bool)}
              label="Full Date"
            />
            <FilterDropDown
              filters={filters}
              onFilterChanged={(name: string, value: boolean) => {
                const tmp = filters;
                if (showBoxPlot()) {
                  tmp['BBCH-Code'] = false;
                  tmp['Height'] = false;
                  tmp['Diameter'] = false;
                }
                if (boxPlotFilters.includes(name)) {
                  for (const key in tmp) {
                    tmp[key] = false;
                  }
                }
                tmp[name] = value;
                onFilterChanged({ ...tmp });
              }}
              selectAll={(select) => {
                const tmp = filters;
                for (const key in tmp) {
                  tmp[key] = select;
                  if (boxPlotFilters.includes(key)) {
                    tmp[key] = false;
                  }
                }
                onFilterChanged({ ...tmp });
              }}
              options={{
                labelFormat: {
                  value: labelFormat,
                  set: (value: 'key' | 'value' | 'keyAndValue' | 'none') =>
                    setLabelFormat(value),
                },
              }}
            />
          </div>
          {!showBoxPlot() && (
            <VictoryChart
              width={window.innerWidth}
              height={window.innerHeight / 2.7}
              theme={VictoryTheme.material}
              domainPadding={[20, 20]}
              containerComponent={
                zoom ? (
                  <VictoryZoomVoronoiContainer
                    onActivated={async (points: any) => {
                      await highlightAnalytics(points);
                    }}
                    events={{
                      onClick: HandleDataClick,
                    }}
                  />
                ) : (
                  <VictoryVoronoiContainer
                    onActivated={async (points: any) => {
                      await highlightAnalytics(points);
                    }}
                    events={{
                      onClick: HandleDataClick,
                      onWheel: () => {
                        // you can also enable zooming by zooming with the trackpad or the mousewheel but it tends to be a bit figgely so I disabled it
                        setZoom(true);
                      },
                    }}
                  />
                )
              }
            >
              <VictoryAxis
                crossAxis
                fixLabelOverlap
                tickValues={showFullDate ? undefined : generateDayLines()}
                style={{
                  ticks: { stroke: 'grey', size: 5 },
                  tickLabels: { fontSize: 12, padding: 5 },
                }}
              />

              <VictoryAxis
                dependentAxis
                fixLabelOverlap
                crossAxis
                style={{
                  ticks: { stroke: 'grey', size: 5 },
                  tickLabels: { fontSize: 12, padding: 5 },
                }}
              />
              {generateLines()}
              {generateScatter()}
            </VictoryChart>
          )}
        </div>

        <div className="w-full mt-5">
          <GraphSideNav
            analyticData={currentSet}
            startDate={analytics[analytics.length - 1]?.data?.date}
          />
        </div>
      </div>
    </>
  );
};

export default Graph;
