import { FormDurationToDBDuration } from './DateUtlil';

export const preparePhasesForSubmit = (data: any) => {
  const formPhases = data.phases;
  for (const phase of data.phases) {
    const inDays = phase.duration_type !== 'hours';
    phase['startPhase'] = false;
    if (phase.analyticIntervalDisabled) {
      delete phase['analyticInterval'];
    }

    phase['duration'] = FormDurationToDBDuration(phase['duration'], inDays);

    phase['usedNutritionSolution'] = phase['usedNutritionSolution']._id;
    delete phase['duration_type'];
  }
  formPhases[0].startPhase = true;

  return formPhases;
};
