import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { OrderGroupModel } from 'models/Order.model';
import { OrderStore } from 'stores/order.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface OrderGroupWebSocketResponse {
  data?: {
    id: OrderGroupModel['_id'];
  };
}
export class OrderGroupWebSocket {
  private orderStore: OrderStore;
  private organizationId = '';

  constructor(orderStore: OrderStore, organizationId: string) {
    this.orderStore = orderStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.ORDER_GROUP}`,

        (...args: WebsocketMessage<OrderGroupWebSocketResponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.DELETED) {
              this.orderStore.getRunningOrderGroups();
            }

            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.find();
            }
          }
        },
      );
  }

  async find() {
    this.orderStore.getRunningOrderGroups();
  }
}
