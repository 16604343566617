import { XMarkIcon } from '@heroicons/react/24/outline';
interface CardHeaderProps {
  title?: string;
  closeModal: () => void;
  buttonTitle?: string;
  titleColour?: string;
}

const CardHeader = ({
  title,
  buttonTitle,
  closeModal,
  titleColour,
}: CardHeaderProps) => {
  return (
    <div className={title ? 'bg-white p-8 py-4' : 'bg-white p-4'}>
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        {title && (
          <div className="ml-4 mt-2">
            <h3
              className={
                titleColour
                  ? 'text-lg leading-6 font-medium ' + titleColour
                  : 'text-lg leading-6 font-medium text-gray-800'
              }
            >
              {title}
            </h3>
          </div>
        )}
        {!title && <div className="ml-4 mt-2" />}

        <div className="ml-2 mt-2 flex">
          {buttonTitle && (
            <button
              type="submit"
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {buttonTitle}
            </button>
          )}
          <div className="flex justify-end items-center w-10">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={() => {
                closeModal();
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
