import ModalName from 'enums/ModalName.enum';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import modalStore from 'stores/modal.store';
import orderStore from 'stores/order.store';
import plantStore from 'stores/plant.store';
import recipeStore from 'stores/recipe.store';
import wait from 'util/wait';
import CardHeader from '../../CardHeader/CardHeader';
import GenericModal from '../GenericModal';
import CreateOrderInputs from './CreateOrderInputs';
import {
  CreateOrderFormInterface,
  isObjectEmpty,
  onSubmitDto,
} from './CreateOrderUtils';
import mobxify from 'util/hocs/mobxify';

const CreateOrderModal = () => {
  const methods = useForm<CreateOrderFormInterface>({
    defaultValues: {
      target: '',
      description: '',
      plantGroup: undefined,
      plant: undefined,
      recipeGroup: undefined,
      startDate: new Date(),
    },
  });

  const [disabled, setDisabled] = useState<boolean>(false);

  const selectedRecipeGroup = methods.watch('recipeGroup');
  const selectedPlantGroup = methods.watch('plantGroup');
  const selectedPlant = methods.watch('plant');

  const handleFetchPlantGroups = async () => {
    try {
      await plantStore?.getPlantGroups();

      await wait(500);

      if (!plantStore?.plantGroups?.length) {
        toast.error(`Error fetching available plant groups, trying again...`);
        await plantStore?.getPlantGroups();
      }
    } catch (error) {
      toast.error('Error at loading plantGroups' + (error as Error).message);
    }
  };

  useEffect(() => {
    if (modalStore?.currentModal !== ModalName.CREATE_ORDER) return;
    handleFetchPlantGroups();
  }, [plantStore, modalStore?.currentModal]);

  useEffect(() => {
    if (isObjectEmpty(selectedPlantGroup)) {
      methods.resetField('recipeGroup');
      methods.resetField('plant');
      methods.resetField('amount');
      return;
    }
    recipeStore?.getRecipeGroups(selectedPlantGroup._id, false);
    methods.resetField('recipeGroup');
    methods.resetField('plant');
    methods.resetField('amount');
  }, [selectedPlantGroup]);

  const closeModal = () => {
    setDisabled(false);
    methods.reset();
    modalStore?.closeModal();
  };

  const onSubmit = async (data: onSubmitDto) => {
    if (!data) return;
    if (!data.recipeGroup) return;
    if (!data.plant) return;
    if (!data.plantGroup) return;
    if (typeof data.recipeGroup.selected !== 'object') return;

    setDisabled(true);

    await orderStore?.createOrderGroup({
      orders: [
        {
          plantGroup: data.plantGroup,
          plant: data.plant,
          recipe: data.recipeGroup.selected._id,
          amount: data.amount,
          startPhase: data.startPhase?.id || 0,
        },
      ],
      amount: data.amount,
      target: data.target,
      description: data.description,
      startDate: data.startDate,
    });
    closeModal();
  };

  return (
    <GenericModal
      formMethods={methods}
      modalName={ModalName.CREATE_ORDER}
      customContainerStyle="container-max-height max-w-lg w-full bg-white inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-5"
    >
      <FormProvider {...methods}>
        <CardHeader closeModal={closeModal} />
        <form onSubmit={methods.handleSubmit(onSubmit)} className="ml-4 mr-4">
          <CreateOrderInputs
            currentPlant={selectedPlant}
            currentRecipeGroup={selectedRecipeGroup}
          />
          <div className="flex items-center justify-end pb-5 border-t border-gray-200">
            <button
              disabled={disabled}
              type="submit"
              className={`relative inline  px-4 py-2 mt-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                disabled && 'cursor-wait'
              }`}
            >
              Create Order
            </button>
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
};

export default mobxify(
  CreateOrderModal,
  'modalStore',
  'orderStore',
  'plantStore',
  'recipeStore',
);
