import { RecipeGroup } from 'models/RecipeGroup.model';

export const sortRecipeGroups = (
  a: RecipeGroup,
  b: RecipeGroup,
  sort: {
    label: string;
    value: string;
  },
) => {
  switch (sort.value) {
    case 'createdAtFirst':
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    case 'createdAtLast':
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    case 'updatedAt':
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    case 'orderAmount':
      return b.orders! - a.orders!;
    default:
      return -1;
  }
};

export const checkHasError = (errors: any, valueName: string) => {
  if (!errors) {
    return false;
  }
  if (errors[valueName]) {
    return true;
  }
  return false;
};
