import { FC } from 'react';
import { OrderModel } from 'models/Order.model';

interface OrderInformationProps {
  currentOrder?: OrderModel;
}

const OrderInformation: FC<OrderInformationProps> = ({ currentOrder }) => {
  if (!currentOrder) return null;

  const status = currentOrder.metaData?.status?.toLowerCase();

  return (
    <div className="flex flex-col w-1/2 mt-8">
      <p className="text-xl text-green-600 font-semiboldw-fit">
        {currentOrder.uuid}
      </p>
      {status ? (
        <p className="text-sm text-gray-500 mt-1">Order-Status is {status}</p>
      ) : (
        <p className="text-sm text-gray-500 mt-1">Order has no status</p>
      )}
      <p className="text-sm text-gray-500 mt-1">
        {currentOrder.amount} {currentOrder.amount === 1 ? 'piece' : 'pieces'}
      </p>
    </div>
  );
};

export default OrderInformation;
