import { FC } from 'react';

import RecipeGroupSidebarTopButtons from './RecipeGroupSidebarTopButtons';
import { RecipeModalMode } from 'util/tabNames';
import VersionSelect from './VersionSelect';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

interface Props {
  disabled?: boolean;
}

const Sidebar: FC<Props> = ({ disabled }) => {
  return (
    <div className="max-h-full sticky top-0 z-30 pt-4 w-64 overflow-y-auto">
      <RecipeGroupSidebarTopButtons disabled={disabled} />
      <div className="max-h-full m-4 ">
        {modalStore?.recipeModalMode === RecipeModalMode.VIEW && (
          <>
            <VersionSelect archived={false} />
            <VersionSelect archived={true} />
          </>
        )}
      </div>
    </div>
  );
};

export default mobxify(Sidebar, 'modalStore', 'recipeStore');
