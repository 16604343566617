import { useMemo } from 'react';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';
import { generateTreemap } from '../orderPageHelper';
import OrderTreemapRow from './OrderTreemapRow';

const TREEMAP_HEIGHT = 500;

const OrderTreemap = () => {
  const generationsObject = useMemo(() => {
    return generateTreemap(orderStore.currentOrderGroup?.orders) || [];
  }, [orderStore.currentOrderGroup?.orders]);

  return (
    <div
      id="id"
      className="bg-gray-200 rounded-lg divide-x divide-gray-400 w-full flex flex-row mt-8 overflow-hidden drop-shadow-lg"
      style={{ height: `${TREEMAP_HEIGHT}px` }}
    >
      {generationsObject.map((rowObject, index) => (
        <OrderTreemapRow
          rowOrders={rowObject}
          height={TREEMAP_HEIGHT}
          generations={generationsObject.length}
          key={index}
        />
      ))}
    </div>
  );
};
export default mobxify(OrderTreemap, 'orderStore');
