import dayjs from 'dayjs';
import {
  format,
  iconColour,
  isRegularEvent,
  pluralCheck,
  showUserIcon,
  toTitle,
} from './EventUtils';
import {
  ClockIcon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  BeakerIcon,
  DocumentTextIcon,
} from '@heroicons/react/20/solid';
import { EventLog } from 'stores/event.store';
interface EventCardProps {
  event: EventLog;
  length: number;
  eventIdx: number;
  handleShowEvent: (thing: string) => void;
}
const EventCard = ({
  event,
  eventIdx,
  length,
  handleShowEvent,
}: EventCardProps) => {
  return (
    <li
      key={eventIdx}
      className={
        event?.name.includes('ANALYTICS') && event?.name.includes('COMPLETED')
          ? 'cursor-pointer hover:text-green-600'
          : ''
      }
    >
      <div
        onClick={() => handleShowEvent(event?._id)}
        className="relative pb-8 "
      >
        {eventIdx !== length - 1 ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <span
              className={
                iconColour(event) +
                ' h-10 w-10 rounded-full flex items-center justify-center ring-8 ring-white'
              }
            >
              {event?.name.includes('CHAT') && showUserIcon(event)}
              {event?.name.includes('PERIOD') && (
                <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
              )}
              {event?.name.includes('ANALYTICS') && (
                <MagnifyingGlassIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              )}
              {event?.name.includes('TRAY') && (
                <ShoppingCartIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              )}
              {event?.name.includes('PLANTS') && (
                <BeakerIcon className="h-6 w-6 text-white" aria-hidden="true" />
              )}
              {event?.name.includes('ORDER') && (
                <DocumentTextIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              )}
            </span>
          </div>
          <div className="min-w-0 flex-1 flex justify-between space-x-4">
            <div className="flex flex-col justify-center">
              {event?.userName && <p className="font-medium"></p>}
              {!event?.userName && (
                <div className="flex items-baseline">
                  <p className="font-medium h-full flex items-end">
                    {toTitle(event?.name)}
                  </p>
                  <p className="h-full text-sm text-gray-500 ml-2 flex items-end">
                    {event.userName && event.userName + ', '}
                    {dayjs(event?.timestamp).format('HH:mm')}
                  </p>
                </div>
              )}
              {event?.comment && (
                <div className="flex items-baseline">
                  <a className="font-normal h-full flex items-baseline">
                    {event.comment}
                  </a>
                  <a className="text-sm text-gray-500 ml-2 flex items-baseline">
                    {event.userName && event.userName + ', '}
                    {dayjs(event?.timestamp).format('HH:mm')}
                  </a>
                </div>
              )}
              {isRegularEvent(event) &&
                Object.keys(event.data).map((key) => {
                  return (
                    <a key={key + event?.timestamp}>
                      {format(event?.data[key])}
                      {event.name === 'PLANTS_EJECTED'
                        ? pluralCheck(' plant', +event?.data[key])
                        : '  '}
                    </a>
                  );
                })}
            </div>
            <div className="text-right text-sm whitespace-nowrap text-gray-500 "></div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default EventCard;
