import { useEffect } from 'react';
import typeStore from 'stores/type.store';
import { RecipeModalMode } from 'util/tabNames';
import { ValidatedCreatableLabel } from '../../InputValidation/ValidatedCreatableLabel';
import mobxify from 'util/hocs/mobxify';

interface OxygenMethodCreatableProps {
  index: number;
  mode: RecipeModalMode;
  hasError: boolean;
}

const OxygenMethodCreatable = ({
  index,
  hasError,
  mode,
}: OxygenMethodCreatableProps) => {
  useEffect(() => {
    typeStore?.loadOxygenMethods();
  }, []);

  const handleCreateOxygenMethod = async (inputValue: string) => {
    await typeStore?.createOxygenMethod({
      label: inputValue,
      value: inputValue.toLowerCase(),
    });
    await typeStore?.loadOxygenMethods();
  };

  return (
    <ValidatedCreatableLabel
      valueName="oxygenMethod"
      label="Oxygenation Method"
      inputClassName="w-3/4"
      className="flex justify-between mt-4"
      hasError={hasError}
      rules={{ required: true }}
      fieldArrayName="phases"
      index={index}
      onCreate={handleCreateOxygenMethod}
      options={typeStore?.oxygenMethods}
      isDisabled={mode === RecipeModalMode.VIEW}
    />
  );
};

export default mobxify(OxygenMethodCreatable, 'typeStore');
