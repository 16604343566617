import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { Phase } from 'models/Phase.model';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RecipeFormValues } from '../../../models/RecipeFormValues.model';
import { toast } from 'react-toastify';
import { Plant } from '../../../models/Plant.model';
import { Recipe } from '../../../models/Recipe.model';
import { WarehouseItem } from '../../../models/WarehouseItem.model';
import { DBDurationToFormDuration } from '../../../util/DateUtlil';
import { preparePhasesForSubmit } from '../../../util/PhaseUtil';
import { RecipeModalMode, plantGroupTabs } from '../../../util/tabNames';
import useQuerySetter from '../../../util/useQuerySetter';
import RecipeGroupSidebar from '../RecipeGroupModal/RecipeGroupSidebar';
import RecipeFormInputs from './RecipeFormInputs';
import { CloudAppParams } from 'routes';
import modalStore from 'stores/modal.store';
import recipeStore from 'stores/recipe.store';
import mobxify from 'util/hocs/mobxify';

interface Props {
  mode?: RecipeModalMode;
}

const RecipeFrom: FC<Props> = () => {
  const { query } = useQuerySetter();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const params = useParams<CloudAppParams>();
  const mode = modalStore?.recipeModalMode;
  const navigate = useNavigate();
  const { farmId } = params;
  const baseUrl = `/${farmId}`;

  const methods = useForm<RecipeFormValues>({
    defaultValues: {
      recipeName: undefined,
      strict: false,
      selectedPlants: undefined,
      phases: [{}],
    },
    mode: 'all',
  });

  useEffect(() => {
    if (!recipeStore?.recipe) return;
    if (mode === RecipeModalMode.CREATE) return;
    loadForm(recipeStore.recipe);
  }, [recipeStore?.recipe]);

  useEffect(() => {
    if (mode === RecipeModalMode.CREATE) {
      methods.reset();
      setLoading(false);
    }
  }, [mode]);

  const loadForm = (recipe: Recipe) => {
    if (!recipe.phases) {
      toast.error('Error Loading Recipe');
      return;
    }
    if (mode === RecipeModalMode.EDIT) return;
    const phases = setupPhasesForDisplay(recipe.phases);
    methods.setValue('phases', phases);
    methods.setValue('recipeName', recipe.name);
    methods.setValue('selectedPlants', recipe.plants as Plant[]);
    methods.setValue('soilType', recipe.soilType as WarehouseItem);
    methods.setValue('strict', recipe.strict);
    setLoading(false);
  };

  const setupPhasesForDisplay = (phases: Phase[]) => {
    return phases.map((phase) => {
      if (!phase.duration) return phase;
      const duration = DBDurationToFormDuration(phase.duration);
      const inDays = duration % 24 === 0;
      const durationType = inDays ? 'days' : 'hours';
      const updatedPhase = {
        ...phase,
        duration: inDays ? duration / 24 : duration,
        duration_type: durationType,
      };
      const nutritionSolution = updatedPhase.usedNutritionSolution;
      if (nutritionSolution) {
        updatedPhase.usedNutritionSolution = {
          ...nutritionSolution,
          label: `${nutritionSolution.ec} EC - ${nutritionSolution.ph} pH - Ratio ${nutritionSolution.ratio} - Waterlevel ${nutritionSolution.waterLevel}`,
        };
      }
      return updatedPhase;
    });
  };

  const onSubmit = async (data: any) => {
    if (mode === RecipeModalMode.VIEW) return;
    const recipeName = data.recipeName;
    const formPhases = preparePhasesForSubmit(data);
    const { plantGroupId } = params;
    setLoading(true);
    if (mode !== RecipeModalMode.EDIT && plantGroupId) {
      setDisabled(true);
      await recipeStore?.create(plantGroupId, {
        name: recipeName,
        phases: formPhases,
        soilType: data.soilType,
        strict: data.strict,
        plants: data.selectedPlants,
      });
      modalStore?.closeModal();
      navigate(
        `${baseUrl}/plantGroups/${plantGroupId}/${plantGroupTabs.recipes}`,
      );
    } else {
      const recipeGroup = query.get('recipeGroup');
      if (!recipeGroup || !params.plantGroupId) {
        setLoading(false);
        throw new Error('No recipe');
      }
      await recipeStore?.createVersion(params.plantGroupId, recipeGroup, {
        name: recipeName,
        phases: formPhases,
        soilType: data.soilType,
        strict: data.strict,
        plants: data.selectedPlants,
      });
      await recipeStore?.getOneRecipeGroup(recipeGroup);
      setDisabled(false);
      modalStore?.setRecipeModalMode(RecipeModalMode.VIEW);
    }
    setLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="h-screen overflow-hidden"
      >
        <div className="relative flex flex-row-reverse h-full overflow-hidden">
          <RecipeGroupSidebar disabled={disabled} />
          <RecipeFormInputs mode={mode ? mode : RecipeModalMode.VIEW} />
          {loading && (
            <div className="absolute top-0 left-0 right-0 h-screen opacity-50 bg-white m-30">
              <LoadingScreen />
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default mobxify(RecipeFrom, 'modalStore', 'recipeStore');
