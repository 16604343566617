import Grid from 'components/Grid/Grid';
import NoDataFound from 'components/NoDataFound/NoDataFound';
import { WarehouseItem } from 'models/WarehouseItem.model';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { CloudAppParams } from 'routes';
import warehouseStore from 'stores/warehouse.store';
import camelToTitleCase from 'util/camelToTitleCase';
import CreateWarehouseButton from './CreateWarehouseButton';
import WarehouseItemCard from './WarehouseItemCard';
import withLoader from 'util/hocs/withLoader';
import mobxify from 'util/hocs/mobxify';

const Items: FC = () => {
  const { itemType } = useParams<CloudAppParams>();
  const typeLabel = camelToTitleCase(itemType);
  const search = warehouseStore.search;

  const warehouseItems = useMemo(
    () =>
      warehouseStore?.warehouseItems
        .filter((item) => (itemType ? item.type === itemType : true))
        .filter((item) =>
          search
            ? item.name.toLowerCase().includes(search.toLowerCase())
            : true,
        ) || [],
    [itemType, search, warehouseStore.warehouseItems],
  );

  return (
    <Grid>
      <CreateWarehouseButton typeLabel={typeLabel} />
      {warehouseItems.map((item: WarehouseItem) => (
        <WarehouseItemCard key={item.name} item={item} />
      ))}
      {warehouseItems.length === 0 && (
        <div className="flex justify-center items-center mt-5">
          <NoDataFound
            title="Warehouse items"
            subtitle="No warehouse items found"
          />
        </div>
      )}
    </Grid>
  );
};
export default withLoader(mobxify(Items, 'warehouseStore'));
