import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { PlantGroup } from 'models/PlantGroup.model';
import { PlantStore } from 'stores/plant.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

type PlantGroupWebsocketResponse = {
  data: Data | Data[];
};

type Data = {
  _id: PlantGroup['_id'];
};

export class PlantGroupWebsocket {
  private plantStore: PlantStore;
  private organizationId = '';
  constructor(plantStore: PlantStore, organizationId: string) {
    this.plantStore = plantStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.PLANT_GROUP}`,
        (...args: WebsocketMessage<PlantGroupWebsocketResponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.findAllPlants();
            }
            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              this.updated(obj);
            }
          }
        },
      );
  }

  private async findAllPlants() {
    await this.plantStore?.getPlantGroups();
  }

  private async updated(obj: PlantGroupWebsocketResponse) {
    if (Array.isArray(obj.data)) {
      await this.plantStore?.getPlantGroups();
    } else {
      await this.plantStore?.getPlantGroup(obj.data._id);
    }
  }
}
