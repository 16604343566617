import { OrderModel } from 'models/Order.model';
import OrderTreemapElement from './OrderTreemapElement';
import mobxify from 'util/hocs/mobxify';

interface OrderTreemapRowProps {
  rowOrders: OrderModel[];
  height?: number;
  generations?: number;
}

const OrderTreemapRow = ({
  rowOrders,
  height = 500,
  generations,
}: OrderTreemapRowProps) => {
  const width = generations === 1 ? 100 : 100 / (generations || 1);
  const rowStyle = `divide-y divide-gray-400`;
  const elementHeight = height / rowOrders.length;

  if (!elementHeight) return null;

  return (
    <div
      className={rowStyle}
      style={{ height: `${height}px`, width: `${width}%` }}
    >
      {rowOrders.map((order) => (
        <OrderTreemapElement
          order={order}
          height={elementHeight}
          key={order._id}
        />
      ))}
    </div>
  );
};

export default mobxify(OrderTreemapRow, 'orderStore');
