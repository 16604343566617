import { useFormContext } from 'react-hook-form';
import { determineInvalidity } from './ValidationUtils';

interface ValidatedInputProps {
  valueName: string;
  rules: any;
  isDisabled?: boolean;
  className?: string;
  fieldArrayName?: string;
  index?: number;
  defaultValue?: any;
  value?: any;
  placeholder?: string;
  isNumber?: boolean;
}
export const ValidatedInput = ({
  valueName,
  isDisabled = false,
  rules,
  fieldArrayName,
  index,
  value,
  className,
  isNumber = false,
  defaultValue,
  placeholder,
}: ValidatedInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  //mt-1 block w-full border bg-red-50	border-red-300 rounded-md shadow-sm py-2 px-3 sm:text-sm focus:border-red-500 focus:ring-red-500"

  const hasError = determineInvalidity(
    errors,
    valueName,
    fieldArrayName,
    index,
  );

  const borderStyle = hasError
    ? 'w-full border focus:outline-none bg-red-50 border-red-300 rounded-md shadow-sm py-2 px-3 sm:text-sm focus:border-red-500 focus:ring-red-500'
    : 'w-full border focus:border-2 focus:outline-none focus:border-green-600 border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm b';

  const getRulesObject = () => {
    if (isNumber)
      return {
        ...rules,
        setValueAs: (v: any) => {
          if (!v) return null;
          return parseFloat(v);
        },
      };
    else {
      const min = rules.required ? 1 : 0;
      return {
        minLength: min,
        ...rules,
      };
    }
  };

  if (fieldArrayName) {
    return (
      <div className={className}>
        <input
          {...register(
            `${fieldArrayName}.${index}.${valueName}` as const,
            getRulesObject(),
          )}
          defaultValue={defaultValue}
          disabled={isDisabled}
          step="any"
          placeholder={placeholder}
          type={isNumber ? 'number' : 'text'}
          id={valueName}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          className={borderStyle}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <input
        {...register(`${valueName}`, getRulesObject())}
        defaultValue={defaultValue}
        disabled={isDisabled}
        step="any"
        placeholder={placeholder}
        type={isNumber ? 'number' : 'text'}
        value={value}
        name={valueName}
        id={valueName}
        onWheel={(e: any) => {
          e.target.blur();
        }}
        className={borderStyle}
      />
    </div>
  );
};
