import { useFormContext } from 'react-hook-form';
import { checkHasError } from 'util/PlantRecipeUtils';
import { RecipeModalMode } from 'util/tabNames';
import { ValidatedCheckbox } from '../InputValidation/ValidatedCheckbox';
import RecipeNameInput from './RecipeFormComponents/Inputs/RecipeNameInput';
import SelectPlantDropDown from './RecipeFormComponents/SelectPlantDropDown';
import SoilTypeCreatable from './RecipeFormComponents/SoilTypeCreatable';

interface RecipeModalHeaderProps {
  mode: RecipeModalMode;
}
const RecipeModalHeader = ({ mode }: RecipeModalHeaderProps) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  return (
    <div className="p-6 border-b border-gray-200 w-full">
      <RecipeNameInput mode={mode} />
      <SelectPlantDropDown
        hasError={checkHasError(errors, 'selectedPlants')}
        mode={mode || RecipeModalMode.VIEW}
      />
      <SoilTypeCreatable
        hasError={checkHasError(errors, 'soilType')}
        mode={mode || RecipeModalMode.VIEW}
      />
      <div className="flex justify-between mt-4 font-medium text-gray-700">
        <span className="flex-grow flex flex-col w-1/4">
          <span
            className="text-sm font-medium text-gray-900"
            id="availability-label"
          >
            Strict Mode
          </span>
          <span className="text-sm text-gray-500" id="availability-description">
            If enabled, no deviation from the recipe will be allowed.
          </span>
        </span>
        <div className="items-center mt-0.5 w-3/4">
          <ValidatedCheckbox
            valueName="strict"
            control={control}
            setValue={setValue}
            isDisabled={mode === RecipeModalMode.VIEW}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipeModalHeader;
