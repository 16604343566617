import Axios from 'util/Axios';
import { action, makeAutoObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import { Recipe } from 'models/Recipe.model';
import { RecipeGroup } from 'models/RecipeGroup.model';
import { CreateRecipeDto } from 'dtos/Recipe.dto';
import { Plant } from 'models/Plant.model';
import { Dispatch } from 'react';
export class RecipeStore {
  @observable recipes: (Recipe & { name: string })[] = [];
  @observable recipe: (Recipe & { name: string }) | undefined;

  @observable recipeGroups: RecipeGroup[] | undefined;

  @observable recipeGroup: RecipeGroup | undefined;

  @observable groupOfRecipe: RecipeGroup | undefined;

  @observable plantsOfGroup: Plant[] | undefined;

  @observable possiblePlantsOfRecipe: Plant[] | undefined;

  @observable comments: any;
  constructor() {
    makeAutoObservable(this);
  }

  @action
  async getRecipeGroups(id: string, archived: boolean) {
    const response = await Axios.get(`plant-groups/${id}/recipe-groups`, {
      params: {
        archived,
      },
    });

    if (response.status === 200) {
      if (!response.data) {
        toast.error('Error fetching recipe groups');
      }
      this.recipeGroups = response.data.recipeGroups;
      this.plantsOfGroup = response.data.plants;
    }
  }

  @action
  async getRecipe(recipeId: string) {
    if (this.recipe && recipeId === this.recipe._id) {
      return;
    }

    const response = await Axios.get(`/recipes/${recipeId}`);
    if (response.status === 200) {
      this.recipe = { ...response.data };
    }
  }

  @action
  clearRecipe() {
    this.recipe = undefined;
  }

  @action
  async create(id: string, phases: any) {
    try {
      await Axios.post(`/recipes`, { plantGroup: id, ...phases });
      toast.success('The recipe is created successfully');
    } catch (error) {
      toast.error('Error on creating the recipe');
    }
  }

  @action
  async createVersion(id: string, recipeGroup: string, phases: any) {
    try {
      await Axios.post(`/recipes`, { plantGroup: id, recipeGroup, ...phases });
      toast.success('The recipe is created successfully');
    } catch (error) {
      toast.error('Error on creating the recipe');
    }
  }

  async createRecipeForGroup(
    plantId: string,
    recipeGroupId: string,
    phases: any,
  ) {
    try {
      await Axios.post(
        `/plants/${plantId}/recipeGroup/${recipeGroupId}`,
        phases,
      );
      toast.success('The recipe is created successfully');
    } catch (error) {
      toast.error('Error on creating the recipe');
    }
  }

  /**
   * @deprecated use updateAndIncrementVersion instead to conform to business logic
   */
  async update(plantId: string, recipe: any) {
    try {
      await Axios.put(`/plants/${plantId}/recipes/${recipe._id}`, recipe);
      toast.success('Successfully updated recipe.');
    } catch (error) {
      toast.error('Error on updating recipe.');
    }
  }

  async updateAndIncrementVersion(
    plantId: string,
    recipeGroupId: string,
    createVersionDto: CreateRecipeDto,
  ) {
    try {
      await Axios.post(
        `/plants/${plantId}/recipeGroup/${recipeGroupId}`,
        createVersionDto,
      );
      toast.success('Successfully updated recipe.');
    } catch (error) {
      toast.error('Error on updating recipe.');
    }
  }

  async delete(plantId: string, recipeId: string) {
    try {
      await Axios.delete(`/plants/${plantId}/recipes/${recipeId}`);
      toast.success('The recipe has been deleted.');
    } catch (error) {
      toast.error('Error on deleting recipe.');
    }
  }

  async deleteRecipeGroup(recipeGroupId: string) {
    try {
      await Axios.delete(`/recipe-groups/${recipeGroupId}`);
      toast.success('The recipe has been deleted.');
    } catch (error) {
      toast.error('Error on deleting recipe.');
    }
  }

  async archiveGroup(recipeGroupId: string, isArchived: boolean) {
    try {
      await Axios.put(`recipe-groups/${recipeGroupId}`, {
        archived: !isArchived,
      });
      toast.success(
        `The recipe has been ${isArchived ? 'unarchived' : 'archived'}.`,
      );
    } catch (error) {
      toast.error(
        `Error on ${isArchived ? 'unarchiving' : 'archiving'} recipe.`,
      );
    }
  }

  async getRecipeForSubType(subTypeId: string) {
    const response = await Axios.get(
      `/plant-subtypes/${subTypeId}/recipeGroups:selected`,
    );
    if (response.status === 200) {
      this.recipeGroups = response.data;
    }
  }

  async toggleArchive(recipeId: string, archived: boolean) {
    try {
      await Axios.put(`/recipes/${recipeId}`, {
        archived: !archived,
      });
      toast.success(
        `The recipe has been ${archived ? 'unarchived' : 'archived'}.`,
      );
    } catch (error) {
      toast.error('Error on archiving recipe.');
    }
  }

  async favoriteRecipe(recipeGroup: string, recipeId: string) {
    try {
      await Axios.put(`/recipe-groups/${recipeGroup}`, {
        selected: recipeId,
      });
      await this.getOneRecipeGroup(recipeGroup);
      toast.success('The recipe has been favorited.');
    } catch (error) {
      toast.error('Error on favorizing recipe.');
    }
  }

  @action
  async getOneRecipeGroup(recipeGroupId: string) {
    const response = await Axios.get(
      `/recipe-groups/${recipeGroupId}?populate=recipes,selected`,
    );
    if (response.status === 200) {
      this.recipeGroup = response.data;
    }
  }

  @action
  async getCommentsForRecipeGroup(recipeGroupId: string) {
    const response = await Axios.get(
      `/recipe-groups/${recipeGroupId}/comments`,
    );
    if (response.status === 200) {
      this.comments = response.data;
    }
  }
  @action
  async getCommentsForRecipe(recipeId: string) {
    const response = await Axios.get(`/recipes/${recipeId}/comments`);
    if (response.status === 200) {
      this.comments = response.data;
    }
  }
  @action
  async getGroupForRecipe(recipeId: string) {
    const response = await Axios.get(`/recipe-groups?recipes=${recipeId}`);
    if (response.status === 200 && response.data.length > 0) {
      this.groupOfRecipe = response.data[0];
    }
  }

  @action
  setRecipe(recipe: Recipe) {
    this.recipe = recipe;
  }

  @action
  async fetchIsArchived(
    recipeGroupId: string,
    setIsArchived: Dispatch<React.SetStateAction<boolean | undefined>>,
  ) {
    if (!recipeGroupId) return;
    if (!setIsArchived) return;

    await Axios.get(`recipe-groups/${recipeGroupId}`)
      .then((response) => {
        if (response.data.archived === true) {
          setIsArchived(true);
        } else {
          setIsArchived(false);
        }
      })
      .catch(toast.error);
  }
}

const recipeStore = new RecipeStore();
export default recipeStore;
