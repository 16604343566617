import { ChevronRightIcon } from '@heroicons/react/24/outline';
import ActiveIndicator from 'components/ActiveIndicator/ActiveIndicator';
import dayjs from 'dayjs';
import { PlantGroup } from 'models/PlantGroup.model';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CloudAppParams } from 'routes';
import plantStore from 'stores/plant.store';
import mobxify from 'util/hocs/mobxify';
import { plantGroupTabs } from 'util/tabNames';

interface Props {
  plantGroup: PlantGroup;
}

const SinglePlantGroup: FC<Props> = ({ plantGroup }) => {
  const navigate = useNavigate();
  const { farmId } = useParams<CloudAppParams>();
  const baseUrl = `/${farmId}`;
  const { plantGroupId } = useParams<CloudAppParams>();

  return (
    <li
      key={plantGroup._id}
      className="block hover:bg-gray-50 cursor-pointer h-16"
      onClick={() => {
        plantStore?.clearPlant();
        navigate(
          `${baseUrl}/plantGroups/${plantGroup._id}/${plantGroupTabs.recipes}`,
        );
        plantStore?.setPlantGroup(plantGroup);
      }}
    >
      <div className="flex items-center justify-between px-4 py-4">
        <button
          name={plantGroup.name}
          className="block truncate text-sm text-start"
        >
          <p className="text-l mr-2 font-semibold ">{plantGroup.name}</p>
          <p className="flex items-center text-xs text-gray-500">
            Updated on {dayjs(plantGroup.updatedAt).format('DD.MM.YYYY')}
          </p>
        </button>
        <div className="flex justify-center items-center">
          {plantGroup._id === plantGroupId && <ActiveIndicator />}
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </li>
  );
};
export default mobxify(SinglePlantGroup, 'plantStore');
