import {
  AnalyticsData,
  AnalyticsDataValuesInterface,
} from 'models/AnalyticsData.model';
import analyticStore from 'stores/analytics.store';
import { getTypeForKey } from 'util/KeyMappers';
import NoDataFound from '../../../NoDataFound/NoDataFound';
import mobxify from 'util/hocs/mobxify';

interface AnalyticsPanelProps {
  analytic?: AnalyticsData;
}

export const extraTypeFilter = [
  'detailedImages',
  'rootImages',
  'trayImages',
  'plantAnalyticsData',
];

const AnalyticsPanel = ({ analytic }: AnalyticsPanelProps) => {
  return (
    <div className=" bg-white w-full mx-4 shadow-lg rounded-lg overflow-hidden">
      {!analytic && (
        <NoDataFound
          title="No analytics data"
          subtitle="No data could be found for this event."
          className="py-5"
        />
      )}
      {analytic && (
        <>
          <div className="border-b px-5 text-2xl font-semibold py-5 text-green-700">
            Analytic
          </div>
          <table className="rounded-md min-w-full divide-y divide-gray-700 ">
            <tbody className="bg-white">
              {Object.keys(analytic.data)
                .filter((entry) => {
                  if (!analyticStore?.analyticDataTypes) return false;
                  const type = getTypeForKey(
                    analyticStore?.analyticDataTypes,
                    entry as keyof AnalyticsDataValuesInterface,
                  );
                  if (extraTypeFilter.includes(type.key)) return false;
                  return type.showInDiagram;
                })
                .map((key, index) => {
                  if (!analyticStore?.analyticDataTypes) return;

                  const type = getTypeForKey(
                    analyticStore.analyticDataTypes,
                    key as keyof AnalyticsDataValuesInterface,
                  );
                  if (key === 'comment') {
                    return (
                      <tr key={index}>
                        <td className="text-sm font-medium text-gray-500 truncate max-w-4">
                          {type.value}
                        </td>
                        <td className="text-sm text-right">
                          {analytic.data[key] +
                            (type.unit ? ' ' + type.unit : '')}
                        </td>
                      </tr>
                    );
                  }
                  if (!type.showInDiagram) {
                    return <tr key={index}></tr>;
                  }

                  return (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0
                          ? 'hover:bg-gray-200'
                          : 'bg-gray-50 hover:bg-gray-200'
                      }
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {type.value}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {analytic.data[
                          key as keyof AnalyticsDataValuesInterface
                        ] + (type.unit ? ' ' + type.unit : '')}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default mobxify(AnalyticsPanel, 'analyticStore');
