export const orderTabKey = 'order-tab';

type TabNames<T> = {
  name: string;
  value: T;
  disabled?: boolean;
}[];

export enum orderTabs {
  orders = 'orders',
  history = 'history',
}

export const orderTabNames: TabNames<orderTabs> = [
  { name: 'Current Orders', value: orderTabs.orders },
  { name: 'Order History', value: orderTabs.history },
];

export const orderModalTabKey = 'order-modal-tab';

export enum orderModalTabs {
  general = 'general',
  events = 'events',
  graphs = 'graphs',
}

export const orderModalTabNames: TabNames<orderModalTabs> = [
  { name: 'General', value: orderModalTabs.general },
  { name: 'Graphs', value: orderModalTabs.graphs },
  { name: 'Events', value: orderModalTabs.events },
];

export const plantGroupTabKey = 'plantGroupTab';
export enum plantGroupTabs {
  recipes = 'recipes',
  archive = 'archive',
  plants = 'plants',
}

export const plantTabNames: TabNames<plantGroupTabs> = [
  { name: 'Recipes', value: plantGroupTabs.recipes },
  { name: 'Archive', value: plantGroupTabs.archive },
  { name: 'Plants', value: plantGroupTabs.plants },
];

export const recipeModalTabKey = 'recipe-modal-tab';
export enum recipeModalTabs {
  general = 'general',
  duplicate = 'duplicate',
  edit = 'edit',
  comments = 'comments',
}

export const recipeModalTabNames: TabNames<recipeModalTabs> = [
  {
    name: 'General',
    value: recipeModalTabs.general,
    // disabled: !query.get("recipe"),
  },
  {
    name: 'Comments',
    value: recipeModalTabs.comments,
    // disabled: !query.get("recipe"),
  },
];

export enum RecipeModalMode {
  VIEW = 'VIEW',
  DUPLICATE = 'DUPLICATE',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}
