import { ValidatedInput } from './ValidatedInput';

interface ValidatedInputProps {
  valueName: string;
  label: string;
  rules: any;
  isDisabled?: boolean;
  className?: string;
  inputClassName?: string;
  fieldArrayName?: string;
  index?: number;
  value?: any;
  isNumber?: boolean;
  defaultValue?: any;
  labelStyle?: string;
  placeholder?: string;
}
export const ValidatedInputLabeled = ({
  valueName,
  isDisabled = false,
  rules,
  label,
  index,
  className,
  fieldArrayName,
  inputClassName,
  isNumber,
  defaultValue,
  labelStyle,
  placeholder,
}: ValidatedInputProps) => {
  const labelStyling = `block text-base font-medium text-gray-700 w-1/4 ${labelStyle}`;

  return (
    <div className={' sm:border-gray-200 flex items-center ' + className}>
      <label htmlFor={valueName} className={labelStyling}>
        {label}
      </label>
      <ValidatedInput
        placeholder={placeholder}
        defaultValue={defaultValue}
        index={index}
        valueName={valueName}
        fieldArrayName={fieldArrayName}
        rules={rules}
        isDisabled={isDisabled}
        className={inputClassName}
        isNumber={isNumber}
      />
    </div>
  );
};
