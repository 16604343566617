import { useQuery } from 'pages/plant-details/PlantRecipe/util/useQuery';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface QueryOptions {
  overwriteQuery?: boolean;
  checkIfAvailable?: boolean;
  onlyReplaceStatedTerms?: boolean;
}

type UseQuerySetter =
  | { term: string; value: string }
  | {
      term: string;
      value: string;
    }[];

export const useQuerySetter = (watchValue?: string) => {
  const query = useQuery();
  const navigate = useNavigate();
  const setQuery = useCallback(
    (
      queryToSet: UseQuerySetter,
      options: QueryOptions = {
        overwriteQuery: false,
        checkIfAvailable: false,
      },
    ) => {
      if (Array.isArray(queryToSet)) {
        const newQuery = queryToSet.reduce((acc, curr) => {
          if (options.checkIfAvailable && query.get(curr.term)) {
            return {
              ...acc,
              [curr.term]: query.get(curr.term) as string,
            };
          } else {
            return {
              ...acc,
              [curr.term]: curr.value,
            };
          }
        }, {});

        navigate({
          pathname: window.location.pathname,
          search: options.overwriteQuery
            ? `?${new URLSearchParams(newQuery).toString()}`
            : `${
                options.onlyReplaceStatedTerms
                  ? stripTerms(
                      window.location.search,
                      queryToSet.map((q) => q.term),
                    )
                  : window.location.search
              }&${new URLSearchParams(newQuery).toString()}`,
        });
      } else if (options.checkIfAvailable || !query.has(queryToSet.term)) {
        navigate({
          pathname: window.location.pathname,
          search: options.overwriteQuery
            ? `?${queryToSet.term}=${queryToSet.value}`
            : `${
                options.onlyReplaceStatedTerms
                  ? stripTerms(window.location.search, [queryToSet.term])
                  : window.location.search
              }&${queryToSet.term}=${queryToSet.value}`,
        });
      }
    },
    [],
  );

  const deleteQueries = useCallback((terms?: string[]) => {
    if (terms) {
      terms.forEach((term) => {
        query.delete(term);
      });
    }
    navigate({
      pathname: window.location.pathname,
      search: terms ? query.toString() : '',
    });
  }, []);

  const stripTerms = useCallback((queryString: string, terms?: string[]) => {
    const queryArray = queryString.replace(/\?/g, '').split('&');

    // filter if queryItem contains any term
    const strippedQuery = queryArray
      .filter((queryItem) => {
        const queryItemIsInTerms = terms?.some((term) =>
          term === '' ? false : queryItem.split('=')[0] === term,
        );

        return !queryItemIsInTerms;
      })
      .join('&');

    return `?${strippedQuery}`;
  }, []);

  const watchQuery = query.get(watchValue || '');

  return { setQuery, query, push: navigate, deleteQueries, watchQuery };
};
export default useQuerySetter;
