import dayjs from 'dayjs';
import { EventLog } from 'stores/event.store';
import { renderEvents } from './EventUtils';
import Feed from './Feed';
interface ClusterProps {
  cluster: EventLog[];
  handleShowEvent: (id: string) => void;
}

const EventCluster = ({ cluster, handleShowEvent }: ClusterProps) => {
  return (
    <>
      <p className="ml-9 font-semibold text-lg mb-2">
        {dayjs(cluster[0].timestamp).format('DD.MM.')}
      </p>
      <Feed>{renderEvents(cluster, handleShowEvent)}</Feed>
    </>
  );
};

export default EventCluster;
