import { Transition } from '@headlessui/react';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { Outlet, useLocation } from 'react-router';
import 'react-tooltip/dist/react-tooltip.css';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';
import OrderChildTaps from './OrderSubPages/OrderChilds/OrderChildTaps';
import OrderGroupInformation from './OrderSubPages/OrderGroupInformation';
import OrderInformation from './OrderSubPages/OrderInformation';
import OrderTreemap from './OrderTreemap/OrderTreemap';
import withLoader from 'util/hocs/withLoader';

const OrderPage = () => {
  const currentOrder = orderStore?.currentOrder;
  const selectedOrder = orderStore?.currentOrder;

  const location = useLocation();

  const orderSelected =
    location.pathname.includes('/analytics') ||
    location.pathname.includes('/events') ||
    false;

  return (
    <div className="py-8 px-16 h-full overflow-x-auto">
      <div className="flex flex-row justify-between">
        <Breadcrumbs selectedOrder={selectedOrder} />
        {orderSelected && <OrderChildTaps />}
      </div>
      <div className="p-4 mt-8 w-full">
        <Transition
          show={!orderSelected}
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <OrderGroupInformation currentOrder={currentOrder} />
          <OrderTreemap />
          <OrderInformation currentOrder={currentOrder} />
        </Transition>

        <Transition
          show={orderSelected}
          enter="transition-opacity duration-500 delay-700"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          className="mt-8"
        >
          <Outlet />
        </Transition>
      </div>
    </div>
  );
};

export default withLoader(mobxify(OrderPage, 'orderStore'));
