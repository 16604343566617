import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RecipeModalMode } from 'util/tabNames';
import { ValidatedSelectLabel } from '../../InputValidation/ValidatedSelectLabel';
import { showAll } from './RecipeFormUtils';
import { useFormContext } from 'react-hook-form';
import plantStore from 'stores/plant.store';
import { CloudAppParams } from 'routes';
import mobxify from 'util/hocs/mobxify';

interface SelectPlantDropDownProps {
  mode: RecipeModalMode;
  recipe?: string;
  hasError: boolean;
}

const SelectPlantDropDown = ({ mode, hasError }: SelectPlantDropDownProps) => {
  const { setValue } = useFormContext();
  const params = useParams<CloudAppParams>();

  useEffect(() => {
    const { plantGroupId: plantGroup } = params;
    if (plantGroup) {
      plantStore?.getPlantsForPlantGroups(plantGroup);
    }
  }, [params]);

  return (
    <div className="relative">
      <ValidatedSelectLabel
        label="Plants"
        valueName="selectedPlants"
        isMulti={true}
        options={plantStore?.plantsForRecipe}
        hasError={hasError}
        rules={{ required: true }}
        className="flex justify-between mt-4"
        inputClassName="w-3/4"
        isDisabled={mode === RecipeModalMode.VIEW}
      />
      {mode !== RecipeModalMode.VIEW &&
        showAll(true, () => {
          if (!plantStore?.plantsForRecipe) return;
          setValue('selectedPlants', plantStore?.plantsForRecipe);
        })}
    </div>
  );
};

export default mobxify(SelectPlantDropDown, 'plantStore');
