import { PlusIcon } from '@heroicons/react/24/outline';
import { useFieldArray } from 'react-hook-form';
import { RecipeModalMode } from 'util/tabNames';
import SinglePhase from './RecipeFormComponents/SinglePhase';
import RecipeModalHeader from './RecipeModalHeader';
import mobxify from 'util/hocs/mobxify';

interface RecipeFormInputProps {
  mode: RecipeModalMode;
}
const RecipeFormInputs = ({ mode }: RecipeFormInputProps) => {
  const { fields, remove, append } = useFieldArray({ name: 'phases' });

  return (
    <div key="form_header" className="border-r grow h-full overflow-auto">
      <RecipeModalHeader mode={mode} />
      {fields.map((item: any, index: number) => {
        return (
          <div key={item.id} className="border-b relative">
            <SinglePhase
              index={index}
              mode={mode ? mode : RecipeModalMode.VIEW}
              remove={remove}
            />
          </div>
        );
      })}
      {mode !== RecipeModalMode.VIEW && (
        <div className="p-3 m-3">
          <button
            onClick={() => {
              append({});
            }}
            className="flex p-8 justify-center items-center border-2 border-dotted h-full w-full flex-col hover:border-solid text-gray-400 hover:text-gray-600 cursor-pointer rounded-lg"
          >
            <PlusIcon className="w-7 h-7" />
            <div className="w-16 text-center">Add a phase</div>
          </button>
        </div>
      )}
      <div className="h-32" />
    </div>
  );
};

export default mobxify(
  RecipeFormInputs,
  'modalStore',
  'recipeStore',
  'warehouseStore',
);
