import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';

interface SuffixSelectProps {
  defaultOption: number;
  options: string[];
  onChange: React.Dispatch<React.SetStateAction<string>>;
  isDisabled?: boolean;
}

//@ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SuffixSelect = ({
  onChange,
  options,
  isDisabled,
  defaultOption,
}: SuffixSelectProps) => {
  const [selectedFilter, setSelectedFilter] = useState(defaultOption);

  const generateText = () => {
    return options[selectedFilter];
  };

  const renderOption = () => {
    return options.map((option, index) => (
      <Menu.Item key={`${option} + ${index}`}>
        {({ active }) => (
          <div
            onClick={() => {
              if (isDisabled) return;
              onChange(options[index]);
              setSelectedFilter(index);
            }}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block px-4 py-2 text-sm cursor-pointer',
            )}
          >
            {option}
          </div>
        )}
      </Menu.Item>
    ));
  };
  return (
    <div className="-ml-1 sm:col-span-2 2xl:col-span-1 items-center h-full">
      <Menu as="div" className="relative inline-block text-left w-full h-full">
        <Menu.Button
          disabled={isDisabled}
          className={`inline-flex w-full justify-center rounded-md rounded-l-none border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-100 h-full ${
            isDisabled ? 'bg-gray-50' : 'bg-white'
          }`}
        >
          <span className="ml-2">{generateText()}</span>
          <ChevronDownIcon
            className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">{renderOption()}</div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default SuffixSelect;
