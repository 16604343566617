const hoursToMilliseconds = (hours: number) => hours * 60 * 60 * 1000;
const millisecondsToHours = (milliseconds: number) =>
  milliseconds / 1000 / 60 / 60;
//FormDuration is in hours or days, DBDuration is in milliseconds
export const FormDurationToDBDuration = (duration: number, inDays: boolean) => {
  return hoursToMilliseconds(inDays ? duration * 24 : duration);
};

export const DBDurationToFormDuration = (duration: number) => {
  return millisecondsToHours(duration);
};
