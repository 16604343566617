import Loader from 'react-loader-spinner';
import whiteSpaceToDash from 'util/whiteSpaceToDash';
import { ValidatedCreatable } from './ValidatedCreatable';

interface ValidatedInputProps {
  valueName: string;
  label: string;
  rules: any;
  loading?: boolean;
  isDisabled?: boolean;
  hasError: boolean;
  options: any;
  onCreate: any;
  inputClassName?: string;
  className?: string;
  fieldArrayName?: string;
  index?: number;
  labelStyle?: string;
}
export const ValidatedCreatableLabel = ({
  valueName,
  label,
  rules,
  hasError,
  options,
  onCreate,
  loading,
  inputClassName,
  className,
  index,
  fieldArrayName,
  labelStyle,
  isDisabled = false,
}: ValidatedInputProps) => {
  const labelStyling = `block text-base font-medium text-gray-700 w-1/4 ${labelStyle}`;

  return (
    <>
      <div className={'sm:border-gray-200 flex items-center ' + className}>
        <label htmlFor={whiteSpaceToDash(label)} className={labelStyling}>
          {label}
        </label>
        {loading && (
          <div className="border focus:outline-none focus:border-green-400 border-gray-300  rounded-md shadow-sm py-2 sm:text-sm b flex justify-center w-3/4">
            {' '}
            <Loader type="Puff" color="#16a34a" height={16} width={16} />{' '}
          </div>
        )}
        {!loading && (
          <ValidatedCreatable
            name={label}
            index={index}
            valueName={valueName}
            rules={rules}
            fieldArrayName={fieldArrayName}
            onCreate={onCreate}
            hasError={hasError}
            options={options}
            className={inputClassName}
            isDisabled={isDisabled}
          />
        )}
      </div>
    </>
  );
};
