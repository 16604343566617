import Axios from 'util/Axios';
import { action, makeAutoObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import { Plant } from '../models/Plant.model';
import { PlantGroup } from '../models/PlantGroup.model';
import { Recipe } from '../models/Recipe.model';

export class PlantStore {
  @observable plantGroups: PlantGroup[] = [];
  @observable plantGroup?: PlantGroup;
  @observable plant?: Plant;
  @observable plants: Plant[] = [];
  @observable allPlants: Plant[] = [];
  @observable recipesOfPlant: Recipe[] = [];

  @observable plantsForRecipe: any;
  @observable selectedPlantsForRecipe: any;
  @observable numberthing = 0;
  @observable plantGroupName = 'Plant Group';

  constructor() {
    makeAutoObservable(this);
  }

  async getPlantGroups() {
    const response = await Axios.get('/plant-groups', {
      params: { sort: 'name' },
    });
    if (response.status === 200) {
      this.plantGroups = [...response.data];
    }
  }

  clearPlant() {
    this.plant = undefined;
    this.plantGroup = undefined;
  }

  async createPlantGroup(name: string) {
    try {
      await Axios.post('/plant-groups', { name });
      await this.getPlantGroups();
      toast.success('Plant was successfully created.');
    } catch (error) {
      toast.error(
        'There was an error with creating Plants Groups. Please try again later',
      );
    }
  }

  async createPlant(
    data: { expectedDiameter: number; name: string; image?: any },
    plantGroup: string,
  ) {
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('expectedDiameter', data.expectedDiameter.toString());
      formData.append('plantGroup', plantGroup);
      formData.append('image', data.image);

      await Axios.post('/plants/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast.success('Plant was successfully created.');
    } catch (error) {
      toast.error(
        'There was an error with creating Plants. Please try again later',
      );
    }
  }

  async getPlant(id: string) {
    const response = await Axios.get('/plants/' + id);
    if (response.status === 200) {
      this.plant = response.data;
    }
  }

  async getPlants(id: string) {
    const response = await Axios.get(
      `/plant-groups/${id}?populate=plants,recipeGroups`,
    );
    if (response.status === 200) {
      this.plants = response.data.plants;
    }
  }

  async getRecipesOfPlant(id: string) {
    const response = await Axios.get(
      `plants/${id}?isDeleted=false&archived=false&populate=recipes`,
    );
    if (response.status === 200) {
      this.recipesOfPlant = response.data.recipes;
    }
  }
  @action
  async getPlantGroup(id: string) {
    this.plantGroup = undefined;
    const response = await Axios.get(
      `/plant-groups/${id}?populate=recipeGroups`,
    );

    if (response.status === 200) {
      this.plantGroup = response.data;
    }
  }

  async updatePlant(id: string, data: any, plantGroupId: string) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('expectedDiameter', data.expectedDiameter.toString());
    formData.append('image', data.image);
    formData.append('plantGroup', plantGroupId);
    formData.append('_id', data._id);
    const response = await Axios.put('/plants/' + id, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 200) {
      toast.success('Plant was successfully updated.');
    }
  }

  async getAllPlants(query?: string) {
    const response = await Axios.get(`/plants${query || ''}`);

    if (response.status === 200) {
      this.allPlants = response.data;
    }
  }

  async deletePlant(plantId: string) {
    const response = await Axios.delete('/plants/' + plantId);
    if (response.status === 200) {
      toast.success('Plant deleted successfully.');
    } else {
      toast.error('There was an error. Please try again later');
    }

    if (this.plantGroup) {
      await this.getPlants(this.plantGroup._id);
    }
  }

  async updatePlantGroupName(id: string, name: string) {
    await Axios.put('/plant-groups/' + id, {
      name,
    });
  }

  async deletePlantGroup(plantGroupId: string) {
    const response = await Axios.delete('/plant-groups/' + plantGroupId);
    if (response.status === 200) {
      toast.success('Plant Group deleted successfully.');
    } else {
      toast.error('There was an error deleting the Plant Group.');
    }
  }

  @action
  async getPlantsForRecipe(id: string) {
    const response = await Axios.get(`/plants?recipes=${id}&select=_id,name`);
    if (response.status === 200) {
      this.selectedPlantsForRecipe = response.data;
    }
  }

  @action
  async setPlantGroup(plantGroup: PlantGroup) {
    this.plantGroup = plantGroup;
    this.plantGroupName = plantGroup.name;
  }

  @action
  async getPlantsForPlantGroups(id: string) {
    const response = await Axios.get(
      `/plant-groups/${id}?populate=plants.name&select=plants`,
    );
    if (response.status === 200) {
      this.plantsForRecipe = response.data.plants;
    }
  }
}

const plantStore = new PlantStore();
export default plantStore;
