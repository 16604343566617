import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { Fragment } from 'react';

interface DropDownProps {
  dropDownOptions: DropDownOption[];
}
export interface DropDownOption {
  handleClick: () => void;
  label: string;
  icon: (
    props: React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    },
  ) => JSX.Element;
}

const renderOptions = (dropDownOptions: DropDownOption[]) => {
  return dropDownOptions.map((option: DropDownOption) => (
    <Menu.Item key={option.label}>
      {({ active }) => (
        <div
          onClick={() => option.handleClick()}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'px-4 py-2 text-sm flex justify-center items-center cursor-pointer',
          )}
        >
          {option.label}
          <option.icon className="w-3 h-3 ml-1" />
        </div>
      )}
    </Menu.Item>
  ));
};

const CardSelectDropDown = ({ dropDownOptions }: DropDownProps) => {
  return (
    <Menu as="div" className="flex-shrink-0 pr-2">
      <Menu.Button className="w-8 h-8 s bg-transparent inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="w-4 h-4" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-5 top-8 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1">{renderOptions(dropDownOptions)}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CardSelectDropDown;

/*
 */
