import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Plant } from 'models/Plant.model';
import { PlantGroup } from 'models/PlantGroup.model';
import { Recipe } from 'models/Recipe.model';
import { RecipeGroup } from 'models/RecipeGroup.model';
import OrderPlantGroupInput from '../InputValidation/CreateOrder/OrderPlantGroupInput';
import OrderPlantInput from '../InputValidation/CreateOrder/OrderPlantInput';
import OrderRecipeInput from '../InputValidation/CreateOrder/OrderRecipeInput';
import OrderStartphaseInput from '../InputValidation/CreateOrder/OrderStartphaseInput';
import OrderTargetInput from '../InputValidation/CreateOrder/OrderTargetInput';
import OrderDescTextbox from '../InputValidation/CreateOrder/OrderDescTextBox';
import { addVersionToRGs, getAmountOfPlantsPerTray } from './CreateOrderUtils';
import OrderDatePicker from '../InputValidation/CreateOrder/OrderDatePicker';
import OrderAmountInput from '../InputValidation/CreateOrder/OrderAmountInput';
import plantStore from 'stores/plant.store';
import recipeStore from 'stores/recipe.store';
import warehouseStore from 'stores/warehouse.store';
import mobxify from 'util/hocs/mobxify';

interface CreateOrderInputProps {
  currentRecipeGroup: RecipeGroup;
  currentPlant: Plant;
}
const CreateOrderInputs = ({
  currentRecipeGroup,
  currentPlant,
}: CreateOrderInputProps) => {
  const { watch, resetField } = useFormContext();
  const [plantOptions, setPlantOptions] = useState<Plant[]>();
  const [plantGroupOptions, setPlantGroupOptions] = useState<PlantGroup[]>();
  const [recipeOptions, setRecipeOptions] = useState<RecipeGroup[]>();
  const [maxAmount, setMaxAmount] = useState<number>(0);

  const startPhaseOptions = (
    (currentRecipeGroup?.selected as Recipe) || { phases: [{}] }
  )?.phases?.map((phase: any, index: number) => ({
    id: index,
    name: `Phase ${index + 1}`,
  }));

  useEffect(() => {
    if (!plantStore?.plantGroups) return;
    setPlantGroupOptions(plantStore?.plantGroups);
    setPlantOptions(undefined);
    setRecipeOptions(undefined);
    setMaxAmount(0);
  }, [plantStore?.plantGroups]);

  useEffect(() => {
    if (!recipeStore?.plantsOfGroup || !recipeStore?.recipeGroups) {
      return;
    }
    setPlantOptions(recipeStore?.plantsOfGroup);
    setRecipeOptions(addVersionToRGs(recipeStore?.recipeGroups));
  }, [recipeStore?.recipeGroups, recipeStore?.plantsOfGroup]);

  useEffect(() => {
    const recipesWithVersion = addVersionToRGs(recipeStore?.recipeGroups);
    warehouseStore?.checkWarehouseItem('plant', currentPlant?._id);
    if (!currentPlant) {
      setRecipeOptions(recipesWithVersion);
      return;
    }
    setRecipeOptions(
      recipesWithVersion.filter((rg: RecipeGroup) => {
        const RecipeGroupPlants = (rg.selected as Recipe).plants as string[];
        if (!RecipeGroupPlants) return false;
        return RecipeGroupPlants.includes(currentPlant._id);
      }),
    );
  }, [currentPlant]);

  useEffect(() => {
    resetField('startPhase');
    warehouseStore?.checkWarehouseItem(
      'soilType',
      (currentRecipeGroup?.selected as Recipe)?.soilType,
    );
    if (!currentRecipeGroup) {
      setPlantOptions(recipeStore?.plantsOfGroup);
      return;
    }
    setPlantOptions(
      recipeStore?.plantsOfGroup?.filter((plant: Plant) => {
        const RecipeGroupPlants = (currentRecipeGroup.selected as Recipe)
          ?.plants as string[];
        if (!RecipeGroupPlants) return false;
        return RecipeGroupPlants.includes(plant._id);
      }),
    );
  }, [currentRecipeGroup]);

  useEffect(() => {
    setMaxAmount(
      Math.min(
        warehouseStore?.warehousePlant?.amount || 0,
        warehouseStore?.warehouseSoilType?.amount || 0,
      ),
    );
  }, [warehouseStore?.warehousePlant, warehouseStore?.warehouseSoilType]);

  const [isSplitOrder, orderAmount] = useMemo(() => {
    const isSplitOrder =
      getAmountOfPlantsPerTray(currentRecipeGroup) < watch('amount');
    const orderAmount = Math.ceil(
      watch('amount') / getAmountOfPlantsPerTray(currentRecipeGroup),
    );

    return [isSplitOrder, orderAmount];
  }, [watch('amount'), JSON.stringify(currentRecipeGroup)]);

  return (
    <>
      <div className="mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Create Order
        </h3>
      </div>
      <OrderTargetInput />
      <OrderDatePicker />
      <OrderDescTextbox />
      <div className="border-t border-gray-200"></div>
      <h3 className="text-lg leading-6 mt-5 font-medium text-gray-900 ">
        Choose Plant and Recipe
      </h3>
      <OrderPlantGroupInput options={plantGroupOptions} />
      <OrderPlantInput options={plantOptions} />
      <OrderRecipeInput options={recipeOptions} />
      <OrderStartphaseInput
        options={startPhaseOptions}
        currentRecipeGroup={currentRecipeGroup}
      />
      <OrderAmountInput maxAmount={maxAmount} />
      <div className="h-5">
        {isSplitOrder && (
          <p className="text-red-500 text-sm">
            This order will be split into {orderAmount} orders.
          </p>
        )}
      </div>
    </>
  );
};

export default mobxify(
  CreateOrderInputs,
  'modalStore',
  'plantStore',
  'recipeStore',
  'warehouseStore',
);
