import { useEffect } from 'react';
import typeStore from 'stores/type.store';
import { RecipeModalMode } from 'util/tabNames';
import { ValidatedCreatableLabel } from '../../InputValidation/ValidatedCreatableLabel';
import mobxify from 'util/hocs/mobxify';

interface SoilTypeCreatableProps {
  hasError: boolean;
  mode: RecipeModalMode;
}

const SoilTypeCreatable = ({ hasError, mode }: SoilTypeCreatableProps) => {
  useEffect(() => {
    typeStore?.loadSoilTypes();
  }, []);

  const handleCreateSoil = async (inputValue: string) => {
    await typeStore?.createSoilType({
      label: inputValue,
      value: inputValue.toLowerCase(),
    });
    await typeStore?.loadSoilTypes();
  };

  return (
    <ValidatedCreatableLabel
      label="Soil"
      valueName="soilType"
      inputClassName="w-3/4"
      className="flex justify-between mt-4 "
      hasError={hasError}
      rules={{ required: true }}
      onCreate={handleCreateSoil}
      options={typeStore?.soilTypes}
      isDisabled={mode === RecipeModalMode.VIEW}
    />
  );
};

export default mobxify(SoilTypeCreatable, 'typeStore');
