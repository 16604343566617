import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { OrderGroupModel, OrderModel } from 'models/Order.model';
import { AnalyticStore } from 'stores/analytics.store';
import { OrderStore } from 'stores/order.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface _WSResponse {
  data: OrderGroupModel[];
}

export class AnalyticWebsocket {
  private analyticStore: AnalyticStore;
  private orderStore: OrderStore;
  private organizationId = '';

  constructor(
    analyticStore: AnalyticStore,
    orderStore: OrderStore,
    organizationId: string,
  ) {
    this.analyticStore = analyticStore;
    this.organizationId = organizationId;
    this.orderStore = orderStore;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_ ${WEBSOCKETCHANNELS.ANALYTICS}`,
        //TODO type - and eliminate inconsistency in types wheter it is order or orders
        (...args: WebsocketMessage<any>[]) => {
          for (const obj of args) {
            if (!obj.data?.order) return;
            if (obj.command === WEBSOCKETCOMMANDS.UPDATED)
              return this.updateAnalyticsData(obj);

            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              if (!this.orderStore.orderGroups) return;
              if (!this.findOrderGroup(obj.data.order)) return;

              this.orderStore.orderGroups.push(obj.data);
            }
          }
        },
      );
  }

  private findOrderGroup(orderId: OrderModel['_id']) {
    if (!this.orderStore.orderGroups) return;
    return this.orderStore.orderGroups.find(
      (
        orderGroup: OrderGroupModel & {
          orders: OrderModel[];
        },
      ) => orderGroup.orders.find((order) => order._id === orderId),
    );
  }

  //TODO TYPE with WSResponse
  private async updateAnalyticsData(obj: any) {
    if (!this.orderStore.currentOrder) return;
    if (this.orderStore.currentOrder._id !== obj.data.order) return;
    //INFO fallback for older analyticsdata without an oid
    await this.analyticStore.loadAnalyticData(
      this.orderStore.currentOrder.oid || this.orderStore.currentOrder._id,
    );
  }
}
