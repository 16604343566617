import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import GenericModal from '../GenericModal';
import { ValidatedInputLabeled } from '../InputValidation/ValidatedInputLabel';
import { Dropzone } from '../../Dropzone/Dropzone';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ModalName from 'enums/ModalName.enum';
import modalStore from 'stores/modal.store';
import plantStore from 'stores/plant.store';
import { CloudAppParams } from 'routes';
import mobxify from 'util/hocs/mobxify';

interface PlantFormDto {
  expectedDiameter: number;
  image: File;
  name: string;
}

const PlantFormModal = () => {
  const [imageFormData, setImageFormData] = useState<File>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const params = useParams<CloudAppParams>();
  const methods = useForm<PlantFormDto>();

  useEffect(() => {
    if (
      modalStore?.customData?.mode === 'edit' &&
      modalStore?.customData?.name
    ) {
      methods.setValue('name', modalStore?.customData.name);
      methods.setValue(
        'expectedDiameter',
        modalStore?.customData.expectedDiameter,
      );
    }
  }, [modalStore?.customData]);

  const createHandler = async (data: PlantFormDto) => {
    if (imageFormData) {
      data.image = imageFormData;
    }
    if (!params?.plantGroupId) return;

    await plantStore?.createPlant(data, params.plantGroupId);
    methods.reset();
    modalStore?.closeModal();
    setImageFormData(undefined);
    plantStore?.getPlants(params.plantGroupId);
    methods.reset();
  };

  const editHandler = async (data: PlantFormDto) => {
    try {
      if (!params?.plantGroupId) return;

      if (imageFormData) {
        data.image = imageFormData;
      }
      await plantStore?.updatePlant(
        modalStore?.customData.plantId,
        {
          ...data,
          _id: modalStore?.customData.plantId,
        },
        params.plantGroupId,
      );
      modalStore?.closeModal();
      plantStore?.getPlants(params.plantGroupId);
      setImageFormData(undefined);
      methods.reset();
    } catch (error) {
      toast.error(`Plant could not be updated, ${(error as Error).message}`);
    }
  };

  const onSubmit = async (data: PlantFormDto) => {
    const mode = modalStore?.customData?.mode;
    if (!mode) return;

    setDisabled(true);

    if (mode === 'create') await createHandler(data);
    if (mode === 'edit') await editHandler(data);

    setDisabled(false);
  };

  return (
    <GenericModal formMethods={methods} modalName={ModalName.PLANT_FORM}>
      <div className="px-8 py-4">
        <FormProvider {...methods}>
          <form
            id="create_plant_type"
            onSubmit={methods.handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <div className="flex justify-between">
              {modalStore?.customData?.mode === 'edit' && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Edit plant
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Use this form to edit a plant.
                  </p>
                </div>
              )}
              {modalStore?.customData?.mode === 'create' && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Create plant
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Use this form to create a plant.
                  </p>
                </div>
              )}
              <button
                type="button"
                name="close"
                className="bg-white  rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={(e) => {
                  e.preventDefault();
                  methods.reset();
                  modalStore?.closeModal();
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="sm:border-t sm:border-gray-200 sm:pt-3 mt-3">
              <ValidatedInputLabeled
                className="justify-between"
                label="Plant name"
                rules={{ required: true }}
                valueName="name"
              />
            </div>
            <div className="sm:border-t sm:border-gray-200 sm:pt-3 mt-3 mb-3">
              <ValidatedInputLabeled
                className="justify-between mm"
                label="Expected Diameter (mm)"
                isNumber={true}
                rules={{ required: true, min: 0 }}
                valueName="expectedDiameter"
              />
            </div>
            <div
              className={
                ' sm:border-gray-200 items-top border-t pt-3  flex justify-between'
              }
            >
              <div className="block text-base font-medium text-gray-700 ">
                Image
              </div>
              <Dropzone setExternalState={setImageFormData} />
            </div>
            <div className="pt-3">
              <div className="flex justify-end">
                {modalStore?.customData?.mode === 'edit' && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!params?.plantGroupId) return;
                      modalStore?.openModal(ModalName.DELETE_PLANT, {
                        plantGroupId: modalStore.customData.plantGroupId,
                        plantId: modalStore.customData.plantId,
                      });
                    }}
                    name="cancel"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-100 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Delete
                  </button>
                )}
                <button
                  disabled={disabled}
                  type="submit"
                  className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                    disabled && 'cursor-wait'
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </GenericModal>
  );
};

export default mobxify(PlantFormModal, 'modalStore', 'plantStore');
