import { action, makeObservable, observable } from 'mobx';
import Axios from 'util/Axios';
export interface EventLog {
  _id: string;
  name: string;
  order: string;
  data: {
    commentId: string;
    [key: string]: string | number | boolean | any;
  };
  timestamp: Date;
  userName?: string;
  comment?: string;
}
export class EventStore {
  @observable events: EventLog[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getEvents(order: string, searchTerms?: string[]) {
    const response = await Axios.get('/events/filtered', {
      params: {
        order: order,
        search: searchTerms,
      },
    });

    if (response.status === 200) {
      this.events = response.data;
    }
  }
}

const eventStore = new EventStore();
export default eventStore;
