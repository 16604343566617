import { useFormContext } from 'react-hook-form';

const OrderDescTextbox = () => {
  const { register } = useFormContext();
  return (
    <textarea
      {...register('description', { required: false, maxLength: 300 })}
      id="description"
      name="description"
      className="w-full mt-2 mb-5 border focus:outline-none focus:border-green-400 border-gray-300 form-control rounded-md shadow-sm py-2 px-3 sm:text-sm b transition ease-in-out"
      defaultValue={''}
      placeholder="Write a description (optional)"
    />
  );
};

export default OrderDescTextbox;
