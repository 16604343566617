import { Outlet } from 'react-router-dom';
import './Container.scss';

import CreateOrderModal from '../modal/CreateOrderModal/CreateOrderModal';

import CreatePlantGroupModal from '../modal/CreatePlantGroupModal/CreatePlantGroupModal';
import DeleteOrderModal from '../modal/DeleteOrderModal/DeleteOrderModal';
import DeleteRecipeModal from '../modal/DeleteRecipeModal/DeleteRecipeModal';
import OrderModal from '../modal/OrderModal';
import RecipeModal from '../modal/RecipeModal/CreateRecipeModal';
import WarehouseModal from '../modal/WarehouseModal/WarehouseModal';
import Navigationbar from '../navigation/Navigationbar';
import WebsocketProvider from '../WebsocketProvider/WebsocketProvider';
import ArchiveRecipeModal from '../modal/ArchiveRecipeModal/ArchiveRecipeModal';
import PlantFormModal from '../modal/CreatePlantModal/PlantFormModal';
import NutritionSolutionGroupModal from '../modal/NutritionSolutionGroupModal/NutritionSolutionGroupModal';
import NutritionSolutionModal from '../modal/NutritionSolutionModal/NutritionSolutionModal';
import RecipeGroupModal from '../modal/RecipeGroupModal/RecipeGroupModal';
import DeleteNutritionSolutionModal from '../modal/DeleteNutritionSolutionModal/DeleteNutritionSolutionModal';
import DeletePlantModal from '../modal/DeletePlantModal/DeletePlantModal';

interface ContainerProps {
  className?: string;
}

const Container = ({ className }: ContainerProps) => (
  <WebsocketProvider>
    <div className="flex flex-row overflow-hidden">
      <Navigationbar />
      <div className={'vertic-container overflow-auto ' + className}>
        <Outlet />

        {/* MODALS */}
        <CreateOrderModal />
        <OrderModal />
        <RecipeGroupModal />
        <CreatePlantGroupModal />
        <RecipeModal />
        <DeleteOrderModal />
        <DeleteRecipeModal />
        <ArchiveRecipeModal />
        <PlantFormModal />
        <DeletePlantModal />
        <WarehouseModal />
        <NutritionSolutionGroupModal />
        <NutritionSolutionModal />
        <DeleteNutritionSolutionModal />
      </div>
    </div>
  </WebsocketProvider>
);

export default Container;
