import OrderModalGraphs from 'components/modal/OrderModal/OrderModalGraphs';
import withLoader from 'util/hocs/withLoader';

const OrderAnalytics = () => (
  <div className="mt-8 h-1/2">
    <OrderModalGraphs />
  </div>
);

export default withLoader(OrderAnalytics);
