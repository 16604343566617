import SuffixSelect from 'components/SuffixSelect/SuffixSelect';
import { useFormContext } from 'react-hook-form';

interface ValidatedDurationInputProps {
  valueName: string;
  register: any;
  border: string;
  isDisabled?: boolean;
  inputKey: string;
  defaultValue: any;
  required: boolean;
  index: number;
}

const ValidatedDurationInput = ({
  inputKey,
  valueName,
  border,
  isDisabled,
  defaultValue,
  required,
  index,
}: ValidatedDurationInputProps) => {
  const { register, setValue, watch } = useFormContext();

  return (
    <div className="relative w-full flex">
      <input
        key={inputKey}
        disabled={isDisabled}
        defaultValue={defaultValue}
        {...register(`${valueName}` as const, {
          setValueAs: (v: any) => {
            if (!v) return null;
            return parseInt(v);
          },
          required: required,
          min: 1,
        })}
        type="number"
        className={`${border} w-full`}
        name={valueName}
        id={valueName}
      />
      <SuffixSelect
        isDisabled={isDisabled}
        options={['days', 'hours']}
        defaultOption={
          watch(`phases.${index}.duration_type`) === 'hours' ? 1 : 0
        }
        onChange={(e) => {
          setValue(`phases.${index}.duration_type`, e);
        }}
      />
    </div>
  );
};

export default ValidatedDurationInput;
