import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { Recipe } from 'models/Recipe.model';
import { RecipeStore } from 'stores/recipe.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface RecipeReponse {
  data: {
    _id: Recipe['_id'];
  };
}

export class RecipeWebsocket {
  private recipeStore: RecipeStore;
  private organizationId = '';

  constructor(recipeStore: RecipeStore, organizationId: string) {
    this.recipeStore = recipeStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.RECIPE}`,
        (...args: WebsocketMessage<RecipeReponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.findOne(obj.data._id);
            }
          }
        },
      );
  }

  private async findOne(id: Recipe['_id']) {
    if (!this.recipeStore?.recipe) return;
    this.recipeStore?.getRecipeGroups(id, false);
  }
}
