import { useState } from 'react';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';

interface CommentInputProps {
  orderid: string;
}
const CommentInput = ({ orderid }: CommentInputProps) => {
  const [text, setText] = useState('');
  return (
    <div className="pl-0.5 items-center border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-green-500 focus-within:ring-1 focus-within:ring-green-500 mr-4 my-4">
      <label htmlFor="comment" className="sr-only">
        Add your comment
      </label>
      <div className="flex justify-between">
        <textarea
          rows={1}
          name="comment"
          id="comment"
          className="w-full border-0 resize-none text-m focus:outline-none inline-flex items-center content-center p-2"
          placeholder="Add your comment..."
          value={text}
          onChange={(event) => {
            setText(event?.target.value);
          }}
        />
        <button
          onClick={() => {
            if (text.length > 0) {
              orderStore?.addCommentToOrder(orderid as string, text);
              orderStore?.getComments(orderid as string);
              setText('');
            }
          }}
          className="inline-flex items-center px-4 py-1 mr-0.5 my-0.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Post
        </button>
      </div>
    </div>
  );
};

export default mobxify(CommentInput, 'orderStore');
