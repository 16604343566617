export const determineInvalidity = (
  errors: any,
  valueName: string,
  fieldArrayName?: string,
  index?: number,
) => {
  if (Object.keys(errors).length === 0) {
    return false;
  }
  if (fieldArrayName && index !== undefined) {
    if (!errors[`${fieldArrayName}`]) return false;
    if (!errors[`${fieldArrayName}`][index]) return false;
    return errors[`${fieldArrayName}`][index][valueName];
  }
  return errors[valueName];
};
