import { PlusIcon } from '@heroicons/react/20/solid';
import ModalName from 'enums/ModalName.enum';
import { FC } from 'react';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

interface Props {
  typeLabel: string;
}
const CreateWarehouseButton: FC<Props> = ({ typeLabel }) => (
  <li
    className="min-h-[12rem] col-span-1 flex flex-col text-center bg-white hover:shadow-lg rounded-lg shadow box p-3 cursor-pointer"
    onClick={() => {
      modalStore.openModal(ModalName.WAREHOUSE);
    }}
  >
    <button className="flex p-8 justify-center items-center border-2 border-dotted h-full w-full flex-col rounded-lg  hover:border-solid text-gray-400 hover:text-gray-600">
      <PlusIcon className="w-7 h-7" />
      <div className="w-16 text-center">Add a new {typeLabel}</div>
    </button>
  </li>
);

export default mobxify(CreateWarehouseButton, 'modalStore');
