import { useFormContext } from 'react-hook-form';
import { Plant } from 'models/Plant.model';
import { ValidatedSelectLabel } from '../ValidatedSelectLabel';
interface OrderPlantInputProps {
  options?: Plant[];
}
const OrderPlantInput = ({ options }: OrderPlantInputProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <ValidatedSelectLabel
      valueName="plant"
      options={options}
      label="Plant"
      hasError={errors.plant != null}
      className="flex justify-between mt-2"
      inputClassName="w-3/4"
      maxHeight={150}
      rules={{ required: true }}
    />
  );
};

export default OrderPlantInput;
