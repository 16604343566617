import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { AnalyticDataType } from 'models/AnalyticsDataType.model';
import Axios from 'util/Axios';
import { getTypeForKey } from 'util/KeyMappers';

export class CompareRecipeStore {
  @observable analytics1: any = {};
  @observable analytics2: any;

  constructor() {
    makeObservable(this);
  }

  calculateDay(millis: number) {
    return Math.floor(millis / 1000 / 60 / 60 / 24);
  }

  calculateAverage(values: any[]) {
    let result = 0;

    for (const val of values) {
      result += val;
    }
    return result / values.length;
  }

  getDay0(data: any[]) {
    let result = moment();
    if (data && data.length > 0) {
      for (const entry of data) {
        const newDate = moment(entry._id);
        if (newDate.isBefore(result)) {
          result = newDate;
        }
      }
    }
    return result;
  }

  generateMapping(analyticDataTypes: AnalyticDataType[], data: any) {
    const values: any = {};
    if (data && data.length > 0) {
      const day0 = this.getDay0(data);
      for (const set of data) {
        for (const day of set.data) {
          for (const key in day) {
            const diff = this.calculateDay(moment(day.date).diff(day0));
            const type = getTypeForKey(analyticDataTypes, key as any);
            if (type.showInDiagram) {
              if (!values[key + '_' + diff]) {
                values[key + '_' + diff] = [];
              }

              values[key + '_' + diff].push(day[key]);
            }
          }
        }
      }
    }
    return values;
  }

  @action
  async loadAnalytics(
    recipeId: string,
    index: number,
    analyticDataTypes: AnalyticDataType[],
  ) {
    const response = await Axios.get(`/recipes/${recipeId}/analytics`);

    const result: any = {};
    const values: any = this.generateMapping(
      analyticDataTypes,
      response.data.reverse(),
    );

    for (const valueKey in values) {
      const [key, date] = valueKey.split('_');

      if (!result[key]) {
        result[key] = { key: key, values: [] };
      }

      result[key].values.push({
        date: parseInt(date),
        value: this.calculateAverage(values[valueKey]),
        key: index + ':' + key,
      });
    }

    const lines: any = [];
    for (const key in result) {
      lines.push(result[key]);
    }

    if (index === 0) {
      this.setAnalyticsDataOne({ lines });
    } else if (index === 1) {
      this.setAnalyticsDataTwo({ lines });
    }
  }

  @action
  setAnalyticsDataOne(data: any) {
    this.analytics1 = data;
  }

  @action
  setAnalyticsDataTwo(data: any) {
    this.analytics2 = data;
  }
}

const compareRecipeStore = new CompareRecipeStore();
export default compareRecipeStore;
