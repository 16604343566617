import { useMemo } from 'react';
import { Phase } from 'models/Phase.model';

interface PropTypes {
  bgcolor: string;
  phases: Phase[];
  currentPhase: number;
}

const ProgressBar = (props: PropTypes) => {
  const { phases, currentPhase } = props;
  const fullRecipeDuration = useMemo(() => {
    let fullDuration = 0;
    phases.forEach((phase) => {
      fullDuration += phase.duration;
    });
    return fullDuration / (1000 * 3600 * 24);
  }, [phases]);

  const calculateWidth = (phases: Phase[], index: number) => {
    const phaseDuration = phases[index].duration / (1000 * 3600 * 24);
    const decimal = phaseDuration / fullRecipeDuration;
    const percent = decimal * 100;
    return percent + '%';
  };

  const checkPhase = (index: number, phases: number) => {
    if (index === phases - 1) {
      return 'rounded-br-lg';
    } else {
      return 'border-r-2 border-gray-200';
    }
  };

  const getStyles = (index: number, currentPhase: number) => {
    const styles = {
      height: '100%',
      width: calculateWidth(phases, index),
      borderRadius: 'inherit',
      backgroundColor: 'gray',
      textAlign: 'right' as const,
    };

    if (index + 1 < currentPhase) {
      styles.backgroundColor = '#416241';
    }
    if (index + 1 > currentPhase) {
      styles.backgroundColor = '#D1D5DB';
    }
    if (index + 1 === currentPhase) {
      styles.backgroundColor = '#008000';
    }
    return styles;
  };
  return (
    <div
      className={`2xl:h-3 sm:h-2 w-full bg-gray-200 rounded-sm flex grid-cols-${phases}`}
    >
      {phases.map((phase, index) => (
        <div
          key={'phase' + index}
          className={`col-span-1 ` + checkPhase(index, phases.length)}
          style={getStyles(index, currentPhase)}
        />
      ))}
    </div>
  );
};
export default ProgressBar;
