import { io } from 'socket.io-client';

const WEBSOCKET_URL = process.env.REACT_APP_API;
export default class SocketHandler {
  private static _instance?: SocketHandler;
  private socket: any;

  connect() {
    this.socket = io(WEBSOCKET_URL ? WEBSOCKET_URL : 'http://localhost:3000');
  }

  static instance() {
    return (
      SocketHandler._instance ?? (SocketHandler._instance = new SocketHandler())
    );
  }

  getSocket() {
    return this.socket;
  }
}
