import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import whiteSpaceToDash from 'util/whiteSpaceToDash';

interface ValidatedSelectProps {
  options: any;
  rules: any;
  hasError: boolean;
  valueName: string;
  className?: string;
  isMulti?: boolean;
  maxHeight?: number;
  isDisabled?: boolean;
  name: string;
}
function ValidatedSelect({
  valueName,
  options,
  rules,
  hasError,
  className,
  isMulti,
  maxHeight,
  isDisabled,
  name,
}: ValidatedSelectProps) {
  const { control } = useFormContext();
  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: isDisabled
        ? '#fafafa'
        : hasError
        ? 'rgb(254 242 242)'
        : 'white',
      borderColor: hasError ? 'rgb(254 100 100)' : 'rgb(210 210 210)',
      borderRadius: '0.375rem',
      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    }),
  };

  return (
    <div className={className}>
      <Controller
        control={control}
        rules={rules}
        name={valueName}
        render={({ field: { onChange, value } }) => (
          <Select
            inputId={whiteSpaceToDash(name)}
            name={name}
            key={`select_${value}`}
            closeMenuOnSelect={!isMulti}
            styles={colourStyles}
            value={value}
            defaultValue={value}
            isMulti={isMulti}
            isDisabled={isDisabled}
            maxMenuHeight={maxHeight}
            getOptionLabel={(option: any) =>
              option.name ? option.name : option.label
            }
            isClearable
            getOptionValue={(option: any) => option._id}
            onChange={(selected) => onChange(selected)}
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        )}
      />
    </div>
  );
}
export default ValidatedSelect;
