import { useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import modalStore from 'stores/modal.store';
import nutritionSolutionStore from 'stores/nutritionSolutionStore.store';
import { generateNutritionSolutionOptions } from 'util/DropDownUtil';
import mobxify from 'util/hocs/mobxify';
import whiteSpaceToDash from 'util/whiteSpaceToDash';

interface Props {
  index: number;
  control: any;
  watch: any;
  hasError: boolean;
  isDisabled: boolean;
  recipe?: string;
}

const SelectNutritionSolutionDropDown = ({
  index,
  hasError = false,
  isDisabled,
}: Props) => {
  const { control } = useFormContext();
  useEffect(() => {
    nutritionSolutionStore?.getNutritionSolutionGroups();
  }, []);

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: isDisabled
        ? '#fafafa'
        : hasError
        ? 'rgb(254 242 242)'
        : 'white',
      borderColor: hasError ? 'rgb(254 100 100)' : 'rgb(210 210 210)',
      borderRadius: '0.375rem',
      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    }),
  };

  const formatGroupLabel = (data: any) => (
    <div>
      <span>{data.label}</span>
      <span>{data.options.length}</span>
    </div>
  );

  const groupedOptions = useMemo(() => {
    return generateNutritionSolutionOptions(
      nutritionSolutionStore?.nutritionSolutionGroups,
    );
  }, [nutritionSolutionStore?.nutritionSolutionGroups]);

  const navigate = useNavigate();

  const handleFindAndNavigateToNutritionSolutionGroup = (value: any) => {
    const nutritionSolutionGroup =
      nutritionSolutionStore?.nutritionSolutionGroups.find(
        (ns) =>
          ns._id === value?._id ||
          ns.nutritionSolutions.find((n) => n._id === value?._id),
      );

    if (!nutritionSolutionGroup) return;

    modalStore?.closeModal();
    const farmId: string = value.docPermissions[0].farm;
    navigate(
      `${farmId}/nutrition-solutions/${nutritionSolutionGroup._id || ''}`,
      {
        replace: true,
      },
    );
  };

  const label = 'Nutrition Solution';

  return (
    <div className={' sm:border-gray-200 flex items-center justify-between'}>
      <label
        className="block text-base font-medium text-gray-700 w-1/4"
        htmlFor={whiteSpaceToDash(label)}
      >
        {label}
      </label>
      <div className="w-3/4">
        <Controller
          control={control}
          rules={{ required: true }}
          name={`phases.${index}.usedNutritionSolution`}
          render={({ field: { onChange, value } }) => (
            <>
              {isDisabled && (
                <button
                  onClick={() =>
                    handleFindAndNavigateToNutritionSolutionGroup(value)
                  }
                >
                  Go to {label}
                </button>
              )}
              <Select
                inputId={whiteSpaceToDash(label)}
                key={`select_${value}`}
                isMulti={false}
                getOptionLabel={(option: any) => {
                  return option.label;
                }}
                styles={colourStyles}
                isDisabled={isDisabled}
                value={value}
                getOptionValue={(option: any) => option._id}
                onChange={(selected) => {
                  onChange(selected);
                }}
                isClearable
                defaultValue={null}
                options={groupedOptions}
                formatGroupLabel={formatGroupLabel}
                className="basic-multi-select "
                classNamePrefix="select"
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default mobxify(
  SelectNutritionSolutionDropDown,
  'nutritionSolutionStore',
  'modalStore',
);
