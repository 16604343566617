import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid';
import { NavLink, useParams } from 'react-router-dom';
import userStore from 'stores/user.store';

import {
  EyeDropperIcon,
  InboxStackIcon,
  ListBulletIcon,
  QrCodeIcon,
  QueueListIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import Skeleton from 'react-loading-skeleton';
import { CloudAppParams } from 'routes';
import mobxify from 'util/hocs/mobxify';
import packageJson from '../../../package.json';
import FarmSelection from '../FarmSelection/FarmSelection';

interface NavigationItem {
  name: string;
  href: string;
  Icon?: JSX.Element;
}

const styles = {
  className: 'inline mr-1.5 h-5 w-5 text-gray-500',
};

const navigationItems: NavigationItem[] = [
  {
    name: 'Plant Manager',
    href: '/plantGroups',
    Icon: <QrCodeIcon {...styles} />,
  },
  {
    name: 'Nutrition',
    href: '/nutrition-solutions',
    Icon: <EyeDropperIcon {...styles} />,
  },
  {
    name: 'Warehouse',
    href: `/items/warehouse/${WAREHOUSE_ITEM_TYPE.PLANT}`,
    Icon: <RectangleStackIcon {...styles} />,
  },
  {
    name: 'MFC',
    href: `/items/mfc/${WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD}`,
    Icon: <InboxStackIcon {...styles} />,
  },
  {
    name: 'History',
    href: '/order-history',
    Icon: <ListBulletIcon {...styles} />,
  },
  {
    name: 'Current Orders',
    href: '/orders',
    Icon: <QueueListIcon {...styles} />,
  },
];

interface LinkClassNameArgs {
  isActive: boolean;
}
function linkClassName({ isActive }: LinkClassNameArgs): string {
  if (isActive)
    return 'hover:text-primary p-2 text-sm hover:bg-gray-200 transition-all active:bg-gray-200 rounded-md text-primary bg-gray-200';

  return 'hover:text-primary p-2 text-sm hover:bg-gray-100 transition-all active:bg-gray-200 rounded-md';
}

const NavigationBar = () => {
  const { farmId } = useParams<CloudAppParams>();
  const baseUrl = `/${farmId}`;

  const currentUser = userStore?.currentUser;

  const generateInitials = (name: string) => {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <div className="flex flex-col flex-grow border-r bg-white w-80 h-screen pb-5">
      <div className="p-4 flex flex-col shadow-inner h-screen  pt-5">
        <FarmSelection />

        {navigationItems.map((item) => {
          return (
            <NavLink
              to={`${baseUrl}${item.href}`}
              className={linkClassName}
              key={item.href}
            >
              {item.Icon}
              {item.name}
            </NavLink>
          );
        })}
      </div>
      <div>
        <div className="flex-shrink-0 border-gray-200 p-4 pb-0">
          <div className="flex items-center">
            <div>
              {currentUser?.image && (
                <img
                  className="inline-block h-9 w-9 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              )}
              {!currentUser?.image && (
                <div className="shadow-sm h-9 w-9 rounded-full bg-slate-200 flex items-center justify-center text-white">
                  {generateInitials(currentUser ? currentUser.fullName : '')}
                </div>
              )}
            </div>

            <div className="ml-3 ">
              <p
                className="text-sm font-medium text-gray-700 group-hover:text-gray-900"
                data-testid="user-name"
              >
                {currentUser?.fullName || <Skeleton width={50} height={18} />}
              </p>
              <div
                className="cursor-pointer flex items-center"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = '/';
                }}
              >
                <ArrowRightOnRectangleIcon className="mr-1 w-4 h-4 text-gray-500" />
                <button className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                  Log Out
                </button>
              </div>
            </div>
          </div>
          <p className="pt-3 text-xs text-center text-gray-300 md:mt-0 md:order-1">
            Version{' '}
            {packageJson?.version || <Skeleton width={20} height={20} />}
          </p>
        </div>
      </div>
    </div>
  );
};

export default mobxify(NavigationBar, 'userStore');
