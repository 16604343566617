const generateColorBasedOnString = (stringInput: string, offset?: boolean) => {
  const stringUniqueHash = stringInput.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  if (offset) {
    return `hsl(${stringUniqueHash % 360}, 70%, 20%)`;
  }
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

export { generateColorBasedOnString };
