import { useFormContext } from 'react-hook-form';
import { RecipeGroup } from 'models/RecipeGroup.model';
import { ValidatedSelectLabel } from '../ValidatedSelectLabel';
interface RecipeInputProps {
  options?: RecipeGroup[];
}
const OrderRecipeInput = ({ options }: RecipeInputProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <ValidatedSelectLabel
      valueName="recipeGroup"
      options={options}
      label="Recipe"
      hasError={errors.recipeGroup != null}
      className="flex justify-between mt-2"
      inputClassName="w-3/4"
      maxHeight={150}
      rules={{ required: true }}
    />
  );
};

export default OrderRecipeInput;
