import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { PlantStore } from 'stores/plant.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

export class PlantWebsocket {
  private plantStore: PlantStore;
  private organizationId = '';
  constructor(plantStore: PlantStore, organizationId: string) {
    this.plantStore = plantStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.PLANT}`,
        (...args: WebsocketMessage[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.findAllPlants();
            }

            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              this.findAllPlants();
            }

            if (obj.command === WEBSOCKETCOMMANDS.DELETED) {
              this.findAllPlants();
            }
          }
        },
      );
  }

  private async findAllPlants() {
    const split = window.location.pathname.split('/');
    await this.plantStore?.getPlants(split[split.length - 2]);
  }
}
