import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { Provider } from 'mobx-react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appRoutes from 'routes';
import './App.css';
import { stores } from './stores';

function App() {
  return (
    <Provider {...stores}>
      <SkeletonTheme>
        <RouterProvider
          router={appRoutes}
          fallbackElement={<LoadingScreen />}
        />
        <ToastContainer />
      </SkeletonTheme>
    </Provider>
  );
}

export default App;
