import { FieldArrayWithId, FieldValues } from 'react-hook-form';
import { ValidatedInput } from '../InputValidation/ValidatedInput';
import { ValidatedInputWithUnits } from '../InputValidation/ValidatedInputWithUnits';
interface MixtureInputProps {
  field: FieldArrayWithId<FieldValues, 'mixtures', 'id'>;
  index: number;
}
export const MixtureInput = ({ index, field }: MixtureInputProps) => {
  return (
    <div className="flex" key={field.id}>
      <ValidatedInput
        key={field.id + 'name'}
        className="justify-between mb-3 w-1/4 pr-3"
        placeholder="Holland 1"
        rules={{ required: true }}
        index={index}
        fieldArrayName="mixtures"
        valueName="name"
      />
      <ValidatedInputWithUnits
        className="justify-between flex-grow mb-3"
        inputClassName="flex-grow"
        placeholder="40"
        fieldArrayName="mixtures"
        key={field.id + 'value'}
        unit="ml"
        index={index}
        isNumber
        rules={{
          required: true,
          pattern: /(\d*\.)?\d+/,
        }}
        valueName="value"
      />
    </div>
  );
};
