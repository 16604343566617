import { UserIcon } from '@heroicons/react/20/solid';
import { EventLog } from 'stores/event.store';
import EventCard from './EventCard';
import { Filter } from './Events';
export interface Comment {
  comment: string;
  createdAt: Date;
  createdBy: { user: { fullName: string } };
  isDeleted: boolean;
  order: string;
  updatedAt: Date;
  _id: string;
}
export const iconColour = (event: EventLog) => {
  if (event?.name.includes('ANALYTICS')) {
    return 'bg-blue-500';
  }
  if (event?.name.includes('PLANTS')) {
    return 'bg-green-500';
  }
  if (event?.name.includes('TRAY')) {
    return 'bg-gray-600';
  }
  if (event?.name.includes('CHAT')) {
    return 'bg-yellow-500';
  }
  if (event?.name.includes('ORDER')) {
    return 'bg-gray-400';
  }
  if (event?.name.includes('PERIOD')) {
    return 'bg-orange-800';
  }
};

export const noneChecked = (arr: Filter[]) => {
  return arr.every((element) => element.selected === false);
};

export const showUserIcon = (event: EventLog) => {
  if (event?.userName) {
    return (
      <p className="text-white text-lg font-semibold">
        {event?.userName
          .split(' ')
          .map((n) => n[0])
          .join('')}
      </p>
    );
  } else {
    return <UserIcon className="h-6 w-6 text-white " aria-hidden="true" />;
  }
};

export const toTitle = (string: string) => {
  if (!string) return '';

  return string
    .toLowerCase()
    .split(/[\s_]+|(?=[A-Z])/)
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
export const isValidEvent = (event: EventLog | undefined) => {
  if (!event) return false;
  if (event.data && event.name.includes('ANALYTICS')) {
    return true;
  } else return false;
};

export const isRegularEvent = (event: EventLog) => {
  if (!event) {
    return false;
  }
  if (event.comment) {
    return false;
  }
  if (event.name.includes('ANALYTICS')) {
    return false;
  }
  if (event.data) {
    return true;
  }
};
export const format = (value?: string | number | boolean) => {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return value.toString();
  }

  if (typeof value === 'boolean') {
    return value === false ? 'False' : 'True';
  }

  return '';
};

export const pluralCheck = (input: string, amount: number) => {
  if (amount === 1) {
    return input;
  }
  return input + 's';
};
export const renderEvents = (
  events: EventLog[],
  handleShowEvent: (id: string) => void,
) =>
  events?.map((event, eventIdx) => (
    <EventCard
      key={event._id}
      event={event}
      length={events.length}
      eventIdx={eventIdx}
      handleShowEvent={handleShowEvent}
    />
  ));

export const mergeCommentsIntoEvents = (
  events: EventLog[],
  comments: Comment[],
) => {
  if (!comments || !events) return;
  return events
    .map((event) => {
      if (!event?.name.includes('CHAT') || !comments) return event;

      const comment = comments.find((comment) => {
        return comment._id === event?.data.comment;
      });

      if (!comment) return event;

      const eventWithComment = {
        ...event,
        data: { commentId: event.data.comment as string },
        userName: comment.createdBy?.user?.fullName || 'Farm',
        comment: comment.comment || '',
      };

      return eventWithComment;
    })
    .filter((e) => e)
    .sort((x, y) => {
      if (!x?.timestamp || !y?.timestamp) return 0;
      return new Date(y.timestamp).getTime() - new Date(x.timestamp).getTime();
    });
};
