import { useFormContext } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import ValidatedDatePicker from '../ValidatedDatePicker';

const OrderDatePicker = () => {
  const { watch, control } = useFormContext();
  return (
    <div className=" sm:border-gray-200 flex items-center mt-2 justify-between">
      <label
        htmlFor="startDate"
        className="block text-sm font-medium text-gray-700"
      >
        Start at
      </label>
      <ValidatedDatePicker
        control={control}
        valueName="startDate"
        className="w-3/4"
        rules={{ required: true }}
        watch={watch}
      />
    </div>
  );
};

export default OrderDatePicker;
