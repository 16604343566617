import Container from 'components/container/Container';
import Events from 'components/modal/OrderModal/Events/Events';
import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import NotFound from 'pages/NotFound/NotFound';
import NutritionSolutionGroupDetails from 'pages/NutritionSolutions/components/NutritionSolutionGroupDetails';
import NutritionSolutionsLayout from 'pages/NutritionSolutions/NutritionSolutionsLayout';
import PlantGroupTabSelect from 'pages/plant-details/PlantGroupTabSelect';
import PlantGroupTab from 'pages/plant-details/PlantGroupTab';
import Items from 'pages/warehouse/Items';
import ItemsLayout from 'pages/warehouse/ItemsLayout';
import {
  createBrowserRouter,
  defer,
  LoaderFunction,
  LoaderFunctionArgs,
  Params,
} from 'react-router-dom';
import recipeStore from 'stores/recipe.store';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import farmStore from 'stores/farm.store';
import nutritionSolutionStore from 'stores/nutritionSolutionStore.store';
import plantStore from 'stores/plant.store';
import userStore from 'stores/user.store';
import warehouseStore from 'stores/warehouse.store';
import ErrorBoundary from 'util/ErrorBoundary';
import { plantGroupTabKey, plantGroupTabs } from 'util/tabNames';
import './App.css';
import CurrentOrderGroups from './pages/currentOrderGroups/CurrentOrderGroups';
import OrderHistory from './pages/farm-details/OrderHistory';
import Login from './pages/login/Login';
import PlantGroups from './pages/plantGroups/PlantGroups';
import orderStore from 'stores/order.store';
import analyticStore from 'stores/analytics.store';
import OrderPage from 'pages/OrderPage/OrderPage';
import OrderAnalytics from 'pages/OrderPage/OrderSubPages/OrderChilds/OrderAnalytics';
import { DEFAULT_FILTERS } from 'components/modal/OrderModal/Events/Defaultfilters';
import eventStore from 'stores/event.store';

export interface CloudAppParamsInterface {
  farmId: string;
  plantGroupId: string;
  itemType: WAREHOUSE_ITEM_TYPE;
  nutritionSolutionGroupId: string;
  orderGroupId: string;
  orderId: string;
  archived?: string;
  plantGroupTab?: plantGroupTabs;
}
export type CloudAppParams = Params<keyof CloudAppParamsInterface>;

function deferLoader(loader: LoaderFunction) {
  async function deferredFunction(args: LoaderFunctionArgs) {
    async function loaderWithReturn() {
      await loader(args);
      return true;
    }
    const promise = loaderWithReturn();
    return defer({ promise });
  }
  return deferredFunction;
}

const appRoutes = createBrowserRouter([
  { path: '/login', element: <Login /> },
  {
    path: '/',
    loader: initialLoader,
    element: <Container />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ':farmId',
        loader: farmLoader,
        children: [
          {
            path: 'orders',
            element: <CurrentOrderGroups />,
            loader: deferLoader(currentOrdersLoader),
          },
          {
            path: 'order-history',
            element: <OrderHistory />,
            loader: deferLoader(orderHistoryLoader),
          },
          {
            path: 'order-details/:orderGroupId',
            element: <OrderPage />,
            loader: deferLoader(orderDetailsLoader),
            children: [
              {
                path: 'order/:orderId?',
                loader: deferLoader(orderLoader),
                children: [
                  {
                    path: 'analytics',
                    element: <OrderAnalytics />,
                    loader: deferLoader(orderAnalyticsLoader),
                  },
                  {
                    path: 'events',
                    element: <Events />,
                    loader: deferLoader(eventsLoader),
                  },
                ],
              },
            ],
          },
          {
            path: 'nutrition-solutions',
            element: <NutritionSolutionsLayout />,
            loader: deferLoader(nutritionSolutionGroupsLoader),
            children: [
              {
                path: ':nutritionSolutionGroupId',
                element: <NutritionSolutionGroupDetails />,
                loader: deferLoader(nutritionSolutionLoader),
              },
            ],
          },
          {
            path: 'plantGroups',
            element: <PlantGroups />,
            loader: deferLoader(plantGroupsLoader),
            children: [
              {
                path: `:plantGroupId`,
                element: <PlantGroupTabSelect />,
                children: [
                  {
                    path: `:${plantGroupTabKey}`,
                    element: <PlantGroupTab />,
                    loader: deferLoader(plantGroupDetailsLoader),
                  },
                ],
              },
            ],
          },
          {
            path: 'items',
            element: <ItemsLayout />,
            children: [
              {
                path: 'warehouse/:itemType?',
                element: <Items />,
                loader: deferLoader(warehouseLoader),
              },
              {
                path: 'mfc/:itemType?',
                element: <Items />,
                loader: deferLoader(warehouseLoader),
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '*', element: <NotFound /> },
]);

export default appRoutes;

async function initialLoader() {
  try {
    if (userStore?.currentUser) return defer({ value: true });
    const token = localStorage.getItem('vertics:jwt');
    if (!token) return (window.location.href = '/login');
    await userStore.loadMe();
    await farmStore.loadFarms();
    if (!farmStore.farms) throw new Error('No farms provided');
    if (farmStore.farms.length < 1) throw new Error('No farms provided');

    const farm = farmStore.farms[0];
    if (!farm) throw new Error('No farms provided');
    if (!farm._id) throw new Error('No farms provided');
    const url = window.location.href;
    const urlHasFarm = farmStore.farms.find((f) => url.includes(f._id));
    if (urlHasFarm) return defer({ value: true });
    window.location.href = `/${farm._id}/orders`;

    return defer({ value: true });
  } catch (error) {
    toast.error('Error at loading user and farms' + (error as Error).message);
    throw error;
  }
}

async function farmLoader({ params }: LoaderFunctionArgs) {
  if (!params.farmId) throw new Error('No farmId provided');
  await farmStore.getFarm(params.farmId);

  return defer({ value: true });
}

async function currentOrdersLoader() {
  await orderStore.getRunningOrderGroups();
}

async function orderHistoryLoader() {
  await orderStore.getOrderGroupUuids();
  await plantStore?.getPlantGroups();
  await plantStore?.getAllPlants();
}

async function orderDetailsLoader({ params }: LoaderFunctionArgs) {
  const { orderGroupId } = params as CloudAppParams;

  if (!orderGroupId) throw new Error('No orderGroupId provided');
  await orderStore.getOrderGroup(orderGroupId, true);

  const currentOrder = orderStore.currentOrderGroup?.orders[0];
  if (!currentOrder) throw new Error('No currentOrder provided');

  const recipeId =
    typeof currentOrder.recipe === 'string'
      ? currentOrder.recipe
      : currentOrder.recipe._id;

  const plantId =
    typeof currentOrder.plant === 'string'
      ? currentOrder.plant
      : currentOrder.plant._id;

  await recipeStore.getRecipe(recipeId);
  await recipeStore.getGroupForRecipe(recipeId);
  await plantStore.getPlant(plantId);
}

async function orderLoader({ params }: LoaderFunctionArgs) {
  const { orderId } = params as CloudAppParams;

  if (!orderId) {
    orderStore.setOrder(undefined);
    return;
  }

  const currentOrder = await orderStore.getOrderFromOrderGroup(orderId);
  if (!currentOrder) throw new Error('No order provided');

  const recipeId =
    typeof currentOrder.recipe === 'string'
      ? currentOrder.recipe
      : currentOrder.recipe._id;

  const plantId =
    typeof currentOrder.plant === 'string'
      ? currentOrder.plant
      : currentOrder.plant._id;

  await recipeStore.getRecipe(recipeId);
  await recipeStore.getGroupForRecipe(recipeId);
  await plantStore.getPlant(plantId);
}

async function orderAnalyticsLoader({ params }: LoaderFunctionArgs) {
  const { orderId } = params as CloudAppParams;

  if (!orderId) throw new Error('No orderId provided');

  await analyticStore?.loadAnalyticData(orderId, {
    bloodline: true,
  });
  await analyticStore?.loadAnalyticDataTypes();

  await orderStore.setCurrentOrderOnLoader(orderId);
}

async function eventsLoader({ params }: LoaderFunctionArgs) {
  const { orderId } = params as CloudAppParams;

  if (!orderId) throw new Error('No orderId provided');

  await orderStore.setCurrentOrderOnLoader(orderId);

  await analyticStore?.loadAnalyticDataTypes();

  if (!orderStore?.currentOrder?._id) return;

  await eventStore?.getEvents(
    orderStore?.currentOrder?._id,
    DEFAULT_FILTERS.map((filter) => ({
      selected: true,
      ...filter,
    })).map((value) => value.value),
  );
}

async function nutritionSolutionGroupsLoader() {
  await nutritionSolutionStore?.getNutritionSolutionGroups();
}

async function nutritionSolutionLoader({ params }: LoaderFunctionArgs) {
  const { nutritionSolutionGroupId } = params as CloudAppParams;
  if (!nutritionSolutionGroupId)
    throw new Error('No nutritionSolutionId provided');

  await nutritionSolutionStore?.getNutritionGroupSolution(
    nutritionSolutionGroupId,
  );
}

async function plantGroupsLoader() {
  await plantStore.getPlantGroups();
}

async function plantGroupDetailsLoader({ params }: LoaderFunctionArgs) {
  const { plantGroupId } = params as CloudAppParams;
  const plantGroupTab = params[plantGroupTabKey];
  const archived = plantGroupTab === plantGroupTabs.archive;

  if (!plantGroupId) throw new Error('No plantGroupId provided');

  await plantStore?.getPlantGroup(plantGroupId);
  if (plantGroupTab === plantGroupTabs.plants) {
    await plantStore?.getPlants(plantGroupId);
  }
  await recipeStore?.getRecipeGroups(plantGroupId, archived);
}

async function warehouseLoader({ params }: LoaderFunctionArgs) {
  const { itemType } = params as CloudAppParams;
  if (!itemType) {
    warehouseStore.reset();
    return;
  }
  await warehouseStore.find(itemType as WAREHOUSE_ITEM_TYPE);
}
