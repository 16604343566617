import { action, makeAutoObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import { User } from 'models/User.model';
import Axios from 'util/Axios';

export class UserStore {
  @observable currentUser?: User;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async login(emailInput: string, password: string) {
    try {
      const response = await Axios.post('/auth/sign-in', {
        email: emailInput,
        password,
      });
      if (response.status !== 200) throw new Error('Login failed');
      const { fullName, email, accessToken, refreshToken, roles } =
        response.data;
      this.currentUser = { email, fullName, roles };
      localStorage.setItem('vertics:jwt', accessToken);
      localStorage.setItem('vertics:refreshToken', refreshToken);

      toast.success('Login successful');
      return response.data;
    } catch (error) {
      toast.error('You are unauthorized. Please check your credentials.');
      return false;
    }
  }

  @action
  async loadMe() {
    try {
      const response = await Axios.get('/users/me');
      if (response.status !== 200)
        throw new Error('Error at getting me: ' + response.statusText);
      this.currentUser = response.data;
    } catch (error) {
      toast.error('Error at getting me: ' + (error as Error).message);
      const clear = confirm('Log out and retry?');
      if (!clear) return;
      localStorage.clear();
    }
  }
}

const userStore = new UserStore();
export default userStore;
