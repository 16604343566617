import { EventLog } from 'stores/event.store';
import { AnalyticsData } from './AnalyticsData.model';
import { Plant } from './Plant.model';
import { Recipe } from './Recipe.model';

type ObjectId = string;

interface OrderGroupBase {
  _id: string;
  analytics?: AnalyticsData[];
  events?: EventLog[];
  plant?: string;
  recipe?: Recipe;
  uuid: string;
  type: string;
  target: string;
  farm: string;
  metaData: OrderMetaData;
  description?: string;
  latestImage?: string;
  createdAt: Date;
  status?: string;
  isDeleted?: boolean;
}
export interface OrderGroupModel extends OrderGroupBase {
  orders: OrderModel[];
}
export interface OrderGroupUnpopulated extends OrderGroupBase {
  orders: string[];
}

export interface OrderModel {
  _id: ObjectId;
  uuid: string;
  plant: ObjectId | Plant;
  plantGroup: ObjectId;
  startPhase: number;
  orderGroup: ObjectId;
  level: number;
  amount: number;
  parents: ObjectId[];
  rating: number;
  comment: string[];
  metaData: OrderMetaData;
  createdAt: Date;
  startDate: Date;
  oid: string;
  recipe: ObjectId | Recipe;
  children?: ObjectId[];
  isDeleted?: boolean;
}

export interface OrderMetaData {
  status: OrderMetaDataStatus;
  comments: string[];
  //set to each individual order's startdate for orders and the first orders' startdate for orderGroup
  startDate: Date;
}

export enum OrderMetaDataStatus {
  NOT_STARTED = 'NOT_STARTED',
  PRODUCTION = 'PRODUCTION',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  TO_EXPAND = 'TO_EXPAND',
  EXPANDED = 'EXPANDED',
}
