import ModalName from 'enums/ModalName.enum';
import { useState } from 'react';
import { toast } from 'react-toastify';
import modalStore from 'stores/modal.store';
import nutritionSolutionStore from 'stores/nutritionSolutionStore.store';
import GenericModal from '../GenericModal';
import mobxify from 'util/hocs/mobxify';

const DeleteNutritionSolutionModal = () => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleDeletion = async () => {
    setDisabled(true);

    const nutritionSolution = modalStore?.customData;

    try {
      if (!nutritionSolution || !nutritionSolution._id)
        throw new Error('Nutrition Solution could not be deleted.');

      nutritionSolution.isDeleted = true;
      await nutritionSolutionStore?.updateNutritionSolution(
        nutritionSolution._id,
        nutritionSolution,
        true,
      );

      modalStore?.closeModal();
    } catch (error) {
      toast.error((error as Error)?.message);
      modalStore?.closeModal();
    }
  };

  return (
    <GenericModal modalName={ModalName.DELETE_NUTRITION_SOLUTION}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Delete this Nutrition Solution
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Do you really want to delete this Nutrition Solution?</p>
        </div>
        <div className="mt-5 flex justify-end">
          <button
            onClick={() => {
              modalStore?.closeModal();
            }}
            name="cancel"
            type="button"
            className="mr-4 inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
          >
            Cancel
          </button>
          <button
            disabled={disabled}
            onClick={() => handleDeletion()}
            type="button"
            className={`inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm ${
              disabled && 'cursor-wait'
            }`}
          >
            Delete Nutrition Solution
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default mobxify(
  DeleteNutritionSolutionModal,
  'modalStore',
  'nutritionSolutionStore',
);
