import {
  ClipboardDocumentIcon,
  LockClosedIcon,
  PencilIcon,
  ArchiveBoxIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ModalName from 'enums/ModalName.enum';
import { RecipeModalMode } from 'util/tabNames';
import useQuerySetter from 'util/useQuerySetter';
import recipeStore from 'stores/recipe.store';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';
interface RecipeGroupSidebarTopButtonsProps {
  disabled?: boolean;
}

const RecipeGroupSidebarTopButtons = ({
  disabled,
}: RecipeGroupSidebarTopButtonsProps) => {
  const { query } = useQuerySetter();
  const recipeGroupId = query.get('recipeGroup');
  const formContext = useFormContext();
  const formState = formContext?.formState ? formContext.formState : null;
  const [isArchived, setIsArchived] = useState<boolean>();

  useEffect(() => {
    recipeStore.fetchIsArchived(recipeGroupId || '', setIsArchived);
  }, []);

  const errorLength = useMemo(() => {
    return formState && Object.keys(formState.errors).length;
  }, [formState]);

  const buttonColor =
    errorLength && errorLength > 0 ? 'bg-gray-400' : 'bg-green-600';

  const buttonClasses = useMemo(
    () =>
      `w-full cursor-pointer inline-flex items-center px-4 py-3 border rounded-md  shadow-sm text-sm font-medium text-white mb-3 ${buttonColor}`,
    [buttonColor],
  );

  const renderEditButton = () => {
    return (
      <div className="m-4 mt-3 mb-6 sm:mt-0">
        <button
          className="w-full cursor-pointer inline-flex items-center px-4 py-3 border rounded-md border-b-0 shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 mb-3"
          onClick={() => {
            modalStore?.setRecipeModalMode(RecipeModalMode.VIEW);
          }}
          type="button"
          name="cancel"
        >
          <LockClosedIcon className="w-4 h-4 mr-1" />
          Cancel
        </button>

        <button
          className={`${buttonClasses} ${disabled && 'cursor-wait'}`}
          type="submit"
          disabled={disabled}
        >
          {' '}
          <PencilIcon className="w-4 h-4 text-white  mr-1" />
          Save new version
        </button>
      </div>
    );
  };

  const renderNewRecipeButton = () => {
    return (
      <div className="mt-3 mb-6 sm:mt-0 px-4 pb-4">
        <button
          className={`${buttonClasses} ${disabled && 'cursor-wait'}`}
          type="submit"
          disabled={disabled}
        >
          <PencilIcon className="w-4 h-4 text-white  mr-1" />
          Create new recipe
        </button>
      </div>
    );
  };

  if (modalStore?.recipeModalMode === RecipeModalMode.EDIT) {
    return renderEditButton();
  }

  if (modalStore?.recipeModalMode === RecipeModalMode.DUPLICATE) {
    return renderNewRecipeButton();
  }

  if (modalStore?.recipeModalMode === RecipeModalMode.CREATE) {
    return renderNewRecipeButton();
  }

  return (
    <div className="m-4 mt-3 mb-6 sm:mt-0">
      <button
        className={
          buttonClasses +
          ' bg-gray-50 text-gray-700 hover:bg-green-600 hover:text-white transition-all'
        }
        type="button"
        onClick={(event) => {
          event.preventDefault();
          modalStore?.setRecipeModalMode(RecipeModalMode.DUPLICATE);
        }}
      >
        <ClipboardDocumentIcon className="w-4 h-4 mr-2 " />
        Duplicate
      </button>
      {!isArchived && (
        <button
          type="button"
          className={
            buttonClasses +
            ' bg-gray-50 text-gray-700 hover:bg-green-600 hover:text-white transition-all'
          }
          onClick={(event) => {
            event.preventDefault();
            modalStore?.setRecipeModalMode(RecipeModalMode.EDIT);
          }}
        >
          <PencilIcon className="w-4 h-4  mr-2" />
          Edit
        </button>
      )}
      <button
        type="button"
        className={
          buttonClasses +
          ' bg-gray-50 text-gray-700 hover:bg-green-600 hover:text-white transition-all'
        }
        onClick={(event) => {
          event.preventDefault();
          modalStore?.openModal(ModalName.ARCHIVE_RECIPE, {
            recipeGroupId: recipeGroupId,
            isArchived: isArchived,
          });
        }}
      >
        <ArchiveBoxIcon className="w-4 h-4  mr-2" />
        {isArchived ? 'Unarchive' : 'Archive'}
      </button>
    </div>
  );
};

export default mobxify(
  RecipeGroupSidebarTopButtons,
  'recipeStore',
  'modalStore',
);
