import { getTypeForKey } from './KeyMappers';
import moment from 'moment';
import { AnalyticDataType } from 'models/AnalyticsDataType.model';
import dayjs from 'dayjs';
import {
  AnalyticsData,
  AnalyticsDataValuesInterface,
} from 'models/AnalyticsData.model';
import { Line, SeparatorLine } from 'components/Graph/Graph';
import { absoluteDateDiff } from 'components/Graph/GraphSideNav';

const generateLines = (
  analytics: AnalyticsData[],
  analyticDataTypes: AnalyticDataType[],
) => {
  //lines
  const lines: Line[] = [];
  const keyPointList: any = {};
  // vertical seperator
  const separator: SeparatorLine[] = [];

  let prevStatus = '';

  const addToList = (
    key: string,
    value: any,
    pointList: any = {},
    startDate: Date,
    analyticDate: Date,
  ) => {
    if (!pointList[key]) {
      pointList[key] = { key: key, values: [] };
    }

    const dayOfAnalytics = absoluteDateDiff(startDate, analyticDate) + 1;

    pointList[key].values.push({
      dateWithDay: `Day ${dayOfAnalytics}`,
      date: dayjs(analyticDate).format('HH:mm DD.MM.YY'),
      value: value,
      key: key,
    });
  };

  if (analytics) {
    const startDate = new Date(analytics[analytics.length - 1]?.data?.date);
    for (const a of analytics) {
      const singlePoint = a.data;

      for (const point in singlePoint) {
        const key = point as keyof AnalyticsDataValuesInterface;

        const type = getTypeForKey(analyticDataTypes, key);
        if (key === 'status' && singlePoint[key] !== prevStatus) {
          prevStatus = singlePoint[key];
          separator.push({
            date: moment(a.data.date).format('HH:mm DD.MM.YY'),
            key: singlePoint[key],
          });
        } else {
          if (type.showInDiagram && singlePoint[key]) {
            addToList(
              key,
              singlePoint[key],
              keyPointList,
              startDate,
              singlePoint.date,
            );
          }
        }
      }
    }
  }

  for (const key in keyPointList) {
    keyPointList[key].values.reverse();
    lines.push(keyPointList[key]);
  }

  return { separator: separator, lines: lines };
};

export { generateLines };
