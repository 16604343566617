import { action, makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import Axios from 'util/Axios';

export interface NutritionSolutionDto {
  _id?: string;
  ec: number;
  ph: number;
  comment: string;
  phMinus: number;
  amount?: {
    name: string;
    value: number;
  }[];
  waterLevel: number;
  isDeleted?: boolean;
}

export interface NutritionSolutionGroupDto {
  _id: string;
  name: string;
  updatedAt?: Date;
  nutritionSolutions: NutritionSolutionDto[];
}

export interface CreateNutritionSolutionGroupDto {
  name: string;
}

export interface CreateNutritionSolutionDto {
  id?: string;
  ec: number;
  ph: number;
  comment: string;
  amount: {
    name: string;
    value: number;
  }[];
  phMinus: number;
  waterLevel: number;
}

export class NutritionSolutionStore {
  @observable currentNutritionSolutionGroup?: NutritionSolutionGroupDto;
  @observable nutritionSolutionGroups: NutritionSolutionGroupDto[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getNutritionSolutionGroups() {
    const response = await Axios.get(
      '/nutrition-solution-groups?populate=nutritionSolutions',
    );

    if (response.status === 200) {
      this.nutritionSolutionGroups = [...response.data];
      return true;
    }
  }

  @action
  async getNutritionGroupSolution(nutritionSolutionGroupId: string) {
    const response = await Axios.get(
      `/nutrition-solution-groups/${nutritionSolutionGroupId}?populate=nutritionSolutions`,
    );

    this.currentNutritionSolutionGroup = response.data;
    return true;
  }

  @action
  async createNutritionSolutionGroup(
    nutritionSolutionGroupName: CreateNutritionSolutionGroupDto,
  ) {
    const response = await Axios.post(
      '/nutrition-solution-groups',
      nutritionSolutionGroupName,
    );
    if (response.status === 201) {
      this.nutritionSolutionGroups.push(response.data);
    }
  }

  @action
  async createNutritionSolution(
    nutritionSolutionGroupId: string,
    nutritionSolution: CreateNutritionSolutionDto,
  ) {
    const response = await Axios.post('/nutrition-solutions', {
      nutritionSolutionGroupId,
      ...nutritionSolution,
    });

    if (response.status === 201) {
      toast.success(`Nutrition Solution created successfully.`);
      this.getNutritionSolutionGroups();
    } else {
      toast.error('There was an error. Please try again later.');
    }
  }

  @action
  async updateNutritionSolution(
    nutritionSolutionGroupId: string,
    nutritionSolution: NutritionSolutionDto,
    onDelete: boolean,
  ) {
    const response = await Axios.put(
      `/nutrition-solutions/${nutritionSolutionGroupId}`,
      nutritionSolution,
    );
    if (response.status === 200) {
      toast.success(
        `Nutrition Solution ${onDelete ? 'deleted' : 'updated'} successfully.`,
      );
      this.getNutritionSolutionGroups();
    } else {
      toast.error('There was an error. Please try again later.');
    }
  }
}

const nutritionSolutionStore = new NutritionSolutionStore();
export default nutritionSolutionStore;
