import { ValidatedInputLabeled } from '../ValidatedInputLabel';
const OrderTargetInput = () => {
  return (
    <ValidatedInputLabeled
      valueName="target"
      label="Target"
      rules={{ required: true }}
      className="flex justify-between mt-5"
      inputClassName="w-3/4"
    />
  );
};

export default OrderTargetInput;
