import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import plantStore from 'stores/plant.store';
import typeStore from 'stores/type.store';
import warehouseStore from 'stores/warehouse.store';
import { ValidatedCreatableLabel } from '../InputValidation/ValidatedCreatableLabel';
import { ValidatedSelectLabel } from '../InputValidation/ValidatedSelectLabel';
import { WarehouseItemOption } from 'models/WarehouseItem.model';
import { CloudAppParams } from 'routes';
import {
  checkIfItemAlreadyExists,
  dependendLoader,
} from './WarehouseItemHelper';
import { toast } from 'react-toastify';

interface WareHouseModalOptionProps {
  isDisabled: boolean;
}

const WarehouseModalOption = ({ isDisabled }: WareHouseModalOptionProps) => {
  const [options, setOptions] = useState<WarehouseItemOption[]>([]);
  const { itemType } = useParams<CloudAppParams>();
  const [isLoading, setIsLoading] = useState(false);

  const { setValue } = useFormContext();

  useEffect(() => {
    dependendLoader(itemType as WAREHOUSE_ITEM_TYPE);
  }, [itemType]);

  useEffect(() => {
    let opt: WarehouseItemOption[] = [];

    if (itemType === WAREHOUSE_ITEM_TYPE.PLANT && plantStore?.allPlants) {
      const result: WarehouseItemOption[] = plantStore?.allPlants.map(
        (plant) => ({
          value: plant.name,
          label: plant.name,
          type: WAREHOUSE_ITEM_TYPE.PLANT,
          _id: plant._id,
        }),
      );

      opt = result;
    } else if (itemType === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
      opt = typeStore.soilTypes;
    } else if (itemType === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
      opt = typeStore.oxygenMethods;
    } else if (itemType === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
      opt = typeStore.lightTypes;
    }

    if (!opt || !Array.isArray(opt)) return;

    const optionsNotInWarehouse = opt.filter(
      (o) => !warehouseStore.warehouseItems.some((i) => i.name === o.label),
    );

    setOptions(optionsNotInWarehouse);
  }, [
    plantStore.allPlants,
    typeStore.lightTypes,
    typeStore.oxygenMethods,
    typeStore.soilTypes,
  ]);

  const handleCreate = async (value: string) => {
    setIsLoading(true);

    try {
      await dependendLoader(itemType as WAREHOUSE_ITEM_TYPE);
      const alreadyExists = checkIfItemAlreadyExists(
        value,
        itemType as WAREHOUSE_ITEM_TYPE,
      );
      if (alreadyExists) throw new Error('WarehouseItem already exists.');

      let createdObj;
      if (itemType === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
        createdObj = await typeStore.createSoilType({
          name: value,
          label: value,
        });
      } else if (itemType === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
        createdObj = await typeStore.createOxygenMethod({
          name: value,
          label: value,
        });
      } else if (itemType === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
        createdObj = await typeStore.createLightType({
          name: value,
          label: value,
        });
      }

      await dependendLoader(itemType as WAREHOUSE_ITEM_TYPE);

      setValue('selectedItem', createdObj);
    } catch {
      toast.error('WarehouseItem could not be created');
    } finally {
      setIsLoading(false);
    }
  };

  if (itemType === WAREHOUSE_ITEM_TYPE.PLANT) {
    return (
      <ValidatedSelectLabel
        isDisabled={isDisabled}
        label="Item"
        valueName="selectedItem"
        className="flex justify-between mt-4"
        inputClassName="w-3/4"
        hasError={false}
        rules={{ required: true }}
        options={options}
      />
    );
  }

  return (
    <ValidatedCreatableLabel
      isDisabled={isDisabled}
      label="Item"
      valueName="selectedItem"
      className="flex justify-between mt-4"
      inputClassName="w-3/4"
      hasError={false}
      rules={{ required: true }}
      loading={isLoading}
      onCreate={handleCreate}
      options={options}
    />
  );
};

export default observer(WarehouseModalOption);
