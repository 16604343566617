import { FormProvider, useForm } from 'react-hook-form';
import ModalName from 'enums/ModalName.enum';
import GenericModal from '../GenericModal';
import { ValidatedInputLabeled } from '../InputValidation/ValidatedInputLabel';
import { useState } from 'react';
import modalStore from 'stores/modal.store';
import nutritionSolutionStore from 'stores/nutritionSolutionStore.store';
import mobxify from 'util/hocs/mobxify';

const NutritionSolutionGroupModal = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const methods = useForm();

  const onSubmit = async (nutritionSolutionGroupName: any) => {
    setDisabled(true);
    await nutritionSolutionStore.createNutritionSolutionGroup(
      nutritionSolutionGroupName,
    );
    closeModal();
  };

  const closeModal = () => {
    setDisabled(false);
    methods.reset();
    modalStore?.closeModal();
  };

  return (
    <GenericModal
      modalName={ModalName.NUTRITION_SOLUTION_GROUP}
      customContainerStyle="overflow-hidden p-5"
      formMethods={methods}
    >
      <FormProvider {...methods}>
        <form
          id="create_plant_type"
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <div>
            <h3 className="text-lg font-medium text-gray-900">
              Create Nutrition Solution Group
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Use this form to create a nutrition solution.
            </p>
          </div>
          <div className="sm:border-t sm:border-gray-200 sm:pt-3 mt-3">
            <ValidatedInputLabeled
              className="justify-between"
              label="Name"
              rules={{ required: true }}
              valueName="name"
            />
          </div>

          <div className="pt-3">
            <div className="flex justify-end">
              <button
                onClick={() => closeModal()}
                name="cancel"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Cancel
              </button>
              <button
                disabled={disabled}
                type="submit"
                className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                  disabled && 'cursor-wait'
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
};

export default mobxify(
  NutritionSolutionGroupModal,
  'modalStore',
  'nutritionSolutionStore',
);
