export const DEFAULT_FILTERS = [
  {
    label: 'Analytics',
    value: 'ANALYTICS',
    color: 'hover:bg-blue-600 bg-blue-500 text-white border border-blue-500',
    offColor:
      'hover:bg-blue-600 hover:text-white bg-white text-blue-400 border border-blue-500',
  },
  {
    label: 'Plants',
    value: 'PLANTS',
    color: 'hover:bg-green-600 bg-green-500 text-white border border-green-500',
    offColor:
      'hover:bg-green-600 hover:text-white bg-white text-green-500 border border-green-500',
  },
  {
    label: 'Chat',
    value: 'CHAT',
    color:
      'hover:bg-yellow-600 bg-yellow-500 text-white border border-yellow-500',
    offColor:
      'hover:bg-yellow-600 hover:text-white bg-white text-yellow-500 border border-yellow-500',
  },
  {
    label: 'Period',
    value: 'PERIOD',
    color:
      'hover:bg-orange-900 bg-orange-800 text-white border border-orange-800',
    offColor:
      'hover:bg-orange-900 hover:text-white bg-white text-orange-800 border border-orange-800',
  },
  {
    label: 'Tray',
    value: 'TRAY',
    color: 'hover:bg-gray-600 bg-gray-500 text-white border border-gray-600',
    offColor:
      'hover:bg-gray-600 hover:text-white bg-white text-gray-500 border border-gray-600',
  },
  {
    label: 'Order',
    value: 'ORDER',
    color: 'hover:bg-gray-300 bg-gray-200 text-gray-600 border border-gray-200',
    offColor:
      'hover:bg-gray-300 hover:text-white bg-white text-gray-500 border border-gray-200',
  },
];
