import React from 'react';

interface FeedProps {
  children: React.ReactNode;
}
const Feed = ({ children }: FeedProps) => {
  return (
    <div className="w-full mt-4 px-10 pb-8">
      <ul role="list" className="-mb-8 ">
        {children}
      </ul>
    </div>
  );
};

export default Feed;
