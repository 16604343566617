import Card from 'components/ListCard/ListCard';
import ModalName from 'enums/ModalName.enum';
import modalStore from 'stores/modal.store';
import { NutritionSolutionDto } from 'stores/nutritionSolutionStore.store';
import mobxify from 'util/hocs/mobxify';

interface Props {
  nutritionSolution: NutritionSolutionDto;
}

const NutritionSolutionCard = ({ nutritionSolution }: Props) => (
  <Card>
    <div
      className="flex-1 flex flex-col cursor-pointer justify-center items-center"
      onClick={() => {
        modalStore?.openModal(ModalName.NUTRITION_SOLUTION, nutritionSolution);
      }}
    >
      <h3 className="font-bold line-clamp-3 max-w-[75%]">{`PH: ${nutritionSolution.ph} EC: ${nutritionSolution.ec} Waterlevel: ${nutritionSolution.waterLevel}`}</h3>
    </div>
  </Card>
);

export default mobxify(NutritionSolutionCard, 'modalStore');
