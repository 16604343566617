import { NutritionSolutionGroupDto } from 'stores/nutritionSolutionStore.store';

//TODO refactor this to be more readable and without ternary operators
const generateNutritionSolutionOptions = (
  nutritionSolutionGroups?: NutritionSolutionGroupDto[],
) => {
  const notAvailable =
    !nutritionSolutionGroups || !Array.isArray(nutritionSolutionGroups);

  if (notAvailable) return [];

  const someNutritionSolutionGroupsAreJustIdsOrUndefined =
    nutritionSolutionGroups.some(
      (nutritionSolutionGroup) =>
        !nutritionSolutionGroup.nutritionSolutions ||
        typeof nutritionSolutionGroup.nutritionSolutions !== 'object',
    );

  if (someNutritionSolutionGroupsAreJustIdsOrUndefined) return [];

  return nutritionSolutionGroups.map((nutritionSolutionGroup) => ({
    label: nutritionSolutionGroup.name + ' ',
    options: nutritionSolutionGroup.nutritionSolutions
      .filter((nutrition) => !nutrition.isDeleted)
      .map((nutritionSolution) => ({
        label: `${nutritionSolution.ec} EC - ${
          nutritionSolution.ph
        } pH - Waterlevel ${
          nutritionSolution.waterLevel
        } - ${nutritionSolution.amount?.map(
          (amount) => amount.name + ': ' + amount.value ?? 0,
        )}`,
        ...nutritionSolution,
      })),
  }));
};

export { generateNutritionSolutionOptions };
