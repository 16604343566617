import { XMarkIcon } from '@heroicons/react/24/outline';

interface EventFilterProps {
  handleFilterChange: (filter: Filter) => void;
  selectedFilter: Filter[];
  selectAllFilters: () => void;
}

interface Filter {
  selected: boolean;
  label: string;
  value: string;
  color?: string;
  offColor?: string;
}

const EventFilter = ({
  selectedFilter,
  handleFilterChange,
  selectAllFilters,
}: EventFilterProps) => {
  return (
    <div className="mb-4">
      {selectedFilter.map((filter) => {
        const styling = filter.selected
          ? 'inline-flex items-center ml-1 px-2.5 py-0.5 rounded-md text-sm font-medium cursor-pointer shadow ' +
            filter.color
          : 'inline-flex items-center ml-1 px-2.5 py-0.5 rounded-md text-sm font-medium cursor-pointer hover:bg-gray-200 bg-gray-100 text-gray-400 shadow';
        return (
          <span
            key={filter.label}
            className={styling}
            onClick={() => {
              handleFilterChange(filter);
            }}
          >
            {filter.label}
          </span>
        );
      })}
      <button
        type="button"
        className="bg-white rounded-md"
        onClick={() => {
          selectAllFilters();
        }}
      >
        <XMarkIcon className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium cursor-pointer text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-10" />
      </button>
    </div>
  );
};

export default EventFilter;
