import { PlusIcon } from '@heroicons/react/24/outline';
import OrderGroupCard from 'components/CurrentOrderGroups/OrderGroupCard';
import Grid from 'components/Grid/Grid';
import ModalName from 'enums/ModalName.enum';
import { OrderGroupModel } from 'models/Order.model';
import { useMemo, useState } from 'react';
import modalStore from 'stores/modal.store';
import orderStore from 'stores/order.store';
import withLoader from 'util/hocs/withLoader';
import SearchBar from '../plant-details/PlantRecipe/PlantRecipeSearch';
import CurrentOrderGroupFilter from './CurrentOrderGroupFilter';
import {
  OrderGroupSortingOption,
  sortOrderGroups,
} from './CurrentOrderGroupUtils';
import mobxify from 'util/hocs/mobxify';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const CurrentOrderGroups = () => {
  const [PlantFilter, setPlantFilter] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<OrderGroupSortingOption>({
    label: 'Newest First',
    value: 'first',
  });
  // TODO delete
  const dateFilter: Date[] = [];

  const orderGroups = orderStore.orderGroups;
  const FilteredOrderGroups = useMemo(
    () =>
      orderGroups
        .filter((orderGroup: OrderGroupModel) => {
          if (PlantFilter.length === 0) return true;
          if (!orderGroup.plant) return true;

          return PlantFilter.includes(orderGroup.plant);
        })
        .filter((orderGroup: OrderGroupModel) => {
          if (search === '') return true;
          return JSON.stringify(orderGroup).includes(search.toLowerCase());
        })
        .filter((orderGroup: OrderGroupModel) => {
          if (dateFilter.length === 0) return true;

          return (
            dateFilter &&
            dayjs(orderGroup.createdAt).isBetween(
              dateFilter[0],
              dateFilter[1],
              'day',
              '[]',
            )
          );
        })
        .sort((a, b) => {
          return sortOrderGroups(a, b, sort);
        }) || [],
    [orderGroups, sort, PlantFilter, search, dateFilter],
  );

  const plantSet = useMemo(
    () => new Set(orderGroups.map((og) => og.plant).filter((og) => og) || []),
    [orderGroups],
  );

  const plants = Array.from(plantSet).filter((exists) => exists) as string[];

  const onChangeSearchField = (query: string) => {
    setSearch(query);
  };

  const handleCreateOrder = () => modalStore?.openModal(ModalName.CREATE_ORDER);

  return (
    <div className="flex flex-col w-full h-screen">
      <div className="p-5 pb-0 w-full bg-gray-50 pr-10">
        <SearchBar
          setSort={setSort}
          searchValue={search}
          onChange={(query) => onChangeSearchField(query)}
          options={[
            { label: 'Oldest First', value: 'last' },
            { label: 'Newest First', value: 'first' },
            { label: 'Days Left', value: 'daysLeft' },
          ]}
        />
      </div>
      <div className="pl-5 py-2 flex flex-row flex-wrap bg-gray-50 ">
        {plants.map((plant: string) => {
          return (
            <CurrentOrderGroupFilter
              key={plant}
              plant={plant}
              plantFilter={PlantFilter}
              setPlantFilter={setPlantFilter}
            />
          );
        })}
      </div>

      <div className="max-h-screen max-w-full bg-gray-50 h-full overflow-scroll pt-1">
        <Grid>
          <button
            onClick={handleCreateOrder}
            className="bg-white shadow-md rounded-lg mx-3 my-3 sm:h-60 2xl:h-80 hover:shadow-xl justify-between col-span-1 p-6 cursor-pointer"
          >
            <div className="flex justify-center p-2 items-center border-2 border-dotted h-full w-full flex-col rounded-lg  hover:border-solid text-gray-400 hover:text-gray-600">
              <PlusIcon className="w-7 h-7" />
              <p className="w-16 text-center">Add a new Order</p>
            </div>
          </button>
          {FilteredOrderGroups.map((og) => (
            <OrderGroupCard key={`card_${og._id}`} orderGroup={og} />
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default withLoader(
  mobxify(CurrentOrderGroups, 'orderStore', 'modalStore'),
);
