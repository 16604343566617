import { ValidatedInputWithUnits } from './ValidatedInputWithUnits';

interface ValidatedInputProps {
  valueName: string;
  label: string;
  unit: string;
  rules: any;
  isDisabled?: boolean;
  className?: string;
  inputClassName?: string;
  index?: number;
  fieldArrayName?: string;
  isNumber?: boolean;
  placeholder?: string;
}
export const ValidatedInputLabelWithUnits = ({
  valueName,
  isDisabled = false,
  label,
  unit,
  rules,
  className,
  fieldArrayName,
  inputClassName,
  index,
  isNumber,
  placeholder,
}: ValidatedInputProps) => {
  return (
    <>
      <div className={' sm:border-gray-200 flex ' + className}>
        <label
          htmlFor={valueName}
          className="text-base font-medium text-gray-700 w-1/4 flex items-center"
        >
          {label}
        </label>
        <ValidatedInputWithUnits
          unit={unit}
          index={index}
          valueName={valueName}
          fieldArrayName={fieldArrayName}
          rules={rules}
          isDisabled={isDisabled}
          className={inputClassName}
          isNumber={isNumber}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};
