import AnalyticStore from './analytics.store';
import CompareRecipeStore from './compareRecipe.store';
import FarmStore from './farm.store';
import ModalStore from './modal.store';
import OrderStore from './order.store';
import PlantStore from './plant.store';
import RecipeStore from './recipe.store';
import TypeStore from './type.store';
import UserStore from './user.store';
import WarehouseStore from './warehouse.store';
import EventStore from './event.store';
import NutritionSolutionStore from './nutritionSolutionStore.store';

export const stores = {
  analyticStore: AnalyticStore,
  modalStore: ModalStore,
  orderStore: OrderStore,
  farmStore: FarmStore,
  plantStore: PlantStore,
  recipeStore: RecipeStore,
  userStore: UserStore,
  compareRecipeStore: CompareRecipeStore,
  typeStore: TypeStore,
  warehouseStore: WarehouseStore,
  eventStore: EventStore,
  nutritionSolutionStore: NutritionSolutionStore,
};
