import classNames from 'classnames';
import { Controller } from 'react-hook-form';

interface ValidatedCheckboxProps {
  valueName: string;
  setValue: any;
  control: any;
  index?: any;
  fieldArrayName?: string;
  isDisabled?: boolean;
}
export const ValidatedCheckbox = ({
  valueName,
  control,
  index,
  isDisabled,
  fieldArrayName,
}: ValidatedCheckboxProps) => {
  return (
    <Controller
      control={control}
      name={
        fieldArrayName ? `${fieldArrayName}.${index}.${valueName}` : valueName
      }
      render={({ field: { onChange, value } }) => {
        const buttonStyle = classNames({
          'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500':
            !value,
          'bg-green-600  relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500':
            value,
          'cursor-auto focus:ring-whit^': isDisabled,
        });
        const spanPosition = classNames({
          'translate-x-0': !value,
          'translate-x-5': value,
        });

        const animateIn = classNames({
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity':
            true,
          'opacity-100 ease-in duration-200': !value,
          'opacity-0 ease-out duration-100': value,
        });
        const animateOut = classNames({
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity':
            true,
          'opacity-100 ease-in duration-200': value,
          'opacity-0 ease-out duration-100': !value,
        });

        return (
          <button
            type="button"
            className={buttonStyle}
            role="switch"
            aria-checked="false"
            onClick={() => {
              if (!isDisabled) {
                onChange(!value);
              }
            }}
          >
            <span className="sr-only">Use setting</span>
            <span
              className={
                'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ' +
                spanPosition
              }
            >
              {!value && (
                <span className={animateIn} aria-hidden="true">
                  <svg
                    className="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
              {value && (
                <span className={animateOut} aria-hidden="true">
                  <svg
                    className="h-3 w-3 text-green-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              )}
            </span>
          </button>
        );
      }}
    />
  );
};
/**
 *   <input
          disabled={isDisabled || false}
          checked={value}
          onChange={(checked) => {
            if (valueName.includes("analyticIntervalDisabled")) {
              //@ts-ignore
              setValue(`${fieldArrayName}.${index}.analyticInterval`, null);
            }
            onChange(checked.target.checked);
          }}
          type="checkbox"
          className=" accent-green-600 h-4 w-4 border-4 border-gray-300 rounded"
        />
 */
