import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';
import { orderModalTabNames, orderModalTabs } from 'util/tabNames';
import useQuerySetter from 'util/useQuerySetter2';

interface OrderNavbarProps {
  active: string;
  onChange: (newTab: orderModalTabs) => void;
  eagerLoad: Record<orderModalTabs, () => Promise<unknown>>;
}

const OrderNavbar = ({ onChange, active, eagerLoad }: OrderNavbarProps) => {
  const { deleteQueries } = useQuerySetter();

  return (
    <div className="pt-3 flex flex-row w-full">
      <div className="hidden sm:block flex-grow">
        <div className="border-b border-gray-200  pl-6">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {orderModalTabNames.map((tab) => (
              <div
                onMouseEnter={eagerLoad[tab.value]}
                onClick={() => {
                  onChange(tab.value);
                }}
                key={tab.name}
                className={classNames(
                  active === tab.value
                    ? 'border-green-500 text-green-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                )}
                aria-current={active === tab.name ? 'page' : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="border-b border-gray-200 pr-6 mt-3">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => {
            modalStore?.closeModal();
            deleteQueries(['order', 'orderGroup', 'orderGroupTab']);
          }}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default mobxify(OrderNavbar, 'orderStore');
