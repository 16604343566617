import { action, makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import { LightTypeModel } from 'models/LightType.model';
import { OxygenType } from 'models/OxygenType.model';
import { Plant } from 'models/Plant.model';
import { SoilTypeModel } from 'models/SoilType.model';
import { WarehouseItem } from 'models/WarehouseItem.model';
import Axios from 'util/Axios';

export class WarehouseStore {
  @observable warehouseItems: WarehouseItem[] = [];
  @observable warehousePlant?: WarehouseItem;
  @observable warehouseSoilType?: WarehouseItem;
  @observable warehouseLightType?: WarehouseItem;
  @observable warehouseOxygenType?: WarehouseItem;
  @observable search = '';
  @observable options:
    | Plant[]
    | LightTypeModel[]
    | SoilTypeModel[]
    | OxygenType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setSearch(newSearch: string) {
    this.search = newSearch;
  }

  @action
  async find(type?: WAREHOUSE_ITEM_TYPE) {
    const response = await Axios.get(`/warehouse-items`, {
      params: {
        type,
      },
    });
    if (response.status === 200 && Array.isArray(response.data)) {
      this.warehouseItems = response.data;
      return true;
    }
  }

  @action
  reset() {
    this.warehouseItems = [];
    this.warehousePlant = undefined;
    this.warehouseSoilType = undefined;
    this.warehouseLightType = undefined;
    this.warehouseOxygenType = undefined;
    this.options = [];
  }

  @action
  async checkWarehouseItem(type: string, item?: any) {
    if (!item) {
      if (type === WAREHOUSE_ITEM_TYPE.PLANT) {
        this.warehousePlant = undefined;
        return;
      }
      if (type === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
        this.warehouseSoilType = undefined;
        return;
      }
      if (type === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
        this.warehouseLightType = undefined;
        return;
      }
      if (type === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
        this.warehouseOxygenType = undefined;
        return;
      }
    }

    const response = await Axios.get(`/warehouse-items/?${type}=${item}`);

    if (response.status === 200) {
      if (response.data.length === 0) {
        if (type === WAREHOUSE_ITEM_TYPE.PLANT) {
          toast.error('Warehouse is missing seeds for this plant.');
          this.warehousePlant = undefined;
        }
        if (type === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
          toast.error('Warehouse is missing soil type for Recipe.');
          this.warehouseSoilType = undefined;
        }
        if (type === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
          toast.error('Warehouse is missing light types.');
          this.warehouseLightType = undefined;
        }
        if (type === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
          toast.error('Warehouse is missing oxygenation methods.');
          this.warehouseOxygenType = undefined;
        }
        return;
      }

      if (response.data[0].amount <= response.data[0].alertOn) {
        toast.warn(
          `${response.data[0].name} stock is low, ${response.data[0].amount} remaining.`,
        );
      } else {
        toast.success(
          `${response.data[0].name}: ${response.data[0].amount} remaining.`,
        );
      }

      if (type === WAREHOUSE_ITEM_TYPE.PLANT) {
        this.warehousePlant = response.data[0];
      }
      if (type === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
        this.warehouseSoilType = response.data[0];
      }
      if (type === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
        this.warehouseLightType = response.data[0];
      }
      if (type === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
        this.warehouseOxygenType = response.data[0];
      }
    } else {
      toast.error('Error checking warehouse for item.');
    }
  }

  @action
  async create(warehouseItem: any) {
    try {
      await Axios.post('/warehouse-items', warehouseItem);
      toast.success('Item created.');
    } catch (error) {
      toast.error('Item not created.');
    }
  }

  @action
  async update(_id: string, data: any) {
    try {
      await Axios.put(`/warehouse-items/${_id}`, data);
      toast.success('Item updated.');
    } catch (error) {
      toast.error('Item not updated.');
    }
  }

  @action
  async delete(_id: string, data: WarehouseItem) {
    try {
      await Axios.put(`/warehouse-items/${_id}`, data);
      toast.success('Item deleted.');
    } catch (error) {
      toast.error('Item not deleted.');
    }
  }
}

const warehouseStore = new WarehouseStore();
export default warehouseStore;
