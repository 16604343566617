import Card from 'components/ListCard/ListCard';
import { TrashIcon } from '@heroicons/react/20/solid';
import { WarehouseItem } from 'models/WarehouseItem.model';
import ModalName from 'enums/ModalName.enum';
import warehouseStore from 'stores/warehouse.store';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';
import { toast } from 'react-toastify';

interface WarehouseItemProps {
  item: WarehouseItem;
}
const WarehouseItemCard = ({ item }: WarehouseItemProps) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const deleteWarehouseItem = () => {
    item.isDeleted = true;
    warehouseStore.delete(item._id, item);
  };
  return (
    <Card
      dropDownOptions={[
        {
          label: 'Delete',
          icon: TrashIcon,
          handleClick: () =>
            toast.error('Delete not possible at the current time'), // deleteWarehouseItem(),
        },
      ]}
    >
      <div
        className="flex-1 flex flex-col p-8 pt-20 cursor-pointer"
        onClick={() => {
          modalStore?.openModal(ModalName.WAREHOUSE, item);
        }}
      >
        <h3 className="font-bold line-clamp-3">{item.name}</h3>

        <div className="relative flex justify-center align-center text-xs text-gray-500">
          <p className="text-sm text-gray-500 truncate">{item.amount} x</p>
        </div>
      </div>
    </Card>
  );
};

export default mobxify(WarehouseItemCard, 'modalStore');
