import { PlusIcon } from '@heroicons/react/24/outline';
import Grid from 'components/Grid/Grid';
import { useMemo, useState } from 'react';
import SearchBar from './PlantRecipe/PlantRecipeSearch';
import PlantListCard from 'components/ListCard/PlantListCard';
import ModalName from 'enums/ModalName.enum';
import { Plant } from 'models/Plant.model';
import { useParams } from 'react-router';
import { CloudAppParams } from 'routes';
import modalStore from 'stores/modal.store';
import plantStore from 'stores/plant.store';
import withLoader from 'util/hocs/withLoader';
import mobxify from 'util/hocs/mobxify';

const Plants = () => {
  const [search, setSearch] = useState('');
  const { plantGroupId } = useParams<CloudAppParams>();

  const searchPlants = useMemo(() => {
    if (!plantStore?.plants) return;
    return plantStore?.plants.filter((recipe: any) =>
      JSON.stringify(recipe).toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, plantStore?.plants]);

  return (
    <div className="overflow-auto h-screen">
      <div className="p-5 pb-0 grid grid-cols-1 gap-3">
        <SearchBar hideSort searchValue={search} onChange={setSearch} />
      </div>
      <Grid>
        <li
          className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow box p-3 cursor-pointer hover:shadow-xl"
          onClick={() => {
            modalStore?.openModal(ModalName.PLANT_FORM, { mode: 'create' });
          }}
        >
          <button className="flex p-8 justify-center items-center border-2 border-dotted h-full w-full flex-col rounded-lg hover:border-solid text-gray-400 hover:text-gray-600">
            <PlusIcon className="w-7 h-7" />
            <div className="w-16 text-center">Add a new type of plant</div>
          </button>
        </li>
        {plantGroupId &&
          searchPlants?.map((plant: Plant) => (
            <PlantListCard
              key={plant._id}
              plant={plant}
              plantGroupId={plantGroupId}
            />
          ))}
      </Grid>

      <div className="h-28"></div>
    </div>
  );
};

export default withLoader(mobxify(Plants, 'modalStore', 'plantStore'));
