import SideLayout from 'components/SideLayout/SideLayout';
import SearchBar from 'pages/plant-details/PlantRecipe/PlantRecipeSearch';
import { FC } from 'react';
import { Outlet } from 'react-router';
import warehouseStore from 'stores/warehouse.store';
import ItemSidebar from './ItemSidebar';
import mobxify from 'util/hocs/mobxify';

const ItemsLayout: FC = () => {
  return (
    <div className="max-w-full">
      <SideLayout sideBar={<ItemSidebar />}>
        <div className="w-full h-full overflow-scroll">
          <div className="mt-1">
            <div className="overflow-auto h-screen bg-gray-50">
              <div className="p-5 pb-0 w-full">
                <SearchBar
                  hideSort
                  onChange={(str) => warehouseStore.setSearch(str)}
                  searchValue={warehouseStore.search}
                />
              </div>
              <Outlet />
            </div>
          </div>
          <div className="h-8"></div>
        </div>
      </SideLayout>
    </div>
  );
};

export default mobxify(ItemsLayout, 'warehouseStore');
