import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CloudAppParams } from 'routes';
import { OrderModel } from '../../models/Order.model';
import orderStore from '../../stores/order.store';
import logger from '../../util/logger';

interface BreadcrumbStep {
  name: string;
  current: boolean;
  href: string;
}

interface BreadcrumbProps {
  selectedOrder: OrderModel | undefined;
}

const Breadcrumbs = ({ selectedOrder }: BreadcrumbProps) => {
  const [breadcrumbSteps, setBreadcrumbSteps] = useState<BreadcrumbStep[]>([]);

  const { farmId, orderGroupId } = useParams<CloudAppParams>();
  const href = useLocation().pathname;

  const setSteps = async (additionalSteps?: BreadcrumbStep[]) => {
    if (!orderGroupId) return;
    await orderStore.getOrderGroup(orderGroupId, false);

    let steps = [
      {
        name: orderStore.currentOrderGroup?.uuid
          ? orderStore.currentOrderGroup?.uuid
          : '',
        current: true,
        href: `/${farmId}/order-details/${orderGroupId}`,
      },
    ];
    if (additionalSteps) {
      steps.forEach((step) => {
        step.current = false;
      });
      steps = [...steps, ...additionalSteps];
    }
    setBreadcrumbSteps(steps);
  };

  useEffect(() => {
    setSteps().catch(logger.error);
  }, []);

  useEffect(() => {
    const selectedOrderStep: BreadcrumbStep = {
      name: selectedOrder?.uuid ? selectedOrder.uuid : '',
      current: false,
      href: `/${farmId}/order-details/${orderGroupId}`,
    };
    if (href.includes('/analytics')) {
      setSteps([
        selectedOrderStep,
        {
          name: 'Analytics',
          current: true,
          href: `/${farmId}/order-details/${orderGroupId}/order/${selectedOrder?._id}/analytics`,
        },
      ]);
    } else if (href.includes('/events')) {
      setSteps([
        selectedOrderStep,
        {
          name: 'Events',
          current: true,
          href: `/${farmId}/order-details/${orderGroupId}/order/${selectedOrder?._id}/events`,
        },
      ]);
    } else {
      setSteps();
    }
  }, [window.location.href]);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="ml-4 flex items-center space-x-4">
        {breadcrumbSteps.map((step) => (
          <li key={step.name}>
            <div className="flex items-center">
              <Link
                to={step.href}
                className={`mr-2 hover:text-green-700 cursor-pointer ${
                  step.current && 'border-b-2 border-green-600 font-semibold'
                }`}
              >
                <p className="p-2">{step.name}</p>
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
