import ModalName from 'enums/ModalName.enum';
import GenericModal from '../GenericModal';
import modalStore from 'stores/modal.store';
import orderStore from 'stores/order.store';
import { useState } from 'react';
import mobxify from 'util/hocs/mobxify';

/**
 *
 * @param param0 orderStore?.deleteOneOrder(farmId, order._id);
 * @returns
 */

const DeleteOrderModal = () => {
  const [disabled, setDisabled] = useState<boolean>(false);

  return (
    <GenericModal modalName={ModalName.DELETE_ORDER}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Delete this order group
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Do you really want to delete this order group?</p>
        </div>
        <div className="mt-5 flex justify-end">
          <button
            onClick={() => {
              modalStore?.closeModal();
            }}
            name="cancel"
            type="button"
            className="mr-4 inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
          >
            Cancel
          </button>
          <button
            disabled={disabled}
            onClick={async () => {
              const data = modalStore?.customData;
              if (data) {
                setDisabled(true);
                await orderStore?.deleteOneOrderGroup(data.orderGroup);
                setDisabled(false);
                modalStore?.closeModal();
              }
            }}
            type="button"
            className={`inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm ${
              disabled && 'cursor-wait'
            }`}
          >
            Delete order
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default mobxify(DeleteOrderModal, 'modalStore', 'orderStore');
