import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { WarehouseStore } from 'stores/warehouse.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

export class WarehouseWebsocket {
  private warehouseStore: WarehouseStore;
  private organizationId = '';

  constructor(warehouseStore: WarehouseStore, organizationId: string) {
    this.warehouseStore = warehouseStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.WAREHOUSE}`,

        async (...args: WebsocketMessage[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              await this.find();
            }

            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              await this.find();
            }

            if (obj.command === WEBSOCKETCOMMANDS.DELETED) {
              await this.find();
            }
          }
        },
      );
  }

  private async find() {
    await this.warehouseStore.find();
  }
}
