import { PlusIcon } from '@heroicons/react/24/outline';
import Grid from 'components/Grid/Grid';
import ModalName from 'enums/ModalName.enum';
import { Plant } from 'models/Plant.model';
import { Recipe } from 'models/Recipe.model';
import { RecipeGroup } from 'models/RecipeGroup.model';
import modalStore from 'stores/modal.store';
import recipeStore from 'stores/recipe.store';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CloudAppParams } from 'routes';
import { sortRecipeGroups } from 'util/PlantRecipeUtils';
import {
  plantGroupTabKey,
  plantGroupTabs,
  RecipeModalMode,
} from 'util/tabNames';
import PlantRecipeCard from './PlantRecipeCard/PlantRecipeCard';
import PlantRecipeFilter from './PlantRecipeFilter';
import SearchBar from './PlantRecipeSearch';
import { getPlantImageFromRecipe } from './util/PlantRecipe.util';
import withLoader from 'util/hocs/withLoader';
import mobxify from 'util/hocs/mobxify';
import { useQuerySetter } from 'util/useQuerySetter2';

const filterRecipeGroups = (query: string) => (recipeGroup: RecipeGroup) =>
  query
    ? JSON.stringify(recipeGroup).toLowerCase().includes(query.toLowerCase())
    : true;

const PlantRecipe = () => {
  const { query } = useQuerySetter();
  const params = useParams<CloudAppParams>();
  const archived = params[plantGroupTabKey] === plantGroupTabs.archive;
  const [search, setSearch] = useState('');

  const recipeGroups =
    recipeStore.recipeGroups?.filter(filterRecipeGroups(search)) || [];
  const plants = recipeStore?.plantsOfGroup || [];
  const [plantFilter, setPlantFilter] = useState<Plant[]>([]);
  const [sort, setSort] = useState({
    label: 'Recently Updated',
    value: 'updatedAt',
  });

  useEffect(() => {
    const recipeGroup = query.get('recipeGroup');
    const recipe = query.get('recipe');
    if (!recipeGroup) return;
    if (!recipe) return;
    modalStore?.setRecipeModalMode(RecipeModalMode.VIEW);
    modalStore?.openModal(ModalName.RECIPE_GROUP);
  }, []);

  const isRecipeInSelectedPlant = (
    plants: Plant[],
    recipeId: Recipe['_id'],
  ) => {
    return plants.some((plant) =>
      plant.recipes.find((plantRecipes) => plantRecipes === recipeId),
    );
  };

  const filteredRecipeGroups = useMemo(() => {
    return recipeGroups
      .filter((recipeGroup) => recipeGroup.archived === archived)
      .filter((recipeGroup: RecipeGroup) => {
        if (plantFilter.length === 0) return true;
        return isRecipeInSelectedPlant(
          plantFilter,
          (recipeGroup.selected as Recipe)._id,
        );
      })
      .sort((a, b) => {
        return sortRecipeGroups(a, b, sort);
      });
  }, [recipeGroups, plantFilter, sort, archived]);

  return (
    <div className="overflow-y-scroll h-[calc(100vh-130px)]">
      <div className="p-5 pb-0 w-full">
        <SearchBar
          options={[
            { label: 'Oldest First', value: 'createdAtLast' },
            { label: 'Newest First', value: 'createdAtFirst' },
            { label: 'Recently updated', value: 'updatedAt' },
            { label: 'Most used', value: 'orderAmount' },
          ]}
          searchValue={search}
          onChange={setSearch}
          setSort={setSort}
        />
      </div>
      <div className="pl-5 py-2 flex flex-row flex-wrap">
        {plants.map((plant: Plant) => (
          <PlantRecipeFilter
            key={plant._id}
            plant={plant}
            plantFilter={plantFilter}
            setPlantFilter={setPlantFilter}
          />
        ))}
      </div>
      <Grid maxHeight="max-h-[12rem]">
        {!archived && (
          <li
            className="h-[12rem]  col-span-1 flex flex-col text-center bg-white hover:shadow-lg rounded-lg shadow box p-3 cursor-pointer"
            onClick={() => {
              modalStore?.setRecipeModalMode(RecipeModalMode.CREATE);
              modalStore?.openModal(ModalName.CREATE_PLANT_RECIPE);
            }}
          >
            <button className="flex p-8 justify-center items-center border-2 border-dotted h-full w-full flex-col rounded-lg  hover:border-solid text-gray-400 hover:text-gray-600">
              <PlusIcon className="w-7 h-7" />
              <div className="w-16 text-center">Add a new recipe</div>
            </button>
          </li>
        )}

        {filteredRecipeGroups.map((recipeGroup, key) => {
          if (!recipeStore?.plantsOfGroup) return;
          return (
            <PlantRecipeCard
              recipeGroup={recipeGroup}
              key={key}
              archived={archived}
              image={getPlantImageFromRecipe(
                (recipeGroup.selected as Recipe).plants as string[],
                recipeStore?.plantsOfGroup,
              )}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default withLoader(mobxify(PlantRecipe, 'modalStore', 'recipeStore'));
