import { ChevronRightIcon } from '@heroicons/react/24/outline';
import ActiveIndicator from 'components/ActiveIndicator/ActiveIndicator';
import NoDataFound from 'components/NoDataFound/NoDataFound';
import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CloudAppParams } from 'routes';

const warehouseItemTypes = [
  { label: 'Plant', value: WAREHOUSE_ITEM_TYPE.PLANT },
  { label: 'Soil', value: WAREHOUSE_ITEM_TYPE.SOIL_TYPE },
];

const mfcItemTypes = [
  {
    label: 'Oxygenation Method',
    value: WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD,
  },
  { label: 'Light Type', value: WAREHOUSE_ITEM_TYPE.LIGHT_TYPE },
];

const ItemSidebar: FC = () => {
  const { farmId, itemType } = useParams<CloudAppParams>();
  const baseUrl = `/${farmId}`;
  const isMfc = useLocation().pathname.includes('mfc');
  const endpoint = isMfc ? 'mfc' : 'warehouse';

  const items = isMfc ? mfcItemTypes : warehouseItemTypes;

  if (items.length === 0) {
    return (
      <div className="h-full flex justify-center items-center">
        <NoDataFound title="Warehouse" subtitle="No Warehouse data found" />
      </div>
    );
  }

  return (
    <div className="h-screen overflow-auto pb-32 mt-3">
      <ul className="divide-y divide-gray-200">
        {items.map((item) => (
          <Link
            key={item.label}
            className="block hover:bg-gray-50 cursor-pointer"
            to={`${baseUrl}/items/${endpoint}/${item.value}`}
          >
            <button
              id={`${endpoint}-${item.label}`}
              className="flex items-center justify-between px-4 py-4  pr-2 text-xs font-semibold truncate w-full"
            >
              {item.label}
              <div className="flex justify-center items-center">
                {item.value === itemType && <ActiveIndicator />}
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </button>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default ItemSidebar;
