import { TrashIcon } from '@heroicons/react/24/outline';
import ModalName from 'enums/ModalName.enum';
import { OrderModel } from 'models/Order.model';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { CloudAppParams } from 'routes';
import modalStore from 'stores/modal.store';
import orderStore from 'stores/order.store';
import plantStore from 'stores/plant.store';
import recipeStore from 'stores/recipe.store';
import { recipeModalTabs } from 'util/tabNames';

interface OrderGroupInformationProps {
  currentOrder?: OrderModel;
}

const OrderGroupInformation = ({
  currentOrder,
}: OrderGroupInformationProps) => {
  const { farmId } = useParams<CloudAppParams>();

  const plant = plantStore.plant;
  const recipe = recipeStore.recipe;
  const recipeGroup = recipeStore.groupOfRecipe;
  const orderGroup = orderStore.currentOrderGroup;

  const recipeUrl = useMemo(() => {
    if (!currentOrder) {
      toast.error('Sorry, unfortunately there is no url to this recipe.');
      return '';
    }
    return `/${farmId}/plantGroups/${currentOrder.plantGroup}/recipes?recipeGroup=${recipeGroup?._id}&recipe=${recipe?._id}&recipe-modal-tab=${recipeModalTabs.general}`;
  }, [currentOrder, recipe, recipeGroup]);

  const plantUrl = useMemo(() => {
    if (!currentOrder) {
      toast.error('Sorry, unfortunately there is no url to this plant.');
      return '';
    }

    return `/${farmId}/plantGroups/${currentOrder.plantGroup}/plants`;
  }, [currentOrder]);

  const handleDelete = () => {
    if (!farmId || !orderGroup)
      return toast.error('OrderGroup can not be deleted.');

    modalStore?.openModal(ModalName.DELETE_ORDER, {
      farmId,
      orderGroup: orderGroup._id,
    });
  };

  if (!orderGroup) return null;

  return (
    <div className="flex flex-row mb-4 px-2 justify-between w-full">
      <div className="flex flex-row w-11/12">
        <div className="flex flex-col w-1/2 mr-8">
          <p className="text-xl text-green-600 font-semiboldw-fit">
            {orderGroup.target}
          </p>
          <p className="text-sm text-gray-500 mt-1">{orderGroup.description}</p>
          <Link
            className="text-sm text-gray-500 mt-1 cursor-pointer hover:underline w-fit"
            to={plantUrl}
            data-tooltip-id="plant-tooltip"
            data-tooltip-html="Navigate to Plant"
          >
            {plant?.name}
          </Link>
          <Tooltip id="plant-tooltip" />
          <Link
            to={recipeUrl}
            relative="path"
            className="text-sm text-gray-500 mt-1 hover:underline cursor-pointer w-fit"
            data-tooltip-id="recipe-tooltip"
            data-tooltip-html="Navigate to Recipe"
          >
            {recipe?.name} - {recipe?.version}
          </Link>
          <Tooltip id="recipe-tooltip" />
        </div>
        <div className="flex flex-col w-1/2 mr-8"></div>
      </div>
      <div className="flex flex-col w-1/12">
        <button
          className="self-end"
          onClick={handleDelete}
          data-tooltip-id="delete-order-group-tooltip"
          data-tooltip-html={'Delete OrderGroup'}
        >
          <TrashIcon className="w-6 h-6 text-green-600 hover:text-green-800" />
        </button>
        <Tooltip id="delete-order-group-tooltip" />
      </div>
    </div>
  );
};

export default OrderGroupInformation;
