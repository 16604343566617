/* eslint-disable unused-imports/no-unused-vars */
import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  StarIcon as Star,
} from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  StarIcon as StarOutline,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Recipe } from 'models/Recipe.model';
import { RecipeModalMode } from 'util/tabNames';
import useQuerySetter from 'util/useQuerySetter';
import recipeStore from 'stores/recipe.store';
import modalStore from 'stores/modal.store';
import { Fragment } from 'react';
import mobxify from 'util/hocs/mobxify';

interface RecipeGroupSidebarItemProps {
  recipe: Recipe;
  isFavorite: boolean;
  isFirst: boolean;
  isLast: boolean;
  isArchived?: boolean;
  draft?: boolean;
}

const RecipeGroupSidebarItem = ({
  recipe,
  isFavorite,
  isFirst,
  isLast,
  isArchived,
  draft,
}: RecipeGroupSidebarItemProps) => {
  const { setQuery, query } = useQuerySetter();
  const recipeId = query.get('recipe');

  // const { farmId } = useParams<CloudAppParams>();
  // const navigate = useNavigate();
  // const baseUrl = `/${farmId}`;

  const handleClick = () => {
    const recipeGroup = query.get('recipeGroup');
    if (!recipeGroup) return;
    if (!recipe) return;

    recipeStore?.favoriteRecipe(recipeGroup, recipe._id);
  };

  const selected = recipeId == recipe._id;
  const renderDropDown = () => {
    if (isArchived) return null;

    return (
      <div className="flex-shrink-0 self-center flex">
        <Menu as="div" className="relative  inline-block text-left">
          <div>
            <Menu.Button className="rounded-full flex z-10 items-center text-gray-400 hover:text-gray-600 focus:outline-none ">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon
                className={
                  'h-5 w-5 ' + (selected ? 'text-white' : 'text-gray-400')
                }
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right  z-50 absolute right-0 top-1 mt-2 w-56 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex px-4 py-2 text-sm',
                        'cursor-pointer',
                      )}
                      onClick={handleClick}
                    >
                      <Star
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Favorite</span>
                    </div>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active
                          ? 'bg-gray-100 text-gray-900 '
                          : 'text-gray-700 ',
                        'flex px-4 py-2 text-sm',
                        'cursor-pointer',
                      )}
                      onClick={() => {
                        if (!recipe) return;
                        recipeStore?.toggleArchive(recipe._id, recipe.archived);
                        const recipeGroup = recipeStore?.recipeGroup;

                        if (recipeGroup && recipeGroup.selected) {
                          setQuery([
                            {
                              term: 'recipe',
                              value: (recipeGroup.selected as Recipe)._id,
                            },
                          ]);
                        }
                      }}
                    >
                      <ArchiveBoxIcon
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>{recipe.archived ? 'Unarchive' : 'Archive'}</span>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  };

  const handleOpen = (id: string) => {
    setQuery([{ term: 'recipe', value: id }], { onlyReplaceStatedTerms: true });
    // TODO
    // navigate(`${baseUrl}/plantGroups/${id}/${plantGroupTabs.recipes}`);
  };

  const containerStyle = classNames({
    'rounded-t-md': isFirst,
    'rounded-b-md': isLast,
    'hover:bg-green-600 hover:text-white': !selected && !recipe.archived,
    'bg-green-600':
      selected && modalStore?.recipeModalMode === RecipeModalMode.VIEW,
    'bg-gray-300': recipe.archived,
    block: true,
  });

  return (
    <li
      key={recipe._id}
      onContextMenu={(event) => {
        event.preventDefault();
      }}
      className={`${isArchived ? 'pointer-events-none' : ''}`}
    >
      <div className={containerStyle + ' transition-all'}>
        <div className={`p-2 select-none ${!selected ? 'cursor-pointer' : ''}`}>
          <div
            className="flex items-center justify-start "
            onClick={() => handleOpen(recipe._id)}
          >
            <div
              className={`flex flex-col rounded-md w-10 h-10 bg-gray-100 bg-opacity-80 justify-center items-center mr-4 cursor-default ${
                selected ? 'bg-green-600' : 'bg-white'
              } `}
            >
              {isFavorite && !isArchived ? (
                <Star className="w-7 text-green-600 " />
              ) : (
                <StarOutline className="w-6 text-gray-400 " />
              )}
            </div>
            <div
              className={classNames([
                selected &&
                modalStore?.recipeModalMode === RecipeModalMode.VIEW &&
                !isArchived
                  ? 'text-white'
                  : '',
                'grow',
              ])}
            >
              <p className="text-14 font-medium truncate">
                Version {recipe.version}
              </p>
              <div className="mt-2 flex items-center text-sm text-opacity-50 sm:mt-0">
                <p className="mr-1">
                  <time dateTime={dayjs(recipe.createdAt).toISOString()}>
                    {dayjs(recipe.createdAt).format('DD.MM.YYYY')}
                  </time>
                </p>
                {recipe.archived && (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-600 text-white first-of-type:mr-0">
                    Archived
                  </p>
                )}
                {recipe.strict && (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-600 text-white mr-1">
                    Strict
                  </p>
                )}
              </div>
            </div>
            {modalStore?.recipeModalMode === RecipeModalMode.VIEW &&
              !isFavorite &&
              renderDropDown()}
            {draft && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                Draft
              </span>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default mobxify(RecipeGroupSidebarItem, 'recipeStore', 'modalStore');
