import moment from 'moment';
import { AnalyticDataType } from 'models/AnalyticsDataType.model';
import { getTypeForKey } from 'util/KeyMappers';

const calculateDay = (millis: number) => {
  return Math.floor(millis / 1000 / 60 / 60 / 24);
};

const calculateAverage = (values: any[]) => {
  let result = 0;

  for (const val of values) {
    result += val;
  }
  return result / values.length;
};

const getDay0 = (data: any[]) => {
  let result = moment();
  if (data && data.length > 0) {
    for (const entry of data) {
      const newDate = moment(entry._id);
      if (newDate.isBefore(result)) {
        result = newDate;
      }
    }
  }
  return result;
};

const generateMapping = (analyticDataTypes: AnalyticDataType[], data: any) => {
  const values: any = {};
  if (data && data.length > 0) {
    const day0 = getDay0(data);
    for (const set of data) {
      for (const day of set.data) {
        for (const key in day) {
          // TODO refactor
          const diff = calculateDay(moment(day.date).diff(day0));
          const type = getTypeForKey(analyticDataTypes, key as any);
          if (type.showInDiagram) {
            if (!values[key + '_' + diff]) {
              values[key + '_' + diff] = [];
            }
            values[key + '_' + diff].push(day[key]);
          }
        }
      }
    }
  }
  return values;
};

export { calculateAverage, generateMapping, getDay0, calculateDay };
