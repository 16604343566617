import SidebarHeader from 'components/SidebarHeader/SidebarHeader';
import ModalName from 'enums/ModalName.enum';
import { PlantGroup } from 'models/PlantGroup.model';
import { Outlet } from 'react-router';
import plantStore from 'stores/plant.store';
import SinglePlantGroup from './SinglePlantGroup';
import withLoader from 'util/hocs/withLoader';
import mobxify from 'util/hocs/mobxify';

const Plants = () => {
  return (
    <div className="max-w-full min-h-screen w-full">
      <div className="flex border-r">
        <div className="flex flex-col border-r bg-white min-w-[16rem] max-w-[16rem]">
          <SidebarHeader
            title="Plant Groups"
            showAddButton={true}
            modalName={ModalName.CREATE_PLANTGROUP}
            testid="addplantsbutton"
          />

          <ul className="overflow-y-scroll max-h-screen pb-24">
            {plantStore?.plantGroups?.map((plantGroup: PlantGroup) => (
              <SinglePlantGroup plantGroup={plantGroup} key={plantGroup._id} />
            ))}
          </ul>
        </div>
        <div className="bg-gray-100 flex-grow heigth-screen flex justify-center items-center min-h-screen">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default withLoader(mobxify(Plants, 'plantStore'));
