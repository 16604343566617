import { Component } from 'react';
import logger from './logger';

export default class ErrorBoundary extends Component<
  any,
  { hasError: boolean; error?: Error }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    // this.setState({ error: error.message })

    logger.error(error.message);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <h1>Something went wrong. </h1>
          <p className="message">{this.state.error?.message}</p>

          <button>
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                window.location.reload();
              }}
            >
              Reload App
            </a>
          </button>

          <button className="secondary">
            <a href="/">Go to Home</a>
          </button>
          <p className="stack">{JSON.stringify(this.state.error?.stack)}</p>
        </div>
      );
    }

    return this.props.children;
  }
}
