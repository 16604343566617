import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Fragment } from 'react';
import ModalName from 'enums/ModalName.enum';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

interface PlantSelectDropDownProps {
  plantName: string;
}

const PlantSelectDropDown = ({ plantName }: PlantSelectDropDownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full border-gray-300px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="w-4 h-4" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => {
                  modalStore?.openModal(ModalName.ADD_PLANT_TYPE, {
                    name: plantName,
                  });
                }}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm cursor-pointer',
                )}
              >
                Rename
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active ? 'bg-gray-100 text-gray-300' : 'text-gray-300',
                  'block px-4 py-2 text-sm cursor-not-allowed',
                )}
              >
                Delete
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default mobxify(PlantSelectDropDown, 'modalStore');
