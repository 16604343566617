import { DocumentArrowDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDebounce } from 'use-debounce';
import LoadingScreen, {
  LoaderSize,
} from 'components/LoadingScreen/LoadingScreen';
import OrderOverview from 'components/OrderOverview/OrderOverview';
import orderStore, { OrderFilter } from 'stores/order.store';
import plantStore from 'stores/plant.store';
import SearchBar from '../plant-details/PlantRecipe/PlantRecipeSearch';
import FarmOrderFilter from './FarmOrderFilter';
import withLoader from 'util/hocs/withLoader';
import mobxify from 'util/hocs/mobxify';
import { OrderGroupModel } from 'models/Order.model';
import { toast } from 'react-toastify';
import { PlantGroup } from 'models/PlantGroup.model';
import { Plant } from 'models/Plant.model';

interface Response {
  hasNextPage: boolean;
  data: OrderGroupModel[];
}

const OrderHistory = () => {
  const RESPONSE_TIME_IN_MS = 200;
  const PAGE_LENGTH = 30;
  const [showArchive, setShowArchive] = useState(true);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [orderGroups, setOrderGroups] = useState<OrderGroupModel[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [error, setError] = useState<Error | string>();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [debouncedSearch] = useDebounce(search, 1000);
  const [selectedPlantGroup, setSelectedPlantGroup] = useState<
    PlantGroup | undefined
  >();
  const [selectedPlants, setSelectedPlants] = useState<Plant[]>([]);
  const [savedOrderGroups, setSavedOrderGroups] = useState<OrderGroupModel[]>(
    [],
  );
  const [selectedOrderGroups, setSelectedOrderGroups] = useState<
    OrderGroupModel['uuid'][]
  >([]);

  // const load = async () => {
  //   if (orderGroupId && orderid) {
  //     await orderStore?.getCurrentOrder(orderid, {
  //       populate: 'recipe plant',
  //     });
  //     modalStore?.openModal(ModalName.CHOOSE_ORDER);
  //   } else {
  //     modalStore?.closeModal();
  //     if (!params?.farmId) return;

  //     setFarmId(params.farmId);
  //   }
  // };

  // useEffect(() => {
  //   load();
  // }, [query.toString()]);

  useEffect(() => {
    setOrderGroups([]);
    setSavedOrderGroups([]);
    setHasNextPage(true);
  }, [
    debouncedSearch,
    selectedPlantGroup,
    dateFrom,
    dateTo,
    selectedPlants,
    showArchive,
    selectedOrderGroups,
  ]);

  async function loadMore() {
    setLoading(true);
    try {
      const { data, hasNextPage: newHasNextPage } = await loadItems(
        orderGroups.length,
      );

      if (!data) return;
      const newData = Array.isArray(data) ? data : [data];

      setOrderGroups((current) => [...current, ...newData]);
      setHasNextPage(newHasNextPage);
    } catch (error) {
      setError(error as Error);
      toast.error('Error exporting orders as excel');
    } finally {
      setLoading(false);
    }
  }

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 0px 0px',
  });

  function checkFilterState(initial: Pick<OrderFilter, 'limit' | 'skip'>) {
    const Filter: OrderFilter = { showArchive: showArchive, ...initial };
    // TODO refactor this to only use Filter object and not multiple if statements
    if (selectedOrderGroups.length > 0) {
      Filter.orderGroups = selectedOrderGroups;
    }
    if (search) {
      Filter.search = search;
    }
    if (dateFrom) {
      Filter.dateFrom = dateFrom;
    }
    if (dateTo) {
      Filter.dateTo = dateTo;
    }
    if (selectedPlantGroup) {
      Filter.plantGroup = selectedPlantGroup._id;
    }
    if (selectedPlants && selectedPlants.length > 0) {
      Filter.plants = selectedPlants.map((sP) => sP._id);
    }

    return Filter;
  }

  function checkHasNextPage(
    page: OrderGroupModel[] = [],
    ordersPerPage: number = PAGE_LENGTH,
  ) {
    return page.length >= ordersPerPage;
  }

  async function loadOrderGroups(startCursor: number) {
    if (savedOrderGroups.length > 0) {
      return savedOrderGroups;
    }

    return await orderStore?.loadOrdersForInfiniteScroll(
      checkFilterState({
        limit: PAGE_LENGTH,
        skip: startCursor,
      }),
    );
  }

  const getPage = (
    fetchedOrders: OrderGroupModel[] = [],
    hasNextPage: boolean,
  ) => {
    if (hasNextPage) {
      const currentPage = fetchedOrders.splice(0, PAGE_LENGTH);
      setSavedOrderGroups(fetchedOrders);
      return currentPage;
    }
    return fetchedOrders;
  };

  function loadItems(startCursor = 0): Promise<Response> {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const fetchedOrders = await loadOrderGroups(startCursor);
        const hasNextPage = checkHasNextPage(fetchedOrders);
        resolve({
          data: getPage(fetchedOrders, hasNextPage),
          hasNextPage: hasNextPage,
        });
      }, RESPONSE_TIME_IN_MS);
    });
  }

  const exportOrdersToExcel = async () => {
    setExportLoading(true);
    try {
      const filter = checkFilterState({
        limit: 1000,
        skip: 0,
      });

      const exportOrders: OrderGroupModel[] =
        await orderStore?.loadOrdersForExcelExport(filter);

      type ObjectId = string;
      const orderIds: ObjectId[] = exportOrders
        .map((oG) => oG.orders)
        .flat()
        .map((o) => o._id);

      await orderStore?.exportOrdersToExcel(orderIds);
    } catch (error) {
      setError(error as Error);
    } finally {
      setExportLoading(false);
    }
  };

  const onChangeSearchField = (query: string) => {
    setSearch(query);
  };
  return (
    <div className="h-full bg-gray-50 w-full">
      <div className="flex justify-between col-span-full p-5 w-full bg-gray-50 pb-1">
        <div className="w-full">
          <SearchBar
            hideSort
            searchValue={search}
            onChange={(query) => onChangeSearchField(query)}
          />
        </div>
        <div />
        <div className="flex items-center h-full">
          <FarmOrderFilter
            showArchive={showArchive}
            setShowArchive={setShowArchive}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            setSelectedPlantGroup={setSelectedPlantGroup}
            setSelectedPlants={setSelectedPlants}
            selectedPlantGroup={selectedPlantGroup}
            selectedPlants={selectedPlants}
            plantGroups={plantStore?.plantGroups}
            plants={plantStore?.allPlants}
            selectedUuids={selectedOrderGroups}
            setSelectedUuids={setSelectedOrderGroups}
          />
        </div>
        <div>
          <button
            onClick={exportOrdersToExcel}
            type="submit"
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {exportLoading ? (
              <div className="flex">
                <LoadingScreen color="#ffffff" size={LoaderSize.Small} />
                <span className="ml-2">Export</span>
              </div>
            ) : (
              <div className="flex">
                <DocumentArrowDownIcon className="w-5 h-5" />
                <span className="ml-2">Export</span>
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="max-h-full max-w-full overflow-auto pb-40" ref={rootRef}>
        <ul>
          <OrderOverview orderGroups={orderGroups} isLoading={loading} />
          {hasNextPage && (
            <li className="p-4 border-gray-200 b-3 mt-4" ref={infiniteRef}>
              <LoadingScreen />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withLoader(mobxify(OrderHistory, 'orderStore', 'plantStore'));
