import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface QueryOptions {
  overwriteQuery?: boolean;
  checkIfAvailable?: boolean;
  onlyReplaceStatedTerms?: boolean;
}

interface UseQuerySetter {
  term: string;
  value: string;
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useQuerySetter = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const setQuery = useCallback(
    (
      queryToSet: UseQuerySetter | UseQuerySetter[],
      options: QueryOptions = {
        overwriteQuery: false,
        checkIfAvailable: false,
      },
    ) => {
      let newQuery;
      if (Array.isArray(queryToSet)) {
        newQuery = queryToSet.reduce((acc, curr) => {
          if (options.checkIfAvailable && query.get(curr.term)) {
            return {
              ...acc,
              [curr.term]: query.get(curr.term) as string,
            };
          } else {
            return {
              ...acc,
              [curr.term]: curr.value,
            };
          }
        }, {});
      } else if (options.checkIfAvailable || !query.has(queryToSet.term)) {
        newQuery = { [queryToSet.term]: queryToSet.value };
      }

      if (newQuery) {
        const searchString = options.overwriteQuery
          ? `?${new URLSearchParams(newQuery).toString()}`
          : `${
              options.onlyReplaceStatedTerms
                ? stripTerms(
                    window.location.search,
                    Array.isArray(queryToSet)
                      ? queryToSet.map((q) => q.term)
                      : [queryToSet.term],
                  )
                : window.location.search
            }&${new URLSearchParams(newQuery).toString()}`;

        navigate({
          pathname: window.location.pathname,
          search: searchString,
        });
      }
    },
    [],
  );

  const deleteQueries = useCallback((terms?: string[]) => {
    if (terms) {
      terms.forEach((term) => {
        query.delete(term);
      });
    }
    navigate({
      pathname: window.location.pathname,
      search: terms ? query.toString() : '',
    });
  }, []);

  const stripTerms = useCallback((queryString: string, terms?: string[]) => {
    const queryArray = queryString.replace(/\?/g, '').split('&');

    // filter if queryItem contains any term
    const strippedQuery = queryArray
      .filter((queryItem) => {
        const queryItemIsInTerms = terms?.some((term) =>
          term === '' ? false : queryItem.split('=')[0] === term,
        );

        return !queryItemIsInTerms;
      })
      .join('&');

    return `?${strippedQuery}`;
  }, []);

  return { setQuery, query, push: navigate, deleteQueries, stripTerms };
};

export default useQuerySetter;
