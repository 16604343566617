import { action, makeObservable, observable } from 'mobx';
import { AnalyticsData } from 'models/AnalyticsData.model';
import { AnalyticDataType } from 'models/AnalyticsDataType.model';
import Axios from 'util/Axios';
import { generateMapping, calculateAverage } from 'util/CompareRecipe.helper';

export class AnalyticStore {
  @observable currentAnalytics: AnalyticsData[] = [];
  @observable currentAnalytic: AnalyticsData | undefined;
  @observable analyticDataTypes: AnalyticDataType[] = [];
  @observable analytics1: any;
  @observable analytics2: any;
  @observable images: any;

  constructor() {
    makeObservable(this);
  }

  @action
  async getAnalyticByTimeStamp(orderId: string, timestamp: Date) {
    const response = await Axios.get(`/orders/${orderId}/analytics`);
    if (response.status === 200) {
      const eventTimestamp = new Date(timestamp);
      const analytic = response.data.find(
        (analytic: AnalyticsData) =>
          Math.abs(
            new Date(analytic.data.date).getTime() - eventTimestamp.getTime(),
          ) <= 60000,
      );
      this.setCurrentAnalytic(analytic);
    }
  }

  @action
  async loadAnalyticData(
    orderId: string,
    options: { [key: string]: string | number | boolean } = {},
  ) {
    const response = await Axios.get(`/orders/${orderId}/analytics`, {
      params: options,
    });
    if (response.status === 200) {
      this.setCurrentAnalytics(response.data.reverse());
    }
  }

  @action
  async loadAnalyticDataTypes() {
    const response = await Axios.get('/types/analytic-data');
    if (response.status === 200) {
      this.setCurrentDataTypeAnalytic(response.data);
    }
  }

  @action
  setCurrentAnalytics(analytic: AnalyticsData[]) {
    this.currentAnalytics = analytic;
  }

  @action
  setCurrentAnalytic(analytic: AnalyticsData) {
    this.currentAnalytic = analytic;
  }

  @action
  setCurrentDataTypeAnalytic(analyticData: any) {
    this.analyticDataTypes = analyticData;
  }

  @action
  async loadAnalyticsForRecipeCompare(
    data: { recipe?: string; recipeGroup?: string },
    index: number,
    analyticDataTypes: AnalyticDataType[],
  ) {
    const response = await Axios.get(`/analytics/recipe/${data.recipe}`);

    const result: any = {};
    const values: any = generateMapping(
      analyticDataTypes,
      response.data.reverse(),
    );

    for (const valueKey in values) {
      const [key, date] = valueKey.split('_');
      if (!result[key]) {
        result[key] = { key: key, values: [] };
      }
      result[key].values.push({
        dateWithDay: `Day ${parseInt(date) + 1}`,
        date: parseInt(date),
        value: calculateAverage(values[valueKey]),
        key: key,
      });
    }

    const lines: any = [];
    for (const key in result) {
      lines.push(result[key]);
    }
    if (index === 0) {
      this.setAnalyticsDataOne({ lines });
    } else if (index === 1) {
      this.setAnalyticsDataTwo({ lines });
    }
  }

  @action
  setAnalyticsDataOne(data: any) {
    this.analytics1 = data;
  }

  @action
  setAnalyticsDataTwo(data: any) {
    this.analytics2 = data;
  }
}

const analyticStore = new AnalyticStore();
export default analyticStore;
