import NoDataFound from 'components/NoDataFound/NoDataFound';
import ModalName from 'enums/ModalName.enum';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

const NutritionSolutionSidebarEmptyState = () => {
  return (
    <div className="h-full flex justify-center items-center">
      <NoDataFound
        title="Nutrition Solutions"
        subtitle="Start by creating your first nutrition solution."
        btnText="Add Nutrition Solution"
        onClick={() => {
          modalStore?.openModal(ModalName.NUTRITION_SOLUTION_GROUP);
        }}
      />
    </div>
  );
};

export default mobxify(NutritionSolutionSidebarEmptyState, 'modalStore');
