import {
  CalendarIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  HashtagIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CloudAppParams } from 'routes';
import mobxify from 'util/hocs/mobxify';
import { OrderGroupModel, OrderModel } from '../../models/Order.model';
import {
  determineCurrentDay,
  determineCurrentPhase,
  getFullRecipeDuration,
  getImage,
} from '../../pages/currentOrderGroups/CurrentOrderGroupUtils';
import {
  orderModalTabKey,
  orderModalTabs,
  orderTabKey,
  orderTabs,
} from '../../util/tabNames';
import useQuerySetter from '../../util/useQuerySetter';
import ProgressBar from './ProgressBar';

interface OrdergroupCardProps {
  orderGroup: OrderGroupModel;
  hideProgress?: boolean;
  hideAmount?: boolean;
}
const OrderGroupCard = ({
  orderGroup,
  hideProgress,
  hideAmount,
}: OrdergroupCardProps) => {
  const { setQuery, query } = useQuerySetter();
  const [image, setImage] = useState<string | undefined>();
  const navigate = useNavigate();
  const { farmId } = useParams<CloudAppParams>();

  useEffect(() => {
    setImage(getImage(orderGroup));
  }, []);

  const isPending = (orderGroup: OrderGroupModel) => {
    if (!orderGroup.status) return false;
    return orderGroup.status === 'NOT_STARTED';
  };

  const handleClick = () => {
    const lastOrder =
      orderGroup.orders[orderGroup.orders.length - 1] || orderGroup.orders[0];
    if (!lastOrder) return;
    if (typeof lastOrder === 'string') return;
    setQuery(
      [
        {
          term: orderTabKey,
          value: query.get(orderTabKey) || orderTabs.orders,
        },
        { term: 'orderGroup', value: orderGroup._id },
        { term: 'order', value: lastOrder._id },
        { term: orderModalTabKey, value: orderModalTabs.general },
      ],
      {
        onlyReplaceStatedTerms: true,
      },
    );

    navigate(`/${farmId}/order-details/${orderGroup._id}`);
  };

  if (!orderGroup) {
    return <></>;
  }
  return (
    <div className="relative">
      {isPending(orderGroup) && (
        <div className="absolute w-full h-full flex items-center justify-center">
          <p className="font-semibold text-gray-900 text-xl z-20 pt-8"></p>
        </div>
      )}
      <div
        key={orderGroup.uuid}
        onClick={handleClick}
        className={`bg-white shadow rounded-lg mx-3 my-3 sm:h-60 2xl:h-80 hover:shadow-xl hover:cursor-pointer relative overflow-hidden ${
          isPending(orderGroup) ? 'opacity-50 grayscale' : ''
        }`}
      >
        <div
          className={`h-2/5 relative rounded-t-lg ${
            image ? 'bg-gray-500' : 'bg-green-700'
          }`}
        >
          {image && (
            <img
              className="h-full w-full rounded-t-lg"
              style={{ objectFit: 'cover', opacity: 0.6 }}
              src={image}
              alt="header image"
            />
          )}

          {!hideAmount && (
            <>
              <h4 className="text-white font-bold absolute bottom-2 left-6">
                {orderGroup.plant?.toUpperCase() || 'SALAT'}
              </h4>

              <p className="text-white font-bold absolute bottom-2 right-6">
                {(orderGroup.orders[0] as OrderModel)?.amount || '0'}x
              </p>
            </>
          )}

          {!hideProgress && (
            <button
              type="button"
              className="px-2 mt-2 absolute top-2 right-4 bg-gray-200 text-gray-500 p-2 rounded-xl text-xs leading-none flex items-center"
            >
              <ClockIcon className="h-3 w-3 mr-1" />
              {Math.round(
                getFullRecipeDuration(orderGroup) -
                  determineCurrentDay(orderGroup),
              )}
              {' days'}
            </button>
          )}
        </div>
        <div className="flex flex-col justify-between h-3/5">
          <div>
            <h3
              title={orderGroup.target}
              className="text-green-600 font-bold 2xl:text-xl sm:text-base w-full px-4 2xl:py-3 sm:py-1 truncate text-ellipsis"
            >
              {orderGroup.target}
            </h3>

            <div className="px-4 2xl:py-2 sm:py-1 flex flex-col flex-grow max-h-full">
              <div className="flex text-sm text-gray-500 ">
                <HashtagIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
                <p>{orderGroup.uuid || 'No Description'}</p>
              </div>
              <div className="flex items-center text-sm text-gray-500 mt-2">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
                <p>
                  Started on{' '}
                  {dayjs(
                    (orderGroup.orders?.[0] as OrderModel)?.startDate ||
                      orderGroup.createdAt,
                  ).format('DD.MM.YYYY')}
                </p>
              </div>

              <div className="flex text-sm text-gray-500 mt-2 max-h-full">
                <ClipboardDocumentCheckIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
                <p
                  className="truncate text-ellipsis"
                  title={orderGroup.description}
                >
                  {orderGroup.description || 'No Description'}
                </p>
              </div>
            </div>
          </div>

          <div className="w-full font-semibold">
            {!hideProgress &&
              orderGroup.recipe?.phases &&
              orderGroup.events && (
                <ProgressBar
                  phases={orderGroup.recipe.phases}
                  bgcolor="green"
                  currentPhase={determineCurrentPhase(orderGroup.events)}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default mobxify(OrderGroupCard, 'modalStore');
