import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import {
  NutritionSolutionDto,
  NutritionSolutionStore,
} from 'stores/nutritionSolutionStore.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface WSResponse {
  data: {
    _id: NutritionSolutionDto['_id'];
  };
}

export class NutritionSolutionGroupWebSocket {
  private nutritionSolutionStore: NutritionSolutionStore;
  private organizationId = '';

  constructor(
    nutritionSolutionStore: NutritionSolutionStore,
    organizationId: string,
  ) {
    this.nutritionSolutionStore = nutritionSolutionStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.NUTRITION_SOLUTION_GROUP}`,
        (...args: WebsocketMessage<WSResponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.DELETED) {
              this.handleNutritionSolutionGroupWebSocket();
            }

            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.handleNutritionSolutionGroupWebSocket();
            }

            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              this.handleNutritionSolutionGroupWebSocket();
            }
          }
        },
      );
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.NUTRITION_SOLUTION}`,
        (...args: WebsocketMessage<WSResponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.DELETED) {
              this.handleNutritionSolutionWebSocket();
            }

            if (obj.command === WEBSOCKETCOMMANDS.CREATED) {
              this.handleNutritionSolutionWebSocket();
            }

            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              this.handleNutritionSolutionWebSocket();
            }
          }
        },
      );
  }

  handleNutritionSolutionGroupWebSocket = () => {
    this.nutritionSolutionStore.getNutritionSolutionGroups();
  };

  handleNutritionSolutionWebSocket = () => {
    this.nutritionSolutionStore.getNutritionSolutionGroups();
    if (!this.nutritionSolutionStore.currentNutritionSolutionGroup?._id) return;

    this.nutritionSolutionStore.getNutritionGroupSolution(
      this.nutritionSolutionStore.currentNutritionSolutionGroup?._id,
    );
  };
}
