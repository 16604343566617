import { useEffect, useState } from 'react';
import { AnalyticsDataFilter } from 'models/AnalyticsDataType.model';

import { generateLines } from 'util/GraphUtil';

import analyticStore from 'stores/analytics.store';
import mobxify from 'util/hocs/mobxify';
import Graph, { Line, SeparatorLine } from '../../Graph/Graph';
import NoDataFound from '../../NoDataFound/NoDataFound';

const OrderModalGraphs = () => {
  const [data, setData] = useState<Line[]>([]);
  const [step, setStep] = useState<SeparatorLine[]>([]);

  const [filter, setFilter] = useState<Partial<AnalyticsDataFilter>>({
    bbchCode: false,
    height: false,
    diameter: false,
    weight: false,
    brix: false,
  });
  const analytics = analyticStore?.currentAnalytics;
  const analyticDataTypes = analyticStore?.analyticDataTypes;

  useEffect(() => {
    if (!analyticDataTypes) return;
    if (!analytics) return;

    // load datatypes and set initialshown to true if it is set to true
    const initialFilter: Partial<AnalyticsDataFilter> =
      analyticDataTypes.reduce((acc, curr) => {
        if (curr.showInDiagram) {
          acc[curr.key] = curr.initiallyShown || false;
        }
        return acc;
      }, filter);

    // delete a filter if it is not in the analyticData

    //   const analyticskeys: AnalyticsDataTypeKey[] =  analytics.map((analytic) =>
    //   Object.keys(analytic.data).map((key) => {
    //     if (key !== "plantAnalytics") return key;

    //     return analytic[key] as
    //   }).flat(),
    // )

    //   const allAnalyticsKeys = new Set<AnalyticsDataFilter>();

    // const tmp: Filter = {};
    // for (let analytic of analytics) {
    //   for (let a of Object.keys(analytic.data)) {
    //     if (a === "plantAnalyticsData") {
    //       tmp.bbchcode = false;
    //       tmp.heigth = false;
    //       tmp.diameter = false;
    //     }

    //     const type = getTypeForKey(analyticDataTypes, a);
    //     if (type.showInDiagram) {
    //       tmp[a] = type.initiallyShown || false;
    //     }
    //   }
    // }
    // setFilter(tmp);
    setFilter(initialFilter);
  }, [analytics?.length, analyticDataTypes?.length]);

  /*   useEffect(() => {
    //sets the filter to only show humidity, all other analytics data types that show in diagram
    //are put into the filter object but are set to false
    if (analyticDataTypes) {
      const tmp: any = {};
      if (analytics) {
        alert("EXPANSIVE");
        for (let analytic of analytics) {
          if (analytic) {
            for (let a of Object.keys(analytic.data)) {
              if (a === "plantAnalyticsData") {
                tmp["BBCH-Code"] = false;
                tmp.Height = false;
                tmp.Diameter = false;
              }

              const type = getTypeForKey(analyticDataTypes, a);
              if (type.showInDiagram) {
                tmp[a] = type.initiallyShown || false;
              }
            }
          }
        }
      }
      setFilter(tmp);
    }
  }, [analytics, analyticDataTypes]); */

  useEffect(() => {
    if (!analytics) return;
    if (!analyticDataTypes) return;
    const result = generateLines(analytics, analyticDataTypes);

    setStep(result.separator);
    setData(result.lines);
  }, [analytics, analyticDataTypes]);

  if (!data || data.length === 0)
    return (
      <div className="absolute top-0 right-0 left-0 bottom-0 z-50 w-full h-full flex justify-center items-center pointer-events-none mt-16">
        <div className="mb-24">
          <NoDataFound
            title="No analytics data"
            subtitle="No data could be found for this order."
          />
        </div>
        <div className="absolute bg-white opacity-40 w-full h-full"></div>
      </div>
    );

  return (
    <Graph
      filters={filter}
      onFilterChanged={(newFilter: any) => {
        setFilter(newFilter);
      }}
      lines={data}
      separationLines={step}
      analytics={analytics}
    />
  );
};

export default mobxify(OrderModalGraphs, 'analyticStore', 'orderStore');
