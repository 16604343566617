import { useFormContext } from 'react-hook-form';
import { PlantGroup } from 'models/PlantGroup.model';
import { ValidatedSelectLabel } from '../ValidatedSelectLabel';
interface OrderPlantGroupInputProps {
  options?: PlantGroup[];
}
const OrderPlantGroupInput = ({ options }: OrderPlantGroupInputProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <ValidatedSelectLabel
      valueName="plantGroup"
      options={options}
      label="Plant Group"
      hasError={errors.plantGroup != null}
      className="flex justify-between mt-5"
      inputClassName="w-3/4"
      maxHeight={150}
      rules={{ required: true }}
    />
  );
};

export default OrderPlantGroupInput;
