import { action, makeAutoObservable, observable } from 'mobx';
import ModalName from 'enums/ModalName.enum';
import { RecipeModalMode } from 'util/tabNames';

export class ModalStore {
  @observable currentModal: ModalName | null = null;
  @observable customData: any;
  @observable recipeModalMode: RecipeModalMode = RecipeModalMode.VIEW;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  openModal(modalName: ModalName, customData?: any) {
    this.currentModal = modalName;
    this.customData = customData;
  }

  @action
  closeModal() {
    this.currentModal = null;
    this.customData = null;
  }

  @action
  setRecipeModalMode(mode: RecipeModalMode) {
    this.recipeModalMode = mode;
  }
}

const modalStore = new ModalStore();
export default modalStore;
