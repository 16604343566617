import { ChevronRightIcon } from '@heroicons/react/24/outline';
import ActiveIndicator from 'components/ActiveIndicator/ActiveIndicator';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import SidebarHeader from 'components/SidebarHeader/SidebarHeader';
import dayjs from 'dayjs';
import ModalName from 'enums/ModalName.enum';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloudAppParams } from 'routes';
import nutritionSolutionStore, {
  NutritionSolutionGroupDto,
} from 'stores/nutritionSolutionStore.store';
import NutritionSolutionSidebarEmptyState from './NutritionSolutionSidebarEmptyState';
import mobxify from 'util/hocs/mobxify';

const NutritionSolutionSidebar = () => {
  const { farmId, nutritionSolutionGroupId } = useParams<CloudAppParams>();
  const baseUrl = `/${farmId}`;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      nutritionSolutionStore?.getNutritionSolutionGroups();
      setLoading(false);
    };
    load();
  }, []);

  const renderEntries = () => {
    return nutritionSolutionStore?.nutritionSolutionGroups.map(
      (solutionGroup: NutritionSolutionGroupDto, key: number) => (
        <li
          key={key}
          className="block hover:bg-gray-50 cursor-pointer"
          onClick={() => {
            navigate(`${baseUrl}/nutrition-solutions/${solutionGroup._id}`);
          }}
        >
          <div className="flex items-center justify-between px-4 py-4">
            <div className="block ">
              <div className="flex items-center flex-1">
                <div className="w-44">
                  <div className="truncate">
                    <div className="flex text-sm flex-col">
                      <a className="text-l mr-2 font-semibold truncate break-words">
                        {solutionGroup.name}
                      </a>
                      <p className="flex items-center text-xs text-gray-500">
                        Update on{' '}
                        {dayjs(solutionGroup.updatedAt).format('DD.MM.YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              {solutionGroup._id === nutritionSolutionGroupId && (
                <ActiveIndicator />
              )}
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
      ),
    );
  };

  const renderContent = () => (
    <div className="h-screen overflow-auto pb-32">
      {loading && <LoadingScreen />}
      {!loading &&
        (nutritionSolutionStore?.nutritionSolutionGroups.length === 0 ? (
          <NutritionSolutionSidebarEmptyState />
        ) : (
          <ul className="divide-y divide-gray-200">{renderEntries()}</ul>
        ))}
    </div>
  );

  return (
    <div>
      <SidebarHeader
        title="Nutrition Solutions"
        modalName={ModalName.NUTRITION_SOLUTION_GROUP}
        testid="addnutrition"
        showAddButton={true}
      />
      {renderContent()}
    </div>
  );
};

export default mobxify(NutritionSolutionSidebar, 'nutritionSolutionStore');
