import { XMarkIcon } from '@heroicons/react/24/outline';

interface PlantRecipeFilterProps {
  plant: string;
  plantFilter: string[];
  setPlantFilter: React.Dispatch<React.SetStateAction<string[]>>;
}
const CurrentOrderGroupFilter = ({
  plant,
  plantFilter,
  setPlantFilter,
}: PlantRecipeFilterProps) => {
  const containPlant = () => {
    for (const plantInFilter of plantFilter) {
      if (plantInFilter === plant) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="pt-2">
      <span
        onClick={() => {
          if (containPlant()) {
            for (let i = 0; i < plantFilter.length; i++) {
              if (plantFilter[i] === plant) {
                plantFilter.splice(i, 1);
              }
            }
          } else {
            plantFilter.push(plant);
          }
          setPlantFilter([...plantFilter]);
        }}
        className={
          containPlant()
            ? 'bg-green-600 shadow-sm text-white text-xs font-semibold px-3 mx-1 py-1 rounded  hover:bg-green-800 hover:cursor-pointer hover:shadow-lg flex justify-center items-center'
            : 'bg-gray-200 shadow-sm text-gray-800 text-xs font-semibold px-3 mx-1 py-1 rounded  hover:bg-green-600 hover:cursor-pointer hover:shadow-lg hover:text-white'
        }
      >
        {plant}
        {containPlant() && <XMarkIcon className="ml-1 w-4 h-4" />}
      </span>
    </div>
  );
};

export default CurrentOrderGroupFilter;
