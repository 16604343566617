import { RecipeModalMode } from 'util/tabNames';

export const showAll = (allChecked: boolean, handleClick: () => void) => {
  if (allChecked) {
    return (
      <button
        className="w-6 h-6 right-16 top-1/2 bottom-1/2 absolute mx-auto my-auto text-gray-300 font-semibold rounded-md hover:text-gray-400 hover:cursor-pointer"
        onClick={handleClick}
      >
        All
      </button>
    );
  }
};

export const allowFullEdit = (currentMode: RecipeModalMode) => {
  if (currentMode === RecipeModalMode.CREATE) return true;
  if (currentMode === RecipeModalMode.DUPLICATE) return true;
  return false;
};
