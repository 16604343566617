import { useFormContext } from 'react-hook-form';
import { RecipeGroup } from 'models/RecipeGroup.model';
import { showPhasePreview } from '../../CreateOrderModal/CreateOrderUtils';
import { ValidatedSelectLabel } from '../ValidatedSelectLabel';
interface StartPhaseInputProps {
  options: { id: number; name: string }[];
  currentRecipeGroup?: RecipeGroup;
}
const OrderStartphaseInput = ({
  options,
  currentRecipeGroup,
}: StartPhaseInputProps) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const phaseToShow = () => {
    if (!currentRecipeGroup?.selected) return;
    if (!watch('startPhase')) return;
    if (typeof currentRecipeGroup.selected === 'string') return;

    const phase = currentRecipeGroup.selected.phases[watch('startPhase').id];

    return phase;
  };

  return (
    <>
      {currentRecipeGroup && (
        <>
          <ValidatedSelectLabel
            valueName="startPhase"
            options={options}
            label="Start Phase (optional)"
            hasError={errors.startPhase != null}
            className="flex justify-between mt-2"
            inputClassName="w-3/4"
            maxHeight={150}
            rules={{ required: false }}
          />{' '}
          {showPhasePreview(phaseToShow())}
        </>
      )}
    </>
  );
};

export default OrderStartphaseInput;
