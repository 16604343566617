import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import analyticStore from 'stores/analytics.store';
import eventStore from 'stores/event.store';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';
import NoDataFound from '../../../NoDataFound/NoDataFound';
import AnalyticsPanel from './AnalyticsPanel';
import CommentInput from './CommentInput';
import { DEFAULT_FILTERS } from './Defaultfilters';
import EventCluster from './EventCluster';
import EventFilter from './EventFilter';
import {
  isValidEvent,
  mergeCommentsIntoEvents,
  noneChecked,
} from './EventUtils';
import withLoader from 'util/hocs/withLoader';

export interface Filter {
  selected: boolean;
  label: string;
  value: string;
  color?: string;
  offColor?: string;
}

const Events: FC = () => {
  const [selectedFilter, setSelectedFilter] = useState<Filter[]>(
    DEFAULT_FILTERS.map((filter) => ({ selected: true, ...filter })),
  );
  const [selectedEventId, setSelectedEventId] = useState('');

  const events = useMemo(() => {
    if (eventStore?.events && orderStore?.comments) {
      const mergedComments = mergeCommentsIntoEvents(
        eventStore?.events,
        orderStore?.comments,
      );
      if (mergedComments) {
        return mergedComments;
      }
    }
  }, [orderStore.comments]);

  const selectedEvent = useMemo(() => {
    if (!events) return;
    return events.filter((e) => e._id === selectedEventId)[0];
  }, [events, selectedEventId]);

  const clusteredEvents = useMemo(() => {
    const clusters = [
      [
        {
          timestamp: new Date('1981-04-05'),
          _id: '',
          name: '',
          order: '',
          data: { commentId: '' },
        },
      ],
    ];
    events?.forEach((event) => {
      let foundCluster = false;
      for (let i = 0; i < clusters.length; i++) {
        const cluster = clusters[i];
        if (
          dayjs(cluster[0].timestamp).format('DD/MM/YY') ===
          dayjs(event.timestamp).format('DD/MM/YY')
        ) {
          clusters[i].push(event);
          foundCluster = true;
        }
      }
      if (!foundCluster) {
        clusters.push([event]);
      }
    });
    clusters.shift();
    return clusters;
  }, [events]);

  const currentAnalytic = useMemo(() => {
    if (!analyticStore?.currentAnalytic) return;
    return analyticStore?.currentAnalytic;
  }, [analyticStore?.currentAnalytic]);

  useEffect(() => {
    if (!selectedEvent?.timestamp) return;
    if (!orderStore?.currentOrder?._id) return;

    analyticStore?.getAnalyticByTimeStamp(
      orderStore?.currentOrder?._id,
      selectedEvent?.timestamp,
    );
  }, [selectedEvent]);

  useEffect(() => {
    if (noneChecked(selectedFilter)) {
      setSelectedFilter(
        selectedFilter.map((obj) => {
          return { ...obj, selected: true };
        }),
      );
    }
    const searchTermArray = selectedFilter.filter((filter) => filter.selected);

    if (!orderStore?.currentOrder?._id) return;

    eventStore?.getEvents(
      orderStore?.currentOrder?._id,
      searchTermArray.map((object) => object.value),
    );
  }, [selectedFilter]);

  useEffect(() => {
    if (!orderStore?.currentOrder?._id) return;

    orderStore?.getComments(orderStore?.currentOrder?._id);
  }, [eventStore?.events]);

  const handleShowEvent = (event: string) => {
    setSelectedEventId(event);
  };

  const handleFilterChange = (filter: Filter) => {
    const allChecked = (arr: Filter[]) => {
      return arr.every((element) => element.selected === true);
    };

    if (allChecked(selectedFilter)) {
      setSelectedFilter(
        selectedFilter.map((obj) => {
          if (obj.label !== filter.label) {
            return { ...obj, selected: false };
          }
          return obj;
        }),
      );
    } else {
      setSelectedFilter(
        selectedFilter.map((obj) => {
          if (obj.label === filter.label) {
            return { ...obj, selected: !obj.selected };
          }
          return obj;
        }),
      );
    }
  };

  const selectAllFilters = () => {
    setSelectedFilter(
      selectedFilter.map((obj) => {
        return { ...obj, selected: true };
      }),
    );
  };

  if (!orderStore?.currentOrder?._id) return null;

  return (
    <div className="flex h-full mt-8">
      <div className="w-1/2">
        <CommentInput orderid={orderStore?.currentOrder?._id} />
        <EventFilter
          selectAllFilters={selectAllFilters}
          selectedFilter={selectedFilter}
          handleFilterChange={handleFilterChange}
        />
        <div className="flow-root overflow-auto h-full">
          {clusteredEvents.map((cluster) => (
            <EventCluster
              key={'cluster-' + cluster[0]._id}
              cluster={cluster}
              handleShowEvent={handleShowEvent}
            />
          ))}
        </div>
      </div>

      <div
        className={`w-1/2 h-full flex justify-center pt-6 ${
          isValidEvent(selectedEvent) ? 'items-start' : 'items-center'
        }`}
      >
        {!isValidEvent(selectedEvent) && (
          <NoDataFound
            title="No Analytics Found"
            subtitle="Select an analytics event to show details here."
            className="mt-32"
          />
        )}
        {isValidEvent(selectedEvent) && (
          <AnalyticsPanel analytic={currentAnalytic} />
        )}
      </div>
    </div>
  );
};

export default withLoader(
  mobxify(Events, 'analyticStore', 'eventStore', 'orderStore'),
);
