import { RecipeModalMode } from 'util/tabNames';
import { ValidatedInputLabeled } from '../../../InputValidation/ValidatedInputLabel';
import { allowFullEdit } from '../RecipeFormUtils';

interface RecipeNameInputProps {
  mode: RecipeModalMode;
}
const RecipeNameInput = ({ mode }: RecipeNameInputProps) => {
  return (
    <ValidatedInputLabeled
      key="field_recipeName"
      valueName="recipeName"
      defaultValue={''}
      label="Recipe Name"
      rules={{ required: true, maxLength: 50 }}
      className="flex justify-between"
      inputClassName="w-3/4"
      isDisabled={!allowFullEdit(mode || RecipeModalMode.VIEW)}
    />
  );
};
export default RecipeNameInput;
