import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactChild } from 'react';
import { UseFormReturn } from 'react-hook-form';
import ModalName from 'enums/ModalName.enum';
import modalStore, { ModalStore } from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

interface GenericModalProps {
  modalName: ModalName;
  children: ReactChild;
  customContainerStyle?: any;
  formMethods?: UseFormReturn<any, any>;
  modalStore?: ModalStore;
}

const GenericModal = ({
  modalName,
  children,
  customContainerStyle,
  formMethods,
}: GenericModalProps) => {
  const containerStyle = classNames(
    customContainerStyle ? customContainerStyle : 'max-w-lg w-full',
    'bg-white inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle',
  );

  if (modalName !== modalStore?.currentModal) {
    return <></>;
  }

  return (
    <Transition.Root
      show={modalName === modalStore?.currentModal}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto "
        onClose={() => {
          formMethods && formMethods.reset();
          modalStore?.closeModal();
        }}
      >
        <div className="min-h-screen px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={containerStyle}>
              <div className="hidden sm:block h-full">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default mobxify(GenericModal, 'modalStore');
