import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { ComponentType, Suspense } from 'react';
import { Await, useLoaderData } from 'react-router';
import ErrorBoundary from 'util/ErrorBoundary';

export default function withLoader(
  Component: ComponentType,
  FallbackView?: ComponentType,
  ErrorView?: ComponentType,
) {
  function WithSuspense() {
    if (!Component) return null;
    const { promise } = useLoaderData() as { promise: Promise<boolean> };

    return (
      <Suspense fallback={FallbackView ? <FallbackView /> : <LoadingScreen />}>
        <Await
          errorElement={ErrorView ? <ErrorView /> : <ErrorBoundary />}
          resolve={promise}
        >
          <Component />
        </Await>
      </Suspense>
    );
  }
  return WithSuspense;
}
