import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC } from 'react';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';
import { recipeModalTabKey, recipeModalTabNames } from 'util/tabNames';
import useQuerySetter from 'util/useQuerySetter';

interface Props {
  hideCategories?: boolean;
}

const RecipeGroupModalHeader: FC<Props> = ({ hideCategories }) => {
  const { deleteQueries, query, setQuery } = useQuerySetter();
  const tabName = query.get(recipeModalTabKey);

  const closeModal = () => {
    deleteQueries(['recipeGroup', recipeModalTabKey, 'recipe']);
    modalStore?.closeModal();
  };

  return (
    <div>
      {!hideCategories && (
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
          >
            <option>General</option>

            <option>Compare Versions</option>
          </select>
        </div>
      )}

      <div className="hidden sm:block">
        <div className="border-b border-gray-200  flex justify-between pl-2 pr-6">
          <>
            <nav
              className={`flex space-x-1 ${
                hideCategories ? 'opacity-0' : 'opacity-100'
              }`}
              aria-label="Tabs"
            >
              {recipeModalTabNames.map((tab) => {
                const navLinkStyle = classNames({
                  'border-transparent cursor-pointer text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-5 border-b-2 font-medium text-sm':
                    true,
                  'border-green-500 hover:text-green-500 text-green-500 hover:border-green-500 cursor-default whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm':
                    tab.value.includes(tabName || ''),
                });

                return (
                  <div
                    key={tab.name}
                    className={navLinkStyle}
                    onClick={() => {
                      setQuery(
                        [{ term: recipeModalTabKey, value: tab.value }],
                        {
                          onlyReplaceStatedTerms: true,
                        },
                      );
                    }}
                  >
                    {tab.name}
                  </div>
                );
              })}
            </nav>
          </>
          <button
            type="button"
            name="close"
            className="bg-white  rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={() => {
              closeModal();
            }}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default mobxify(RecipeGroupModalHeader, 'modalStore');
