import { Plant } from 'models/Plant.model';

export const getPlantImageFromRecipe = (
  recipePlants: string[],
  plants: Plant[],
) => {
  const plant = plants.find((plant) => recipePlants.includes(plant._id));
  if (plant) {
    return plant.imageURL;
  }
  return '';
};
