import { Phase } from 'models/Phase.model';
import { Plant } from 'models/Plant.model';
import { PlantGroup } from 'models/PlantGroup.model';
import { RecipeGroup } from 'models/RecipeGroup.model';
export interface onSubmitDto {
  amount: number;
  recipe?: string;
  recipeGroup: RecipeGroup;
  plantGroup: { _id: string; name: string };
  plant: {
    _id: string;
    name: string;
  };
  target: string;
  description?: string;
  startDate: Date;
  startPhase?: { id: number; name: string };
}

export interface CreateOrderFormInterface {
  target: string;
  description?: string;
  startDate: Date;
  plantGroup: PlantGroup;
  plant: Plant;
  recipeGroup: RecipeGroup;
  amount: number;
  startPhase?: { id: number; name: string };
}
export const showPhasePreview = (phase?: Phase) => {
  if (!phase) return null;

  const duration = (phase.duration / 1000 / 60 / 60 / 24).toFixed(1);
  return (
    <div className="shadow-md w-30 h-30 my-4 rounded-md border-gray-300 border text-gray-700">
      <div className="grid grid-cols-3 gap-2 p-2">
        <div className="flex flex-col justify-center items-center">
          <p className="font-semibold">Humidity</p>
          <p>{phase.humidity + '%'}</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="font-semibold">Temperature</p>
          <p>{phase.environmentTemperature + '°C'}</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="font-semibold">Duration</p>
          <p>{duration + ' days'}</p>
        </div>
      </div>
    </div>
  );
};

export const isObjectEmpty = (object?: object) => {
  if (!object) return true;
  if (Object.keys(object).length === 0) return true;
  return false;
};

export const addVersionToRGs = (recipeGroups?: RecipeGroup[]) => {
  if (!recipeGroups) return [];
  return recipeGroups
    .map((rg) => {
      if (typeof rg.selected !== 'object') return { ...rg };
      return { ...rg, name: rg.name + ' (V' + rg.selected.version + ')' };
    })
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
};

export const getAmountOfPlantsPerTray = (currentRecipeGroup?: RecipeGroup) => {
  if (!currentRecipeGroup) {
    return 0;
  }
  if (typeof currentRecipeGroup.selected !== 'object') {
    return 0;
  }
  return currentRecipeGroup.selected.phases[0].plantsPerTray;
};
