interface Props {
  sideBar: any;
  children: any;
}

const SideLayout = ({ sideBar, children }: Props) => {
  return (
    <div className="flex border-r min-h-full">
      <div className="flex flex-col border-r h-full min-w-[16rem] max-w-[16rem]">
        {sideBar}
      </div>
      <div className="bg-gray-100 flex-grow heigth-screen flex justify-center items-center">
        {children}
      </div>
    </div>
  );
};

export default SideLayout;
