import { useFormContext } from 'react-hook-form';
import { RecipeModalMode } from 'util/tabNames';
import { ValidatedInputLabelWithUnits } from '../../../InputValidation/ValidatedInputLabelWithUnits';
import { useEffect } from 'react';

interface AnalyticIntervalInputProps {
  index: number;
  mode: RecipeModalMode;
}
const AnalyticIntervalInput = ({ index, mode }: AnalyticIntervalInputProps) => {
  const { watch, resetField } = useFormContext();

  const isDisabled = watch(`phases.${index}.analyticIntervalDisabled`);
  useEffect(() => {
    if (!isDisabled) return;
    resetField(`phases.${index}.analyticInterval`);
  }, [isDisabled]);

  return (
    <ValidatedInputLabelWithUnits
      valueName="analyticInterval"
      label="Analytic Interval"
      placeholder={isDisabled ? '' : '7'}
      unit="days"
      fieldArrayName="phases"
      isDisabled={mode === RecipeModalMode.VIEW || isDisabled}
      index={index}
      rules={{
        required: !isDisabled,
      }}
      isNumber={!isDisabled}
      className="flex justify-between mt-4 items-center"
      inputClassName="w-3/4"
    />
  );
};

export default AnalyticIntervalInput;
