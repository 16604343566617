import { useState } from 'react';
import ModalName from '../../../enums/ModalName.enum';
import GenericModal from '../GenericModal';
import plantStore from 'stores/plant.store';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

const CreatePlantGroupModal = () => {
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState<boolean>(false);

  const savePlant = async () => {
    setDisabled(true);
    await plantStore?.createPlantGroup(name);
    setDisabled(false);
    modalStore?.closeModal();
  };

  const canSafe = () => {
    return name.length > 0;
  };
  return (
    <GenericModal
      modalName={ModalName.CREATE_PLANTGROUP}
      customContainerStyle="container-max-height max-w-lg w-full bg-white inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-6"
    >
      <div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Create Plant Group
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Use this form to create a plant group.
          </p>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-6">
          <label
            htmlFor="plant-name"
            className="flex text-sm font-medium text-gray-700 items-center h-full"
          >
            Plant Group Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="plant-name"
              id="plant-name"
              minLength={3}
              onChange={(event) => {
                setName(event.target.value);
              }}
              autoComplete="given-name"
              className="w-full border focus:outline-none focus:border-green-600 border-gray-300  rounded-md shadow-sm py-2 px-3 sm:text-sm b"
            />
          </div>
        </div>
        <div className="pt-5 ">
          <div className="flex justify-end">
            <button
              onClick={() => {
                setName('');
                modalStore?.closeModal();
              }}
              name="cancel"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            {canSafe() && (
              <button
                disabled={disabled}
                onClick={() => {
                  savePlant();
                }}
                type="submit"
                className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                  disabled && 'cursor-wait'
                }`}
              >
                Save
              </button>
            )}
            {!canSafe() && (
              <button className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 opacity-50 cursor-not-allowed">
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </GenericModal>
  );
};

export default mobxify(CreatePlantGroupModal, 'plantStore', 'modalStore');
