import { Tailwindest } from 'tailwindest';
interface GridProps {
  children: React.ReactNode | React.ReactNode[];
  scale?: 'sm' | 'md' | 'lg' | 'responsive';
  gap?: `gap-${number}`;
  padding?: `p-${number}` | Tailwindest;
  maxHeight?: `max-h-[${number}rem]`;
  bottomPadding?: `pb-${number}`;
}

const Grid = ({
  children,
  scale = 'responsive',
  gap = 'gap-4',
  padding = 'p-5',
  maxHeight,
}: GridProps) => {
  const colVariants = {
    sm: 'sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 auto-rows-[12rem]',
    md: 'md:grid-cols-3 2xl:grid-cols-4 auto-rows-[12rem]',
    lg: 'md:grid-cols-2 2xl:grid-cols-4 auto-rows-[20rem]',
    responsive: `sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 auto-rows-[1fr] auto-cols-[1fr]`,
  };

  return (
    <ul
      className={`grid relative z-10 w-full ${colVariants[scale]} ${padding} ${gap} ${maxHeight}`}
    >
      {children}
    </ul>
  );
};

export default Grid;
