import { OrderFilter } from 'stores/order.store';

const checkOnObj = (data: any, key: string) => {
  return (
    typeof data[key] === 'object' &&
    !Array.isArray(data[key] && data[key] !== null)
  );
};

const getQueryStringFromFilters = (filters: OrderFilter) => {
  let queryString = `order-groups/filtered?skip=${filters.skip}&limit=${
    filters.limit
  }&archive=${filters.showArchive.toString()}`;
  if (filters.orderGroups && filters.orderGroups.length > 0) {
    queryString = queryString + `&orderGroups=${filters.orderGroups.join(',')}`;
  }
  if (filters.search) {
    queryString = queryString + `&search=${filters.search}`;
  }
  if (filters.dateFrom) {
    queryString = queryString + `&dateFrom=${filters.dateFrom.toISOString()}`;
  }
  if (filters.dateTo) {
    queryString = queryString + `&dateTo=${filters.dateTo.toISOString()}`;
  }
  if (filters.plantGroup) {
    queryString = queryString + `&plantGroup=${filters.plantGroup}`;
  }
  if (filters.plants && filters.plants.length > 0) {
    queryString = queryString + `&plants=${filters.plants.join(',')}`;

    queryString = queryString.slice(0, -1);
  }
  return queryString;
};

const generateFormPhases = (phaseLength: number, data: any) => {
  const formPhases = [{}];
  for (let currentPhase = 0; currentPhase < phaseLength; currentPhase++) {
    for (const key of Object.keys(data)) {
      if (key === 'recipeName' || !data[key] || data[key] === '') {
        continue;
      }
      if (key.includes('_' + (currentPhase + 1))) {
        const newKey = key.split('_')[0];
        let value = data[key];
        if (checkOnObj(data, key) && data[key]) {
          value = data[key].label;
        }

        formPhases[currentPhase] = {
          ...formPhases[currentPhase],
          ...{ [newKey]: value },
        };

        if (newKey === 'analyticIntervalDisabled' && data[key]) {
          //@ts-ignore
          delete formPhases[currentPhase]['analyticInterval'];
        }
      }
    }
  }
  return formPhases;
};
export { checkOnObj, generateFormPhases, getQueryStringFromFilters };
