import { useEffect } from 'react';
import typeStore from 'stores/type.store';
import { RecipeModalMode } from 'util/tabNames';
import { ValidatedCreatableLabel } from '../../InputValidation/ValidatedCreatableLabel';
import mobxify from 'util/hocs/mobxify';

interface SoilTypeCreatableProps {
  index: number;
  control: any;
  watch: any;
  mode: RecipeModalMode;
  hasError: boolean;
}

const SoilTypeCreatable = ({
  index,
  mode,
  hasError,
}: SoilTypeCreatableProps) => {
  useEffect(() => {
    typeStore?.loadLightTypes();
  }, []);

  const handleCreateLight = async (inputValue: string) => {
    await typeStore?.createLightType({
      label: inputValue,
      value: inputValue.toLowerCase(),
    });
    await typeStore?.loadLightTypes();
  };

  return (
    <ValidatedCreatableLabel
      key={`creatable_${index}`}
      valueName="lightType"
      label="Light"
      inputClassName="w-3/4"
      className="flex justify-between mt-4"
      hasError={hasError}
      rules={{ required: true }}
      fieldArrayName="phases"
      index={index}
      onCreate={handleCreateLight}
      options={typeStore?.lightTypes}
      isDisabled={mode === RecipeModalMode.VIEW}
    />
  );
};

export default mobxify(SoilTypeCreatable, 'typeStore');
