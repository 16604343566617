import { Switch } from '@headlessui/react';
import { FC } from 'react';

interface Props {
  value: boolean;
  setValue: (value: boolean) => void;
  label?: string;
}

const Toggle: FC<Props> = ({ value, setValue, label = '' }) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={value}
        onChange={() => setValue(!value)}
        className={
          value
            ? 'bg-green-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
            : 'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
        }
      >
        <span
          aria-hidden="true"
          className={
            value
              ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              : 'translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          }
        />
      </Switch>
      <Switch.Label as="span" className="ml-2 mr-6">
        <span className="text-sm font-medium text-gray-900">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default Toggle;
