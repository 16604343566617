import ListCard from './ListCard';
import { Plant } from 'models/Plant.model';
import ModalName from 'enums/ModalName.enum';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

interface PlantListCardProps {
  plantGroupId: string;
  plant: Plant;
}
const PlantListCard = ({ plantGroupId, plant }: PlantListCardProps) => {
  return (
    <ListCard key={plant._id}>
      <div
        className={`h-full w-full flex justify-center items-center relative cursor-pointer ${
          plant.imageURL ? 'bg-gray-800' : 'bg-gray-400'
        }`}
        onClick={() => {
          modalStore?.openModal(ModalName.PLANT_FORM, {
            mode: 'edit',
            plantGroupId,
            plantId: plant._id,
            name: plant.name,
            expectedDiameter: plant.expectedDiameter,
            imageURL: plant.imageURL,
          });
        }}
      >
        {plant.imageURL && (
          <>
            <img
              className="h-full w-full r"
              style={{ objectFit: 'cover', opacity: 0.8 }}
              src={plant.imageURL}
            />
          </>
        )}
        <h3
          style={{ textShadow: '1px 1px 1px black' }}
          className="text-white font-bold absolute text-lg p-4"
        >
          {plant.name.toUpperCase()}
        </h3>
      </div>
    </ListCard>
  );
};

export default mobxify(PlantListCard, 'modalStore', 'recipeStore');
