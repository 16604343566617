import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ModalName from 'enums/ModalName.enum';
import GenericModal from '../GenericModal';
import { ValidatedInputLabeled } from '../InputValidation/ValidatedInputLabel';
import { ValidatedInputLabelWithUnits } from '../InputValidation/ValidatedInputLabelWithUnits';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { MixtureInput } from './MixtureInput';
import { useEffect, useLayoutEffect, useState } from 'react';
import { CloudAppParams } from 'routes';
import modalStore from 'stores/modal.store';
import nutritionSolutionStore from 'stores/nutritionSolutionStore.store';
import mobxify from 'util/hocs/mobxify';

const NutritionSolutionModal = () => {
  const duplicateMode = !!modalStore?.customData;
  useEffect(() => {
    if (!modalStore?.customData) return;
    if (modalStore?.currentModal !== ModalName.NUTRITION_SOLUTION) return;
    methods.setValue('ec', modalStore.customData.ec);
    methods.setValue('ph', modalStore.customData.ph);
    methods.setValue('comment', modalStore.customData.comment);
    methods.setValue('phMinus', modalStore.customData.phMinus);
    methods.setValue('waterLevel', modalStore.customData.waterLevel);
    methods.setValue('mixtures', modalStore.customData.amount);
  }, [modalStore?.customData, modalStore?.currentModal]);

  useLayoutEffect(() => {
    return () => {
      methods.reset();
    };
  }, []);

  const [disabled, setDisabled] = useState<boolean>(false);

  const { nutritionSolutionGroupId } = useParams<CloudAppParams>();
  const methods = useForm<FieldValues>({
    defaultValues: {
      comment: '',
      ec: undefined,
      mixtures: [],
      ph: undefined,
      phMinus: undefined,
      waterLevel: undefined,
    },
  });
  const control = methods.control;
  const { fields, append } = useFieldArray({ control, name: 'mixtures' });
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!nutritionSolutionGroupId) return;
    setDisabled(true);
    await nutritionSolutionStore?.createNutritionSolution(
      nutritionSolutionGroupId,
      {
        ec: data.ec,
        ph: data.ph,
        amount: data.mixtures,
        comment: data.comment,
        phMinus: data.phMinus,
        waterLevel: data.waterLevel,
      },
    );
    setDisabled(false);
    methods.reset();
    modalStore?.closeModal();
  };

  return (
    <GenericModal
      modalName={ModalName.NUTRITION_SOLUTION}
      customContainerStyle="overflow-hidden p-5 w-1/2"
      formMethods={methods}
    >
      <FormProvider {...methods}>
        <form
          id="nutritionSolutionModal"
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <div className="flex justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                {duplicateMode
                  ? 'Duplicate and adapt Nutrition Solution'
                  : 'Create Nutrition Solution'}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {duplicateMode
                  ? 'Use this form to create a new nutrition solution based on this one.'
                  : 'Use this form to create a new nutrition solution.'}
              </p>
            </div>
            <button
              type="button"
              name="close"
              className="bg-white  rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={(e) => {
                e.preventDefault();
                methods.reset();
                modalStore?.closeModal();
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:border-t sm:border-gray-200 sm:pt-3 mt-3 ">
            <ValidatedInputLabelWithUnits
              isNumber
              className="justify-between w-full"
              label="EC"
              inputClassName="flex-grow"
              rules={{
                required: true,
                pattern: /(\d*\.)?\d+/,
                min: 0,
              }}
              valueName="ec"
              unit="mS/cm"
              placeholder="2"
            />
            <ValidatedInputLabeled
              className="justify-between w-full mt-3"
              inputClassName="flex-grow"
              label="PH"
              isNumber
              rules={{
                required: true,
                pattern: /(\d*\.)?\d+/,
                min: 0,
                max: 14,
              }}
              valueName="ph"
              placeholder="6"
            />
            <ValidatedInputLabeled
              className="justify-between w-full mt-3"
              inputClassName="flex-grow"
              label="Comment"
              rules={{ required: false }}
              valueName="comment"
              placeholder="type your comment here"
            />
          </div>

          <div className="sm:border-t sm:border-gray-200 sm:pt-5 mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-700 mb-6 justify-center justify-items-center">
              Water
            </h3>
            <ValidatedInputLabelWithUnits
              className="justify-between w-full mb-3"
              inputClassName="flex-grow"
              label="Waterlevel"
              placeholder="30"
              isNumber
              unit="mm"
              rules={{
                required: true,
                pattern: /(\d*\.)?\d+/,
              }}
              valueName="waterLevel"
            />
            <div className="flex justify-between border-t mt-5 pt-5 pb-3 items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-700 mb-6 justify-center items-center">
                Nutrition
              </h3>
              <button
                type="button"
                onClick={() => {
                  append({ name: '', value: 0 });
                }}
                className="inline-flex items-center px-12 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 h-10"
              >
                Add Solution
              </button>
            </div>
            <div>
              <ValidatedInputLabelWithUnits
                className="justify-between w-full mb-3"
                inputClassName="flex-grow"
                label="pH Minus"
                isNumber
                placeholder="3"
                unit="ml"
                rules={{
                  required: true,
                  pattern: /(\d*\.)?\d+/,
                }}
                valueName="phMinus"
              />
              {fields.map((mixture, index) => (
                <MixtureInput key={index} index={index} field={mixture} />
              ))}
            </div>
          </div>
          <div className="pt-3 mt-3 border-t">
            <div className="flex justify-end">
              {modalStore?.customData && (
                <button
                  onClick={() => {
                    modalStore?.openModal(
                      ModalName.DELETE_NUTRITION_SOLUTION,
                      modalStore?.customData,
                    );
                  }}
                  name="delete"
                  className="ml-3 inline-flex items-center px-12 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Delete
                </button>
              )}
              <button
                disabled={disabled}
                type="submit"
                className={`ml-3 inline-flex items-center px-12 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                  disabled && 'cursor-wait'
                }`}
              >
                {modalStore?.customData
                  ? 'Duplicate Nutrition Solution'
                  : 'Create Nutrition Solution'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
};

export default mobxify(
  NutritionSolutionModal,
  'modalStore',
  'nutritionSolutionStore',
);
