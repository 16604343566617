import { OrderGroupModel } from 'models/Order.model';
import logger from 'util/logger';

//replace this with whatever stock image you deem appropriate
const placeholderimage = process.env.REACT_APP_PLACEHOLDER_IMAGE;

export const getFullRecipeDuration = (ordergroup?: OrderGroupModel) => {
  if (!ordergroup) return 0;
  let fullDuration = 0;
  ordergroup.recipe?.phases.forEach((phase: any) => {
    fullDuration += phase.duration;
  });
  return fullDuration / (1000 * 3600 * 24);
};

export const determineCurrentPhase = (events: Array<any>) => {
  //Take all events with the name "PERIOD_STARTED" from the ordergroup's events
  const PhaseEventArray = [1];
  events.forEach((element) => {
    if (element.name === 'PERIOD_STARTED') {
      PhaseEventArray.push(element.data.period.match(/\d+/)[0]);
    }
  });
  //Find the one with the highest number in the data.period
  return Math.max(...PhaseEventArray);
};

export const determineCurrentDay = (orderGroup: any) => {
  const day1 = new Date(orderGroup.orders[0].startDate).getTime();
  const now = new Date().getTime();

  const timediff = now - day1;

  let daysPassed = timediff / (1000 * 3600 * 24);
  const recipeMax = getFullRecipeDuration(orderGroup);
  if (daysPassed > recipeMax) {
    daysPassed = recipeMax;
  }
  return Math.round(daysPassed);
};

export const getImage = (orderGroup: any) => {
  try {
    if (orderGroup.latestImage) {
      return orderGroup.latestImage;
    }
    if (!orderGroup.analytics || orderGroup.analytics.length === 0) {
      return placeholderimage;
    }

    const analytics = orderGroup.analytics;
    const mostRecentImage = { createdAt: 0, image: placeholderimage };

    for (let i = 0; i < analytics.length; i++) {
      const analyticsDate = new Date(analytics[i].data.date).getTime();
      if (analyticsDate > mostRecentImage.createdAt) {
        if (analytics[i].data.rootImages?.length > 0) {
          mostRecentImage.image = analytics[i].data.rootImages[0].low;
          mostRecentImage.createdAt = analyticsDate;
        }
        if (analytics[i].data.trayImages?.length > 0) {
          mostRecentImage.image = analytics[i].data.trayImages[0].low;
          mostRecentImage.createdAt = analyticsDate;
        }
        if (analytics[i].data.detailedImages?.length > 0) {
          mostRecentImage.image = analytics[i].data.detailedImages[0].low;
          mostRecentImage.createdAt = analyticsDate;
        }
      }
    }
    return mostRecentImage.image;
  } catch (error) {
    logger.error(error);
  }
};

export const countDistinctPlants = (orderGroups: Array<any>) => {
  const results: Array<any> = [];
  orderGroups.forEach((order) => {
    if (
      results.some((res: any) => {
        return res['plant'] == order['plant'];
      })
    ) {
      results.forEach((result: any) => {
        if (result['plant'] === order['plant']) {
          result['occurrence']++;
          result['amount'] += order.orders['amount'];
        }
      });
    } else {
      const a: any = {};
      a['plant'] = order['plant'];
      a['occurrence'] = 1;
      a['amount'] = order.orders['amount'];
      results.push(a);
    }
  });
  return results;
};

export interface OrderGroupSortingOption {
  value: 'first' | 'last' | 'daysLeft';
  label: string;
}

export const sortOrderGroups = (
  a: OrderGroupModel,
  b: OrderGroupModel,
  sort: OrderGroupSortingOption,
) => {
  switch (sort.value) {
    case 'first':
      return (
        Number(b.uuid.replace(/\D/g, '')) - Number(a.uuid.replace(/\D/g, ''))
      );
    case 'last':
      return (
        Number(a.uuid.replace(/\D/g, '')) - Number(b.uuid.replace(/\D/g, ''))
      );
    case 'daysLeft':
      return (
        getFullRecipeDuration(a) -
        determineCurrentDay(a) -
        (getFullRecipeDuration(b) - determineCurrentDay(b))
      );
    default:
      return -1;
  }
};
