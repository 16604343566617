import { Farm } from 'models/NavbarItem.model';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { CloudAppParams } from 'routes';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';

interface Tab {
  name: string;
  href: string;
}

const navLinkClasses = ({ isActive }: { isActive: boolean }) =>
  `cursor-pointer p-2 ${
    isActive && 'border-b-2 border-green-600 font-semibold'
  }`;

const getTabs = (farm: Farm['_id']): Tab[] => [
  {
    name: 'Analytics',
    href: `/${farm}/order-details/${orderStore.currentOrderGroup?._id}/order/${orderStore.currentOrder?._id}/analytics`,
  },
  {
    name: 'Events',
    href: `/${farm}/order-details/${orderStore.currentOrderGroup?._id}/order/${orderStore.currentOrder?._id}/events`,
  },
];

const OrderChildTaps = () => {
  const { farmId } = useParams<CloudAppParams>();

  if (!farmId) return null;

  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {getTabs(farmId).map((tab) => (
          <NavLink key={tab.name} className={navLinkClasses} to={tab.href}>
            {tab.name}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default mobxify(OrderChildTaps, 'orderStore');
