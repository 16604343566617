import { ComponentType } from 'react';
import { inject, observer } from 'mobx-react';

/**
 * @description Wraps a component with mobx's observer and inject
 * @caution This HOC must be at the bottom of the HOC chain
 * @example
 * const MyComponent = () => <div>MyComponent</div>;
 * export default CORRECT otherHOC(mobxify(MyComponent, 'store1', 'store2'));
 * export default INCORRECT mobxify(otherHOC(MyComponent, 'store1', 'store2'));
 */
function mobxify<T>(component: ComponentType<T>, ...storeNames: string[]) {
  return inject(...storeNames)(observer(component));
}

export default mobxify;
