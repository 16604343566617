import { RecipeGroup } from 'models/RecipeGroup.model';
import classNames from 'classnames';
import useQuerySetter from 'util/useQuerySetter';
import { Recipe } from 'models/Recipe.model';
import {
  RecipeModalMode,
  recipeModalTabKey,
  recipeModalTabs,
} from 'util/tabNames';
import ModalName from 'enums/ModalName.enum';
import recipeStore from 'stores/recipe.store';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';
interface PlantRecipeCardProps {
  recipeGroup: RecipeGroup;
  key: number;
  archived: boolean;
  image?: string;
}

const PlantRecipeCard = ({ recipeGroup, key, image }: PlantRecipeCardProps) => {
  const { setQuery } = useQuerySetter();

  const openModal = (recipeGroup: RecipeGroup) => {
    setQuery(
      [
        { term: 'recipeGroup', value: recipeGroup._id },
        {
          term: 'recipe',
          value: (recipeGroup.selected as Recipe)?._id,
        },
        {
          term: recipeModalTabKey,
          value: recipeModalTabs.general,
        },
      ],
      {
        overwriteQuery: true,
      },
    );
    recipeStore?.clearRecipe();
    modalStore?.setRecipeModalMode(RecipeModalMode.VIEW);
    modalStore?.openModal(ModalName.RECIPE_GROUP);
  };

  return (
    <li
      key={key}
      onClick={() => {
        openModal(recipeGroup);
      }}
      className={classNames([
        'bg-gray-100',
        'col-span-1 flex flex-col text-center rounded-lg shadow cursor-pointer hover:shadow-lg min-h-[12rem] overflow-hidden',
      ])}
    >
      <div className={`h-[45%] w-full relative bg-gray-300`}>
        {image && (
          <img
            className="w-full h-full"
            style={{ objectFit: 'cover', display: 'block' }}
            src={image}
          ></img>
        )}
      </div>
      <div className="flex-1 flex flex-col px-4 py-2 cursor-pointer bg-white text-gray-500 justify-center text-center">
        <h3 className="font-bold text-lg truncate">{recipeGroup.name}</h3>
        <div className="relative flex justify-center text-xs ">
          <p className="text-sm truncate">
            {recipeGroup.recipes.length} Recipe
            {recipeGroup.recipes.length > 1 ? 's' : ''}
          </p>
        </div>
        <p className="text-sm  truncate">
          Used in {recipeGroup.orders}
          {recipeGroup.orders === 1 ? ' Order' : ' Orders'}
        </p>
      </div>
    </li>
  );
};

export default mobxify(PlantRecipeCard, 'modalStore', 'recipeStore');
