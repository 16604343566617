import { FC } from 'react';
import { useParams } from 'react-router';
import { CloudAppParams } from 'routes';
import { plantGroupTabKey, plantGroupTabs } from 'util/tabNames';
import PlantRecipe from './PlantRecipe/PlantRecipe';
import Plants from './Plants';
import withLoader from 'util/hocs/withLoader';

const tabComponents = {
  [plantGroupTabs.recipes]: <PlantRecipe />,
  [plantGroupTabs.archive]: <PlantRecipe />,
  [plantGroupTabs.plants]: <Plants />,
};

const PlantGroupTab: FC = () => {
  const params = useParams<CloudAppParams>();
  const currentTab =
    (params[plantGroupTabKey] as plantGroupTabs) || plantGroupTabs.recipes;
  const CurrentComponent = tabComponents[currentTab];

  return CurrentComponent || null;
};
export default withLoader(PlantGroupTab);
