import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

const baseURL = API_URL;
const instance = axios.create({
  baseURL: baseURL,
  timeout: undefined,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('vertics:jwt');
  if (!token) return config;
  if (!config.headers) return config;

  config.headers.Authorization = 'Bearer ' + token;
  const pathSplit = window.location.pathname.split('/');
  if (pathSplit[1]) {
    config.headers['farm-id'] = pathSplit[1];
  }
  return config;
});
// instance.interceptors.request.use((config) => {
//   return new Promise(async (resolve) => {
//     const token = localStorage.getItem('vertics:jwt');
//     if (token && config.headers) {
//       config.headers.Authorization = 'Bearer ' + token;
//       const pathSplit = window.location.pathname.split('/');
//       if (pathSplit[1]) {
//         config.headers['farm-id'] = pathSplit[1];
//       }
//     }
//     resolve(config);
//   });
// });

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    return refreshToken(error);
  },
);

const refreshToken = async (error: any) => {
  const originalRequest = error.config;

  if (
    error.response.status === 401 &&
    originalRequest.url === '/auth/refresh'
  ) {
    return Promise.reject(error);
  }

  error.response.status = 401;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = localStorage.getItem('vertics:refreshToken');

    if (!refreshToken) throw new Error('RefreshToken not found');

    const resp = await axios.post(baseURL + '/auth/refresh-access-token', {
      refreshToken,
    });

    if (resp.status === 201) {
      const token = resp.data.accessToken;

      localStorage.setItem('vertics:jwt', resp.data.accessToken);
      if (token && instance.defaults && instance.defaults.headers) {
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      return instance(originalRequest);
    }

    throw new Error('RefreshToken not found');
  }
  return Promise.reject(error);
};

export default instance;
