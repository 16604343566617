import { FC } from 'react';

interface Props {
  slide: React.ReactNode;
  slideClassName?: string;
}

const Slide: FC<Props> = ({ slide, slideClassName, ...rest }) => {
  return (
    <li className={`glide__slide ${slideClassName || ''}`} {...rest}>
      {slide}
    </li>
  );
};
export default Slide;
