import { TrashIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';
import { RecipeModalMode } from 'util/tabNames';
import ValidatedDurationInput from '../../../DurationInput/RecipeDurationPicker';
import { ValidatedCheckboxLabel } from '../../InputValidation/ValidatedCheckboxLabel';
import { ValidatedInputLabeled } from '../../InputValidation/ValidatedInputLabel';
import { ValidatedInputLabelWithUnits } from '../../InputValidation/ValidatedInputLabelWithUnits';
import AnalyticIntervalInput from './Inputs/AnalyticIntervalInput';
import LightTypeCreatable from './LightTypeCreatable';
import OxygenTypeCreatable from './OxygenTypeCreatable';
import SelectNutritionSolutionDropDown from './SelectNutritionSolutionDropDown';

interface SinglePhaseProps {
  index: number;
  mode: RecipeModalMode;
  remove: any;
}

const SinglePhase = ({ index, mode, remove }: SinglePhaseProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const renderNutritionSolution = () => {
    if (
      watch(`phases.${index}.usedNutritionSolution`) ||
      mode !== RecipeModalMode.VIEW
    ) {
      return (
        <>
          <SelectNutritionSolutionDropDown
            index={index}
            control={control}
            watch={watch}
            isDisabled={mode === RecipeModalMode.VIEW}
            //@ts-ignore
            hasError={errors.phases?.[index]?.usedNutritionSolution}
          />
        </>
      );
    }
    return (
      <>
        <ValidatedInputLabeled
          valueName="nutritionSolution"
          label="Name"
          fieldArrayName="phases"
          index={index}
          rules={{ required: true }}
          className="flex justify-between"
          inputClassName="w-3/4"
          isDisabled={mode === RecipeModalMode.VIEW}
        />
        <ValidatedInputLabeled
          valueName="nutritionRatio"
          label="Ratio"
          fieldArrayName="phases"
          index={index}
          rules={{ required: true }}
          className="flex justify-between mt-4"
          inputClassName="w-3/4"
          isDisabled={mode === RecipeModalMode.VIEW}
        />
        <ValidatedInputLabelWithUnits
          valueName="waterlevel"
          label="Waterlevel"
          unit="mm"
          isNumber={true}
          fieldArrayName="phases"
          index={index}
          rules={{ required: false }}
          className="flex justify-between mt-4"
          inputClassName="w-3/4"
          isDisabled={mode === RecipeModalMode.VIEW}
        />
      </>
    );
  };
  return (
    <div className="flex" id={`phase-${index}`}>
      <div className="w-1/2 p-6">
        <h3 className="text-lg leading-6 font-bold text-gray-700 mb-6 justify-center justify-items-center">
          General
        </h3>
        <ValidatedInputLabeled
          label="Phase"
          isDisabled
          defaultValue={index + 1}
          rules={{}}
          valueName="phaseLabel"
          className="flex justify-between items-center mt-4 "
          inputClassName="w-3/4"
        />
        <ValidatedDurationInput
          index={index}
          isDisabled={mode === RecipeModalMode.VIEW}
        />
        <ValidatedInputLabeled
          valueName="plantsPerTray"
          label="Plants per Tray"
          fieldArrayName="phases"
          index={index}
          isNumber={true}
          defaultValue={352}
          rules={{ required: true, min: 1 }}
          className="flex justify-between items-center mt-4 "
          inputClassName="w-3/4"
          isDisabled={mode === RecipeModalMode.VIEW}
        />
        <LightTypeCreatable
          //@ts-ignore
          hasError={errors.phases?.[index]?.lightType || false}
          index={index}
          control={control}
          watch={watch}
          mode={mode ? mode : RecipeModalMode.VIEW}
        />

        <ValidatedInputLabelWithUnits
          valueName="humidity"
          label="Humidity"
          unit="%"
          placeholder="55"
          fieldArrayName="phases"
          index={index}
          rules={{ required: true, min: 1 }}
          isNumber={true}
          className="flex justify-between items-center mt-4 "
          inputClassName="w-3/4"
          isDisabled={mode === RecipeModalMode.VIEW}
        />
        <div>
          <AnalyticIntervalInput mode={mode} index={index} />
          <div className="flex justify-end">
            <ValidatedCheckboxLabel
              label="Disable Analytics"
              valueName="analyticIntervalDisabled"
              control={control}
              setValue={setValue}
              index={index}
              fieldArrayName="phases"
              className="w-3/4 flex items-start left-5 h-10 mt-2"
              isDisabled={mode === RecipeModalMode.VIEW}
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 border-l">
        <div className="p-6">
          <h3 className="text-lg leading-6 font-bold text-gray-900 mb-6 justify-center justify-items-center">
            Nutrition Solution
          </h3>

          {renderNutritionSolution()}
          <ValidatedInputLabelWithUnits
            valueName="insertLevel"
            label="Insertlevel"
            unit="mm"
            isNumber={true}
            fieldArrayName="phases"
            index={index}
            placeholder="( optional )"
            rules={{ required: false }}
            className="flex justify-between mt-4"
            inputClassName="w-3/4"
            isDisabled={mode === RecipeModalMode.VIEW}
          />

          <OxygenTypeCreatable
            index={index}
            //@ts-ignore
            hasError={errors?.phases?.[index]?.oxygenMethod || false}
            mode={mode || RecipeModalMode.VIEW}
          />
          <ValidatedInputLabelWithUnits
            valueName="environmentTemperature"
            label="Temperature"
            unit="°C"
            placeholder="24"
            fieldArrayName="phases"
            index={index}
            isNumber={true}
            rules={{ required: true }}
            className="flex justify-between mt-4 items-center"
            inputClassName="w-3/4"
            isDisabled={mode === RecipeModalMode.VIEW}
          />
          <ValidatedInputLabelWithUnits
            valueName="lightPlantDistance"
            label="Distance between light and plant"
            unit="cm"
            placeholder="( optional )"
            fieldArrayName="phases"
            index={index}
            rules={{ required: false, min: 1 }}
            isNumber={true}
            //@ts-ignore
            hasError={errors?.phases?.[index]?.humidity ? true : false}
            className="flex justify-between items-center mt-4 "
            inputClassName="w-3/4"
            watch={watch}
            isDisabled={mode === RecipeModalMode.VIEW}
          />
        </div>
      </div>
      {index !== 0 && mode !== RecipeModalMode.VIEW && (
        <button
          onClick={() => {
            remove(index);
          }}
          type="button"
          className="h-8 w-8 mt-4 flex items-center justify-center p-1 right-8 absolute border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};

export default SinglePhase;
