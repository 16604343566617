import { UseFormSetValue } from 'react-hook-form';
import { ValidatedCheckbox } from './ValidatedCheckbox';

interface ValidatedCheckboxProps {
  valueName: string;
  label: string;
  setValue: UseFormSetValue<any>;
  control: any;
  index?: any;
  fieldArrayName?: string;
  isDisabled?: boolean;
  className: string;
}
export const ValidatedCheckboxLabel = ({
  label,
  valueName,
  control,
  className,
  isDisabled = false,
  setValue,
  index,
  fieldArrayName,
}: ValidatedCheckboxProps) => {
  return (
    <div className={className}>
      <ValidatedCheckbox
        valueName={valueName}
        control={control}
        setValue={setValue}
        index={index}
        fieldArrayName={fieldArrayName}
        isDisabled={isDisabled}
      />
      <div className="ml-3 text-sm">
        <label className="font-medium text-gray-700">{label}</label>
      </div>
    </div>
  );
};
