import { action, makeObservable, observable } from 'mobx';
import Axios from 'util/Axios';

export class TypeStore {
  //TODO: create model for lightType.model.ts
  @observable lightTypes: any;
  @observable soilTypes: any;
  @observable oxygenMethods: any;

  constructor() {
    makeObservable(this);
  }

  @action
  async loadOxygenMethods() {
    const response = await Axios.get('/types/oxygen-methods');
    if (response.status === 200) {
      this.oxygenMethods = response.data;
    }
  }

  @action
  async createOxygenMethod(data: any) {
    const response = await Axios.post('/types/oxygen-methods', data);
    if (response.status === 201) {
      return response.data;
    }
  }

  @action
  async loadSoilTypes(query?: string) {
    const response = await Axios.get(`/types/soils${query ? query : ''}`);
    if (response.status === 200) {
      this.soilTypes = response.data;
    }
  }

  @action
  async createSoilType(data: any) {
    const response = await Axios.post('/types/soils', data);
    if (response.status === 201) {
      return response.data;
    }
  }

  @action
  async loadLightTypes() {
    const response = await Axios.get('/types/lights');
    if (response.status === 200) {
      this.lightTypes = response.data;
    }
  }

  @action
  async createLightType(data: any) {
    const response = await Axios.post('/types/lights', data);
    if (response.status === 201) {
      return response.data;
    }
  }
}

const typeStore = new TypeStore();
export default typeStore;
