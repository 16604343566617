import ModalName from 'enums/ModalName.enum';
import GenericModal from '../GenericModal';
import RecipeGroupModalHeader from '../RecipeGroupModal/RecipeGroupModalHeader';
import RecipeForm from './RecipeForm';

const CreateRecipeModal = () => {
  return (
    <GenericModal
      modalName={ModalName.CREATE_PLANT_RECIPE}
      customContainerStyle="recipe-group-modal overflow-hidden"
    >
      <>
        <RecipeGroupModalHeader hideCategories />
        <div className="flex flex-row-reverse h-full">
          <div className="grow pb-52">
            <RecipeForm />
          </div>
        </div>
      </>
    </GenericModal>
  );
};

export default CreateRecipeModal;
