import { Popover, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import Toggle from 'components/toggle/Toggle';
import { OrderGroupModel } from 'models/Order.model';
import { Plant } from 'models/Plant.model';
import { PlantGroup } from 'models/PlantGroup.model';
import { Fragment, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import Select, { MultiValue, SingleValue } from 'react-select';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';

interface FarmOrderFilterDropDownProps {
  dateFrom?: Date;
  dateTo?: Date;
  showArchive: boolean;
  setShowArchive: (state: boolean) => void;
  setDateFrom: (date: Date) => void;
  setDateTo: (date: Date) => void;
  selectedPlantGroup?: PlantGroup;
  setSelectedPlantGroup: (plantGroup: PlantGroup | undefined) => void;
  selectedPlants?: Plant[];
  setSelectedPlants: (plants: Plant[]) => void;
  plantGroups: PlantGroup[];
  plants: Plant[];
  selectedUuids: OrderGroupModel['uuid'][];
  setSelectedUuids: (uuids: OrderGroupModel['uuid'][]) => void;
}

function orderGroupUuidOptions() {
  return (
    orderStore.orderGroupUuids
      ?.map((uuid) => ({
        label: uuid,
        value: uuid,
      }))
      .reverse() || []
  );
}

function ValueToOption(value: any, label?: string) {
  return { label: label || value, value };
}

export interface SelectOption<T> {
  label: string;
  value: T;
}

function MultiToValue<T>(option: MultiValue<T>) {
  return (option as MultiValue<SelectOption<T>>).map((o) => o.value) as T[];
}

function _SingleToValue<T>(option: SingleValue<T>) {
  //@ts-ignore
  return (option as SingleValue<SelectOption<T>>).value;
}
// TODO unbedingt refactoren und state in ein filter Object packen mit dateTo dateFrom etc als keys oder mit react hookform eine form erstellen dafür
const FarmOrderFilter = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  selectedPlantGroup,
  selectedPlants,
  setSelectedPlantGroup,
  setSelectedPlants,
  showArchive,
  setShowArchive,
  plants,
  plantGroups,
  selectedUuids,
  setSelectedUuids,
}: FarmOrderFilterDropDownProps) => {
  const orderGroupUuids = useMemo(orderGroupUuidOptions, [
    orderStore.orderGroupUuids,
  ]);
  const renderOptions = () => (
    <>
      <div className="mb-2">
        <label className="font-medium text-green-700">Orders</label>
        <Select
          inputId="order-uuids"
          className="focus:border-green-400 focus:outline-none"
          classNamePrefix="select"
          value={selectedUuids.map((v) => ValueToOption(v))}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options={orderGroupUuids}
          isMulti
          onChange={(orderGroupUuid) => {
            const values =
              //@ts-ignore
              MultiToValue<OrderGroupModel['uuid']>(orderGroupUuid);
            setSelectedUuids(values);
          }}
        />
      </div>
      <div className="mb-2">
        <label className="font-medium text-green-700">From</label>
        <DatePicker
          id="From"
          className="h-full pl-1 border border-gray-300 rounded-md w-full py-2 outline-none focus:border-green-400"
          placeholderText=" Select a date..."
          selected={dateFrom}
          onChange={(e) => setDateFrom(e!)}
        />
      </div>
      <div className="my-2">
        <label className="font-medium text-green-700">To</label>
        <DatePicker
          id="To"
          className="h-full pl-1 border border-gray-300 rounded-md w-full py-2 outline-none focus:border-green-400"
          selected={dateTo}
          placeholderText=" Select a date..."
          onChange={(e) => setDateTo(e!)}
        />
      </div>
      <div className="my-2">
        <label className="font-medium text-green-700 ">Plant Group</label>
        <Select
          inputId="Plant Group"
          className="focus:border-green-400 focus:outline-none"
          classNamePrefix="select"
          value={
            selectedPlantGroup
              ? ValueToOption(selectedPlantGroup, selectedPlantGroup?.name)
              : undefined
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options={plantGroups?.map((plantgroup) => ({
            label: plantgroup.name,
            value: plantgroup,
          }))}
          isClearable
          //@ts-ignore
          onChange={(e) => {
            if (!e) return setSelectedPlantGroup(undefined);

            setSelectedPlantGroup(e.value);
          }}
        />
      </div>
      <div className="my-2">
        <label className="font-medium text-green-700">Plant</label>
        <Select
          className="focus:border-green-400 focus:outline-none"
          classNamePrefix="select"
          value={selectedPlants?.map((plant) =>
            ValueToOption(plant, plant.name),
          )}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          options={plants?.map((plant) => ({
            label: plant.name,
            value: plant,
          }))}
          isClearable
          isMulti
          inputId="Plant"
          onChange={(e) => {
            //@ts-ignore
            const newPlants = MultiToValue(e);
            //@ts-ignore
            setSelectedPlants(newPlants);
          }}
        />
      </div>
      <Toggle
        value={showArchive}
        setValue={() => setShowArchive(!showArchive)}
        label="Show Deleted Orders"
      />
    </>
  );

  return (
    <Popover as="div" className="inline-block text-left ">
      {() => (
        <>
          <div>
            <Popover.Button>
              <FunnelIcon className="w-7 h-7 ml-4 mr-4 hover:cursor-pointer bg-gray-50 text-gray-400 hover:text-gray-600 mt-1" />
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute z-50 right-5 transform p-4 sm:px-0 drop-shadow-2xl bg-white mt-3 rounded-md">
              <div className="bg-white p-4 max-w-[400px]">
                {renderOptions()}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default mobxify(FarmOrderFilter, 'orderStore');
