import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import SearchBarSortSelect from './PlantRecipeSortSelect';

interface SearchBarProps {
  onChange: (query: string) => void;
  hideSort?: boolean;
  setSort?: any;
  setDateFilter?: any;
  options?: { label: string; value: string }[];
  searchValue?: string;
  exportAction?: () => void;
}

const SearchBar = ({
  onChange,
  hideSort,
  setSort,
  options,
  searchValue,
}: SearchBarProps) => {
  return (
    <div className="sm:col-span-6 2xl:col-span-7 h-10">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="flex h-full">
        <div className="relative flex flex-grow">
          <div
            className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            aria-hidden="true"
          >
            <MagnifyingGlassIcon
              className="mr-3 h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="search"
            id="search"
            value={searchValue || ''}
            className={`focus:outline-none block w-full pl-9 sm:text-sm h-full rounded-md border border-gray-300 ${
              !hideSort ? ' rounded-r-none' : ''
            }`}
            placeholder="Search"
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
        {options && (
          <SearchBarSortSelect options={options} onChange={setSort} />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
