import { useEffect } from 'react';
import GenericModal from '../GenericModal';
import './RecipeGroupModal.styles.css';
import RecipeGroupModalHeader from './RecipeGroupModalHeader';
import RecipeForm from '../RecipeModal/RecipeForm';
import useQuerySetter from 'util/useQuerySetter';

import RecipeGroupComments from './RecipeGroupComments';
import {
  RecipeModalMode,
  recipeModalTabKey,
  recipeModalTabs,
} from 'util/tabNames';
import ModalName from 'enums/ModalName.enum';
import recipeStore from 'stores/recipe.store';
import mobxify from 'util/hocs/mobxify';

const RecipeGroupModal = () => {
  const { watchQuery: recipeTab, query } = useQuerySetter(recipeModalTabKey);

  useEffect(() => {
    const id = query.get('recipeGroup');
    if (id) {
      recipeStore?.getOneRecipeGroup(id);
    }
  }, [query.get('recipeGroup')]);

  useEffect(() => {
    const id = query.get('recipe');
    if (id) {
      recipeStore?.getRecipe(id);
    }
  }, [query.get('recipe')]);

  return (
    <GenericModal
      modalName={ModalName.RECIPE_GROUP}
      customContainerStyle="recipe-group-modal overflow-hidden"
    >
      <>
        <RecipeGroupModalHeader />
        <div className="flex flex-row-reverse h-full">
          <div className="grow pb-6 pt-0">
            {(recipeTab === recipeModalTabs.edit ||
              recipeTab === recipeModalTabs.duplicate) &&
              query.get('recipe') && (
                <>
                  <RecipeForm mode={RecipeModalMode.EDIT} />
                  <div className="h-20"></div>
                </>
              )}

            {recipeTab === 'comments' && (
              <>
                <RecipeGroupComments />
                <div className="h-20"></div>
              </>
            )}

            {(!recipeTab || recipeTab === recipeModalTabs.general) && (
              <RecipeForm mode={RecipeModalMode.VIEW} />
            )}
          </div>
        </div>
      </>
    </GenericModal>
  );
};

export default mobxify(RecipeGroupModal, 'modalStore', 'recipeStore');
