import { PlusIcon } from '@heroicons/react/24/outline';
import ModalName from 'enums/ModalName.enum';
import { FC } from 'react';
import modalStore from 'stores/modal.store';
import mobxify from 'util/hocs/mobxify';

interface Props {
  showAddButton?: boolean;
  title: string;
  modalName: ModalName;
  testid?: string;
}

const SidebarHeader: FC<Props> = ({
  title,
  modalName,
  testid,
  showAddButton = true,
}) => {
  return (
    <div className="flex flex-row border-b pb-3 pt-5 pl-4">
      <h2 className="flex-grow font-bold">{title}</h2>
      {showAddButton && (
        <div className="flex items-center justify-end flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0">
            <button
              onClick={() => {
                modalStore?.openModal(modalName);
              }}
              name="addplants"
              data-testid={testid}
              type="button"
              className="mr-3 relative inline-flex items-center border border-transparent shadow-sm text-sm font-medium rounded-xl text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500  h-full"
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default mobxify(SidebarHeader, 'modalStore');
