import SideLayout from 'components/SideLayout/SideLayout';
import { Outlet } from 'react-router-dom';
import NutritionSolutionSidebar from './components/NutritionSolutionSidebar';
import withLoader from 'util/hocs/withLoader';

const NutritionSolutionsLayout = () => {
  return (
    <div className="max-w-full min-h-screen w-full">
      <SideLayout sideBar={<NutritionSolutionSidebar />}>
        <Outlet />
      </SideLayout>
    </div>
  );
};

export default withLoader(NutritionSolutionsLayout);
