import { Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import recipeStore from 'stores/recipe.store';
import useQuerySetter from 'util/useQuerySetter';
import NoDataFound from '../../NoDataFound/NoDataFound';
import RecipeGroupSidebar from './RecipeGroupSidebar';
import mobxify from 'util/hocs/mobxify';

const RecipeGroupComments: FC = () => {
  const [commentList, setCommentList] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [recipeGroupId, setRecipeGroupId] = useState<string>();
  const [recipeId, setRecipeId] = useState<string>();
  const [filterActive, setFilterActive] = useState(false);
  const { query } = useQuerySetter();

  useEffect(() => {
    if (!recipeId) return;
    if (showAll) return;
    recipeStore?.getCommentsForRecipe(recipeId);
  }, [recipeId]);

  useEffect(() => {
    const groupId = query.get('recipeGroup');
    if (groupId) {
      setRecipeGroupId(groupId);
    }
    const recipeId = query.get('recipe');
    if (recipeId) {
      setRecipeId(recipeId);
    }
  }, [query.toString()]);

  useEffect(() => {
    if (!recipeStore?.comments) {
      return;
    }
    setCommentList(recipeStore?.comments);
  }, [recipeStore?.comments]);

  useEffect(() => {
    if (!showAll) {
      if (!recipeId) {
        return;
      }
      recipeStore?.getCommentsForRecipe(recipeId);
      setFilterActive(false);
    }
    if (showAll) {
      if (!recipeGroupId) {
        return;
      }
      recipeStore?.getCommentsForRecipeGroup(recipeGroupId);
      setFilterActive(false);
    }
  }, [showAll]);

  const changeOrderFilter = (order: any) => {
    setFilteredList(commentList.filter((e) => e._id === order._id));
    setFilterActive(true);
  };

  const showRecipeGroupToggle = () => {
    return (
      <div className="py-5">
        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={showAll}
            onChange={() => setShowAll(!showAll)}
            className={
              showAll
                ? 'bg-green-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                : 'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
            }
          >
            <span
              aria-hidden="true"
              className={
                showAll
                  ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  : 'translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              }
            />
          </Switch>
          <Switch.Label as="span" className="ml-3">
            <span className="text-sm font-medium text-gray-900">
              Show all versions of recipe{' '}
            </span>
          </Switch.Label>
        </Switch.Group>
      </div>
    );
  };

  const showBadges = () => {
    if (!commentList || commentList.length === 0) {
      return;
    }
    return (
      <div className="">
        {commentList.map((order: any) => (
          <span
            key={order._id}
            className="inline-flex shadow-md hover:shadow-lg items-center px-2 py-0.5 rounded text-xs font-medium bg-green-600 text-white mr-1 cursor-pointer hover:bg-green-700"
            onClick={() => changeOrderFilter(order)}
          >
            {`${order._id}`}
          </span>
        ))}
        {filterActive && (
          <button
            type="button"
            className="bg-white rounded-md"
            onClick={() => {
              setFilterActive(false);
            }}
          >
            <XMarkIcon className="inline-flex items-center px-2 rounded-md text-sm font-medium cursor-pointer text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-10" />
          </button>
        )}
      </div>
    );
  };

  const showUserIcon = (comment: any) => {
    if (comment.user) {
      return (
        <p className="text-white text-lg font-semibold">
          {comment.user
            .split(' ')
            .map((n: string) => n[0])
            .join('')}
        </p>
      );
    }
  };

  const showHeader = (comments: any) => {
    if (!comments || comments.length === 0 || !comments[0].name) {
      return;
    }
    return (
      <div className="px-12 py-6 border-b mb-2 shadow-sm">
        <a className="text-lg font-semibold">Comments for </a>
        <a className="text-green-600 text-xl font-semibold">
          {`${comments[0].name}`}
        </a>
        {showRecipeGroupToggle()}
        {showBadges()}
      </div>
    );
  };
  const showChatFeed = (comments: any) => {
    if (!comments || comments.length === 0) {
      return (
        <NoDataFound
          title="No comments"
          subtitle="No comments could be found for this recipe."
          className="mt-5"
        />
      );
    }
    return (
      <div className="">
        {comments.map((entry: any) => (
          <div
            key={entry._id}
            className="mx-12 rounded-lg shadow-md hover:shadow-xl mb-2 border-2 border-gray-200 bg-gray-50"
          >
            <h1 className="px-10 py-2 border-gray-100 rounded-t-lg border-b font-semibold text-green-600 text-xl bg-white">
              {showAll
                ? `Order ${entry._id} | Recipe Version: ${entry.version}`
                : `Order ${entry._id}`}
            </h1>
            <div className="flex justify-between relative overflow-auto px-10 ">
              <div className="flow-root w-full mt-6">
                <ul role="list" className="mb-2 ">
                  {entry.comments.map((comment: any, commentIdx: number) => (
                    <li key={commentIdx}>
                      <div className="relative pb-6">
                        {commentIdx !== entry.comments.length - 1 ? (
                          <span
                            className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <span
                            className={
                              ' h-10 w-10 rounded-full flex items-center justify-center ring-4 ring-gray-50 bg-yellow-500'
                            }
                          >
                            <div>{showUserIcon(comment)}</div>
                          </span>
                          <div className="min-w-0 flex-1 flex justify-between space-x-4">
                            <div>
                              <a>{comment.comment.comment}</a>
                              <p className="text-sm text-gray-500">
                                {`${comment.user}, ${dayjs(
                                  comment.comment.createdAt,
                                ).format('HH:mm DD.MM.')}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="relative flex flex-row-reverse h-full">
        <RecipeGroupSidebar />
        <div className="border-r grow h-full overflow-scroll">
          {showHeader(commentList)}
          {!filterActive && showChatFeed(commentList)}
          {filterActive && showChatFeed(filteredList)}
        </div>
      </div>
    </>
  );
};

export default mobxify(RecipeGroupComments, 'recipeStore');
