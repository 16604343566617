import {
  AnalyticDataType,
  AnalyticsDataTypeKey,
} from 'models/AnalyticsDataType.model';

const getTypeForKey = (
  keyMappings: AnalyticDataType[],
  key: AnalyticsDataTypeKey,
): AnalyticDataType => {
  for (const mapping of keyMappings) {
    if (mapping.key === key) {
      return mapping;
    }
  }
  return {
    key: key,
    value: key,
    showInDiagram: true,
    initiallyShown: false,
  };
};

export { getTypeForKey };
