import {
  WEBSOCKETCHANNELS,
  WEBSOCKETCOMMANDS,
} from 'constants/Websocket.constants';
import { OrderModel } from 'models/Order.model';
import { OrderStore } from 'stores/order.store';
import SocketHandler from '../SocketHandler';
import { WebsocketMessage } from './types';

interface OrderWebsocketReponse {
  data: {
    order: OrderModel['_id'];
  };
}

export class OrderWebsocket {
  private orderStore: OrderStore;
  private organizationId = '';

  constructor(orderStore: OrderStore, organizationId: string) {
    this.orderStore = orderStore;
    this.organizationId = organizationId;
    this.startWebsocket();
  }

  private startWebsocket() {
    SocketHandler.instance()
      .getSocket()
      .on(
        `${this.organizationId}_${WEBSOCKETCHANNELS.CHAT}`,
        (...args: WebsocketMessage<OrderWebsocketReponse>[]) => {
          for (const obj of args) {
            if (obj.command === WEBSOCKETCOMMANDS.UPDATED) {
              this.updateChatForOrder(obj);
            }
          }
        },
      );
  }

  private async updateChatForOrder(obj: OrderWebsocketReponse) {
    await this.orderStore?.getOrder(obj.data.order);
  }
}
