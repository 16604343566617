import { Controller, useFormContext } from 'react-hook-form';
import Creatable from 'react-select/creatable';
import whiteSpaceToDash from 'util/whiteSpaceToDash';

interface ValidatedCreatableProps {
  valueName: string;
  index?: number;
  onCreate: (input: string) => void;
  options?: any;
  rules: any;
  hasError: boolean;
  className?: string;
  fieldArrayName?: string;
  isDisabled?: boolean;
  name: string;
}

export const ValidatedCreatable = ({
  index,
  onCreate,
  options,
  rules,
  valueName,
  hasError,
  fieldArrayName,
  className,
  isDisabled = false,
  name,
}: ValidatedCreatableProps) => {
  const { control } = useFormContext();
  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: isDisabled
        ? '#fafafa'
        : hasError
        ? 'rgb(254 242 242)'
        : 'white',
      borderRadius: '0.375rem',
      borderColor: hasError ? 'rgb(254 100 100)' : 'rgb(210 210 210)',
      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    }),
  };

  return (
    <div className={className}>
      <Controller
        name={
          fieldArrayName ? `${fieldArrayName}.${index}.${valueName}` : valueName
        }
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          return (
            <Creatable
              inputId={whiteSpaceToDash(name)}
              maxMenuHeight={150}
              isDisabled={isDisabled}
              value={value}
              classNames={{
                option: () => 'select__option',
                control: () => 'select__control',
                menuList: () => 'select__menu',
                input: () => 'select__input',
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option._id}
              styles={colourStyles}
              onChange={(selected) => onChange(selected)}
              onCreateOption={(input) => {
                onCreate(input);
              }}
              options={options}
            />
          );
        }}
      />
    </div>
  );
};
