import userStore from 'stores/user.store';

interface LoginDto {
  email: string;
  password: string;
  rememberMe: boolean;
}
export const signIn = async (loginDto: LoginDto) => {
  const user = await userStore?.login(loginDto.email, loginDto.password);
  if (!user) return;

  if (loginDto.rememberMe) {
    localStorage.setItem('vertics:email', loginDto.email);
  }

  return true;
};
