import Grid from 'components/Grid/Grid';
import OrderGroupCard from '../CurrentOrderGroups/OrderGroupCard';
import NoDataFound from '../NoDataFound/NoDataFound';
import mobxify from 'util/hocs/mobxify';

interface OrderOverviewProps {
  orderGroups: any;
  farmId?: string;
  search?: string;
  isLoading?: boolean;
}

const OrderOverview = ({
  orderGroups,
  isLoading = false,
}: OrderOverviewProps) => {
  return (
    <>
      {orderGroups.length !== 0 && (
        <Grid padding="p-2" gap="gap-4">
          {orderGroups.map((orderGroup: any, key: number) => (
            <OrderGroupCard
              key={key}
              orderGroup={orderGroup}
              hideProgress
              hideAmount
            />
          ))}
        </Grid>
      )}
      {orderGroups.length === 0 && !isLoading && (
        <NoDataFound
          title="No orders"
          subtitle="No orders could be found for this search"
          className="p-6"
        />
      )}
    </>
  );
};

export default mobxify(OrderOverview, 'userStore');
