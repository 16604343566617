import RecipeGroupSidebarItem from 'components/RecipeGroupSidebarItem/RecipeGroupSidebarItem';
import { Recipe } from 'models/Recipe.model';
import { useMemo, useState } from 'react';
import { RecipeStore } from 'stores/recipe.store';
import mobxify from 'util/hocs/mobxify';
interface VersionSelectProps {
  archived: boolean;
  recipeStore?: RecipeStore;
}

const VersionSelect = ({ archived, recipeStore }: VersionSelectProps) => {
  const MAX_AMOUNT_DISPLAYED_DEFAULT = 4;

  const [displayedAmount, setDisplayedAmount] = useState(
    MAX_AMOUNT_DISPLAYED_DEFAULT,
  );
  const recipeInfo = useMemo(() => {
    const filteredRecipes = (recipeStore?.recipeGroup?.recipes as Recipe[])
      ?.filter((r: Recipe) => (archived ? r.archived : !r.archived))
      .reverse();

    if (filteredRecipes) {
      return {
        displayedRecipes: filteredRecipes.slice(0, displayedAmount),
        trueLength: filteredRecipes.length,
      };
    }
  }, [recipeStore?.recipeGroup?.recipes, displayedAmount]);

  if (!recipeInfo || recipeInfo.displayedRecipes.length === 0) return null;
  return (
    <div className="flex justify-center flex-col mb-4">
      <h3 className="text-md leading-6 font-medium text-gray-900 mb-2 ">
        {archived ? 'Archived' : 'Active'} Versions
      </h3>
      <div className="bg-white shadow sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {recipeInfo.displayedRecipes.map((recipe, index) => {
            const selectedRecipeId =
              typeof recipeStore?.recipeGroup?.selected === 'string'
                ? recipeStore?.recipeGroup?.selected
                : (recipeStore?.recipeGroup?.selected as Recipe)?._id;

            const isFavorite = recipe._id === selectedRecipeId;

            return (
              <RecipeGroupSidebarItem
                key={index}
                isFirst={index === 0}
                isLast={recipeInfo.displayedRecipes.length - 1 === index}
                isFavorite={isFavorite}
                recipe={recipe}
              />
            );
          })}
        </ul>
      </div>
      {displayedAmount < recipeInfo.trueLength && (
        <button
          className="flex justify-center items-center mt-2 text-gray-500 hover:text-gray-700"
          onClick={(e) => {
            e.preventDefault();
            setDisplayedAmount(recipeInfo.trueLength + 1);
          }}
        >
          Show All
        </button>
      )}

      {displayedAmount > MAX_AMOUNT_DISPLAYED_DEFAULT && (
        <button
          className="flex justify-center mt-2 text-gray-500 hover:text-gray-700"
          onClick={(e) => {
            e.preventDefault();
            setDisplayedAmount(MAX_AMOUNT_DISPLAYED_DEFAULT);
          }}
        >
          Hide
        </button>
      )}
    </div>
  );
};

export default mobxify(VersionSelect, 'recipeStore');
