import { OrderMetaDataStatus, OrderModel } from 'models/Order.model';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { CloudAppParams } from 'routes';
import orderStore from 'stores/order.store';
import mobxify from 'util/hocs/mobxify';

interface TreemapElementProps {
  order: OrderModel;
  height: number;
}

const TreemapElement = ({ order, height }: TreemapElementProps) => {
  const { farmId } = useParams<CloudAppParams>();
  const isDeleted =
    order.metaData?.status === OrderMetaDataStatus.CANCELED || false;

  const isHighlighted = !!orderStore.highlightedOrders.find(
    (o) => o._id === order._id,
  );
  const elementHeight = Math.trunc(height);
  const elementStyle = `p-1 cursor-pointer flex items-center justify-center hover:bg-green-600/50 transition-all hover:font-bold ${
    isDeleted ? 'text-gray-400 bg-red-200/75' : 'text-black'
  } ${isHighlighted && 'bg-green-600/20'}`;

  const handleOnMouseOver = async (order: OrderModel) => {
    await orderStore.highlightOrderAndParents(order);
  };
  const handleOnMouseLeave = () => {
    orderStore.resetHighlighted();
  };

  return (
    <Link
      to={`/${farmId}/order-details/${orderStore.currentOrderGroup?._id}/order/${order._id}/analytics`}
      onMouseOver={async () => await handleOnMouseOver(order)}
      onMouseLeave={() => handleOnMouseLeave()}
      className={elementStyle}
      style={{ height: `${elementHeight}px` }}
    >
      {order.uuid}
    </Link>
  );
};

export default mobxify(TreemapElement, 'orderStore');
