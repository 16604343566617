import classNames from 'classnames';
import PlantSelectDropDown from './PlantSelectDropDown/PlantSelectDropDown';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CloudAppParams } from 'routes';
import plantStore from '../../stores/plant.store';
import {
  plantGroupTabKey,
  plantGroupTabs,
  plantTabNames,
} from '../../util/tabNames';
import mobxify from 'util/hocs/mobxify';

const PlantGroupTabSelect = () => {
  const params = useParams<CloudAppParams>();
  const currentTab = params[plantGroupTabKey] || plantGroupTabs.recipes;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-full h-full bg-gray-50">
      <div className="relative border-b h-[110px] border-gray-200 sm:pb-0 px-4 bg-white pt-4 flex items-end">
        <div className="sm:hidden">
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
            defaultValue={''}
          >
            {plantTabNames.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
          <div className="flex items-center"></div>
        </div>
        <div className="hidden sm:block">
          <div
            className="flex flex-row items-center mb-4 justify-between"
            style={{ paddingTop: 1, paddingBottom: 1 }}
          >
            <h1 className="text-xl font-bold leading-7 text-gray-900 sm:truncate pl-1 flex items-center">
              {plantStore?.plantGroupName}
            </h1>
            <PlantSelectDropDown plantName={plantStore?.plantGroupName || ''} />
          </div>
          <nav className="-mb-px flex space-x-8">
            {plantTabNames.map((tab) => (
              <button
                onClick={() => {
                  navigate({
                    pathname: location.pathname.replace(currentTab, tab.value),
                    search: location.search,
                  });
                }}
                key={tab.name}
                className={classNames(
                  currentTab === tab.value
                    ? 'border-green-500 text-green-600 cursor-pointer'
                    : 'border-transparent text-gray-500 hover:text-gray-700 cursor-pointer hover:border-gray-300',
                  'whitespace-nowrap pb-4 px-1 border-b-2 cursor-pointer font-medium text-sm',
                )}
                aria-current={currentTab ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="h-[calc(100vh-110px)]">
        <Outlet />
      </div>
    </div>
  );
};

export default mobxify(PlantGroupTabSelect, 'plantStore');
