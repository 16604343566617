import { ValidatedInputLabeled } from '../ValidatedInputLabel';
interface AmountInputProps {
  maxAmount: number;
}
const OrderAmountInput = ({ maxAmount }: AmountInputProps) => {
  return (
    <ValidatedInputLabeled
      valueName="amount"
      label="Amount"
      isNumber={true}
      rules={{ required: true, min: 1, max: maxAmount }}
      className="flex justify-between mt-2 mb-5"
      inputClassName="w-3/4"
      placeholder={`Available: ${maxAmount}`}
    />
  );
};

export default OrderAmountInput;
