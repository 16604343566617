import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import plantStore from 'stores/plant.store';
import typeStore from 'stores/type.store';

export const checkIfItemAlreadyExists = (
  value: string,
  type: WAREHOUSE_ITEM_TYPE,
) => {
  let warehouseItems;
  if (type === WAREHOUSE_ITEM_TYPE.PLANT) {
    warehouseItems = plantStore.allPlants;
  } else if (type === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
    warehouseItems = typeStore.lightTypes;
  } else if (type === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
    warehouseItems = typeStore.oxygenMethods;
  } else if (type === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
    warehouseItems = typeStore.soilTypes;
  }

  if (!warehouseItems) throw Error('No WarehouseItems in Store.');

  const alreadyExists = warehouseItems.find(
    (item: any) => item.label === value,
  );

  return alreadyExists;
};

export const dependendLoader = async (type: WAREHOUSE_ITEM_TYPE) => {
  if (type === WAREHOUSE_ITEM_TYPE.PLANT) {
    await plantStore.getAllPlants();
  } else if (type === WAREHOUSE_ITEM_TYPE.SOIL_TYPE) {
    await typeStore.loadSoilTypes();
  } else if (type === WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD) {
    await typeStore.loadOxygenMethods();
  } else if (type === WAREHOUSE_ITEM_TYPE.LIGHT_TYPE) {
    await typeStore.loadLightTypes();
  }
};
