import { AxiosError } from 'axios';
import { Comment } from 'components/modal/OrderModal/Events/EventUtils';
import { BloodlineEntry } from 'components/modal/OrderModal/Hierarchy/Hierarchy';
import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';
import { AnalyticsData } from 'models/AnalyticsData.model';
import { OrderGroupModel, OrderModel } from 'models/Order.model';
import { Plant } from 'models/Plant.model';
import { ObjectId, PlantGroup } from 'models/PlantGroup.model';
import { findParents } from 'pages/OrderPage/orderPageHelper';
import { toast } from 'react-toastify';
import Axios from 'util/Axios';
import { getQueryStringFromFilters } from 'util/OrderUtil';
interface CreateOrderGroupDto {
  orders: {
    plantGroup: { _id: string; name: string };
    plant: { _id: string; name: string };
    recipe: ObjectId;
    amount: number;
    startPhase?: number;
  }[];
  amount: number;
  target: string;
  description?: string;
  startDate: Date;
}

export interface OrderFilter {
  search?: string;
  dateFrom?: Date;
  dateTo?: Date;
  plantGroup?: PlantGroup['_id'];
  plants?: Plant['_id'][];
  showArchive: boolean;
  orderGroups?: OrderGroupModel['uuid'][];
  skip: number;
  limit: number;
}

function sortAnalyticsOfOrderGroups(order: OrderGroupModel) {
  if (!order.analytics) return order;

  order.analytics = order.analytics.sort(
    (a: AnalyticsData, b: AnalyticsData) =>
      new Date(b.data.date).getTime() - new Date(a.data.date).getTime(),
  );

  return order;
}

export class OrderStore {
  @observable orderGroups: OrderGroupModel[] = [];
  @observable currentOrderGroupId?: string;
  @observable currentOrderGroup?: OrderGroupModel;

  @observable comments: Comment[] = [];
  @observable bloodline: BloodlineEntry[] = [];
  @observable pendingOrderGroups: OrderGroupModel[] = [];

  @observable orderPageHighlightedOrders: OrderModel[] = [];
  @observable orderPageSelectedOrder?: OrderModel;
  @observable orderGroupUuids?: OrderGroupModel['uuid'][];
  @observable currentOrder?: OrderModel;
  @observable highlightedOrders: OrderModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getOrderGroupUuids() {
    const response = await Axios.get(`/order-groups/uuids`);

    if (response.status === 200) {
      const orderGroupsUuids: OrderGroupModel['uuid'][] = response.data?.map(
        (oG: Partial<OrderGroupModel>) => oG.uuid,
      );
      if (!orderGroupsUuids) throw new Error('No order groups found');
      this.orderGroupUuids = orderGroupsUuids;
    }
  }

  @action
  async highlightOrderAndParents(order: OrderModel) {
    if (!order) return;
    if (!this.currentOrderGroup)
      throw new Error('No current order group selected, impossible State!');

    const relatedOrders: OrderModel[] = findParents(
      order,
      this.currentOrderGroup.orders,
    );

    this.currentOrder = order;
    this.highlightedOrders = relatedOrders;
  }

  @action
  resetHighlighted() {
    this.highlightedOrders = [];
  }

  @action
  async getBloodline(orderGroup: string, selectedOrder?: string) {
    //I could have called it "getAncestry" but that wouldn't be as funny - Taner says: DEPP
    const response = await Axios.get(`/orders/${orderGroup}/bloodline`, {
      params: selectedOrder ? { selectedOrder } : {},
    });
    if (response.status === 200) {
      this.bloodline = response.data;
    }
  }

  @action
  async getRunningOrderGroups() {
    const response = await Axios.get(`/order-groups/current`);

    if (response.status === 200) {
      const orderGroups = response.data?.map(sortAnalyticsOfOrderGroups);
      if (!orderGroups) throw new Error('No order groups found');
      this.orderGroups = orderGroups;
    }
  }

  @action
  async getOrderGroups() {
    const response = await Axios.get(`/order-groups?populate=orders`);
    if (response.status === 200) {
      this.setOrderGroups(response.data.reverse());
    }
  }

  @action
  async getOrdersForPlant(plantId: string) {
    const response = await Axios.get(`/farms/${plantId}/orders`);

    if (response.status === 200) {
      this.setOrderGroups(response.data);
    }
  }

  @action
  async deleteOneOrderGroup(orderGroupId: string) {
    try {
      const response = await Axios.delete(`/order-groups/${orderGroupId}`);
      if (response.status === 200) {
        toast.success('Order has been deleted.');
      } else {
        toast.error('Order has not been deleted.');
      }
      await this.getRunningOrderGroups();
    } catch (error) {
      toast.error('Order has not been deleted.');
    }
  }

  //TODO: DEFINE CREATE ORDER DTO
  @action
  async createOrderGroup(orderGroup: CreateOrderGroupDto) {
    try {
      await Axios.post(`/order-groups`, orderGroup);
      toast.success('Order created successfully.');
      return true;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Order has not been created.');
      }
    }
  }

  @action
  async capacityCheck(farmId: string, order: CreateOrderGroupDto) {
    try {
      const response = await Axios.post(
        `/farms/${farmId}/orders/capacity-check`,
        order,
      );
      // toast.success("Check successfully.");
      toast.success(response.data.message);
      return response;
    } catch (error) {
      if ((error as AxiosError)?.response?.data.message) {
        toast.error((error as AxiosError).response?.data.message);
      } else {
        toast.error('Error occured at capacity checker');
      }
    }
  }

  @action
  async getOrderFromOrderGroup(orderId: string) {
    try {
      if (!this.currentOrderGroup)
        throw new Error('No current order group selected, impossible State!');

      const order = this.currentOrderGroup.orders.find(
        (o) => o._id === orderId,
      );
      if (!order) throw new Error('Order not found in current order group');

      this.currentOrder = order;
      return order;
    } catch (error) {
      // fallback
      await this.getOrder(orderId).catch(toast.error);
    }
  }

  @action
  async getOrder(
    orderId: OrderModel['_id'],
    search: Record<string, string | number> = {},
  ) {
    const response = await Axios.get(`/orders/${orderId}`, {
      params: search,
    });
    if (response.status === 200) {
      this.setOrder(response.data);
    }
  }

  @action
  async addCommentToOrder(order: string, comment: string) {
    try {
      await Axios.post(`/comments`, {
        comment,
        order,
      });
      toast.success('Comment has been created successfully.');
    } catch (error) {
      toast.error('Error on creating comment.');
    }
  }

  @action
  setOrderGroups(orderGroups: OrderGroupModel[]) {
    this.orderGroups = orderGroups;
  }

  @action
  async getComments(orderId: string) {
    const response = await Axios.get(
      `/comments?order=${orderId}&populate=createdBy:user.fullName`,
    );
    if (response.status === 200) {
      this.comments = response.data;
    }
  }

  @action
  async getCommentById(commentId: string, orderId: string) {
    const response = await Axios.get(
      `/orders/${orderId}/comments/${commentId}`,
    );
    if (response.status === 200) {
      return response.data;
    }
  }

  @action
  setOrder(order: OrderModel | undefined) {
    this.currentOrder = order;
  }

  @action
  async getOrderGroup(id: string, setCurrentOrder = false) {
    this.currentOrderGroupId = id;
    const response = await Axios.get(`/order-groups/${id}?populate=orders`);
    if (response.status !== 200) return;

    this.currentOrderGroup = response.data;
    this.highlightedOrders = [];

    if (setCurrentOrder && response.data.orders.length > 0) {
      this.currentOrder = response.data.orders[0];
    }
  }

  @action
  async loadOrdersForInfiniteScroll(filters: OrderFilter) {
    const response = await Axios.get(getQueryStringFromFilters(filters), {
      /*
      todo use query params instead of query string generator
       {
        params: {
          ...
        }
      } */
    });

    if (response.status === 200) {
      return response.data;
    }
  }

  @action
  async loadOrdersForExcelExport(filters: OrderFilter) {
    const response = await Axios.get(
      getQueryStringFromFilters({
        ...filters,
        limit: 100000,
        skip: 0,
      }),
    );

    if (response.status === 200) {
      return response.data;
    }
  }

  @action
  async getPendingOrders() {
    const response = await Axios.get(`/order-groups/pending`);
    if (response.status === 200) {
      this.pendingOrderGroups = response.data;
    }
  }

  @action
  async setCurrentOrderOnLoader(orderId: string) {
    const response = await Axios.get(`orders/${orderId}`);

    if (response.status === 200) {
      this.setOrder(response.data);
    }
  }

  async exportOrdersToExcel(orderIds: string[]) {
    const response = await Axios.post(
      `/order-groups/export`,
      {
        orderIds,
      },
      {
        responseType: 'blob',
        timeout: 1000 * 90,
      },
    );
    if (response.status === 201) {
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      link.download = `export-analytics-${dayjs().format(
        'YYYY-MM-DD-HH-mm',
      )}.xlsx`;
      link.click();
    }
  }
}

const orderStore = new OrderStore();
export default orderStore;
