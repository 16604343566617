import { Fragment, useEffect, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { getTypeForKey } from 'util/KeyMappers';
import { generateColorBasedOnString } from 'util/ColorGenerator';
import { AnalyticsDataValuesInterface } from 'models/AnalyticsData.model';
import analyticStore from 'stores/analytics.store';
import mobxify from 'util/hocs/mobxify';

interface FilterDropDownProps {
  filters: any;

  onFilterChanged: (name: string, value: boolean) => void;
  selectAll?: (select: boolean) => void;

  options?: {
    labelFormat: {
      value: 'key' | 'value' | 'keyAndValue' | 'none';
      set: (value: 'key' | 'value' | 'keyAndValue' | 'none') => void;
    };
  };
}

const FilterDropDown = ({
  filters,
  onFilterChanged,
  selectAll,
  options,
}: FilterDropDownProps) => {
  useEffect(() => {
    analyticStore?.loadAnalyticDataTypes();
  }, []);

  const allSelected = useMemo(
    () => Object.values(filters as boolean).every((val) => val),
    [filters],
  );

  const renderOptions = () => {
    return Object.keys(filters).map((item: string, key: number) => {
      if (!analyticStore?.analyticDataTypes) return null;
      const tmp = getTypeForKey(
        analyticStore?.analyticDataTypes,
        item as keyof AnalyticsDataValuesInterface,
      );
      return (
        <div className="relative flex items-start" key={key + item}>
          <div className="flex items-center">
            <input
              onChange={(event) => {
                onFilterChanged(item, event.target.checked);
              }}
              /* id="comments"
              aria-describedby="comments-description"
              name="comments"  */
              type="checkbox"
              checked={filters[item]}
              style={{ accentColor: generateColorBasedOnString(item) }}
              className="focus:ring-green-500 h-4 w-4 border-gray-300 rounded text-green-500"
            />
          </div>
          <div
            className="ml-3 text-sm"
            onClick={() => {
              onFilterChanged(item, !filters[item]);
            }}
          >
            <label htmlFor="comments" className="font-medium text-gray-700">
              {tmp.value}
            </label>
          </div>
        </div>
      );
    });
  };

  if (!filters) {
    return <>Keine Filter geladen</>;
  }

  return (
    <Popover as="div" className="inline-block text-left">
      {() => (
        <>
          <div>
            <Popover.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
              Filter
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute z-10 right-3 transform p-4 sm:px-0 drop-shadow-2xl bg-white mt-3 rounded-md">
              {Object.keys(filters).length > 0 && (
                <>
                  {selectAll && (
                    <div className="bg-white py-2 px-4">
                      <div className="relative flex items-start" key="all">
                        <div className="flex items-center">
                          <input
                            onChange={(event) => {
                              selectAll(event.target.checked);
                            }}
                            type="checkbox"
                            checked={allSelected}
                            style={{
                              accentColor: 'grey',
                            }}
                            className="focus:ring-green-500 h-4 w-4 border-gray-300 rounded text-green-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            All
                          </label>
                        </div>
                      </div>{' '}
                    </div>
                  )}
                  <div className="bg-white p-4">{renderOptions()}</div>
                  <div className="divide-x-2"></div>
                  {options && (
                    <div className="bg-white p-4">
                      <div
                        className="relative flex items-start"
                        key="showLabels"
                      >
                        <div className="flex items-center">
                          <input
                            onChange={() => {
                              if (options.labelFormat.value === 'value') {
                                options.labelFormat.set('keyAndValue');
                              }
                              if (options.labelFormat.value === 'keyAndValue') {
                                options.labelFormat.set('value');
                              }
                              if (options.labelFormat.value === 'key') {
                                options.labelFormat.set('none');
                              }
                              if (options.labelFormat.value === 'none') {
                                options.labelFormat.set('key');
                              }
                            }}
                            type="checkbox"
                            checked={options.labelFormat.value.includes('key')}
                            style={{
                              accentColor: 'green',
                            }}
                            className="focus:ring-green-500 h-4 w-4 border-gray-300 rounded text-green-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Show Labels in Graph
                          </label>
                        </div>
                      </div>
                      <div
                        className="relative flex items-start"
                        key="showValues"
                      >
                        <div className="flex items-center">
                          <input
                            onChange={() => {
                              if (options.labelFormat.value === 'key') {
                                options.labelFormat.set('keyAndValue');
                              }
                              if (options.labelFormat.value === 'keyAndValue') {
                                options.labelFormat.set('key');
                              }
                              if (options.labelFormat.value === 'value') {
                                options.labelFormat.set('none');
                              }
                              if (options.labelFormat.value === 'none') {
                                options.labelFormat.set('value');
                              }
                            }}
                            type="checkbox"
                            checked={options.labelFormat.value
                              .toLocaleLowerCase()
                              .includes('value')}
                            style={{
                              accentColor: 'green',
                            }}
                            className="focus:ring-green-500 h-4 w-4 border-gray-300 rounded text-green-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Show Values in Graph
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {Object.keys(filters).length === 0 && (
                <div className="bg-white">Keine Filter geladen</div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default mobxify(FilterDropDown, 'analyticStore');
