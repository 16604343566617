import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  setExternalState: (state: any) => void;
}

export const Dropzone = ({ setExternalState }: DropzoneProps) => {
  const [isHover, setIsHover] = useState(false);
  const [file, setFile] = useState<any>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;
    setFile(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setExternalState(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(file.preview);
  }, []);

  const style: CSSProperties = {
    width: '250px',
    height: '250px',
    backgroundColor: '#eeeeee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    border: isHover ? '1px solid #a0aec0' : '1px dashed #a0aec0',
    cursor: 'pointer',
  };

  const showInputBox = () => {
    if (!file) return <div></div>;
    if (file.length === 0)
      return (
        <p className="text-gray-00 font-small flex text-center p-6">
          Drag a new image here, or click to select one!
        </p>
      );
    return (
      <img
        className="w-[250px] h-[250px] rounded-md object-cover shadow-md"
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
    );
  };

  return (
    <div
      {...getRootProps({
        style,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
    >
      <input
        {...getInputProps({ accept: 'image/*', id: 'input-dropzone' })}
        name="image-upload"
      />
      {isDragActive ? (
        <p className="text-gray-500 font-medium">Drop the image here...</p>
      ) : (
        showInputBox()
      )}
    </div>
  );
};
