import { ValidatedInput } from './ValidatedInput';

interface ValidatedInputWithUnitsProps {
  valueName: string;
  unit: string;
  rules: any;
  index?: number;
  fieldArrayName?: string;
  isDisabled?: boolean;
  className?: string;
  isNumber?: boolean;
  inputClassName?: string;
  placeholder?: string;
}
export const ValidatedInputWithUnits = ({
  valueName,
  isDisabled = false,
  unit,
  rules,
  fieldArrayName,
  index,
  className,
  isNumber,
  placeholder,
  inputClassName,
}: ValidatedInputWithUnitsProps) => {
  return (
    <>
      <div className={'relative ' + className}>
        <ValidatedInput
          index={index}
          fieldArrayName={fieldArrayName}
          valueName={valueName}
          className={inputClassName}
          isDisabled={isDisabled}
          rules={rules}
          isNumber={isNumber}
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-3 flex items-center pointer- ">
          <span className="text-gray-500 sm:text-sm">in {unit}</span>
        </div>
      </div>
    </>
  );
};
