import { ArchiveBoxIcon } from '@heroicons/react/20/solid';
import ModalName from 'enums/ModalName.enum';
import GenericModal from '../GenericModal';
import { useState } from 'react';
import modalStore from 'stores/modal.store';
import recipeStore from 'stores/recipe.store';
import mobxify from 'util/hocs/mobxify';

const ArchiveRecipeModal = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const archived = modalStore?.customData?.isArchived || false;

  const handleArchiving = async () => {
    const recipeGroupId = modalStore?.customData.recipeGroupId;

    if (!recipeGroupId) return;

    setDisabled(true);

    await recipeStore?.archiveGroup(recipeGroupId, archived);

    setDisabled(false);
    modalStore?.closeModal();
  };

  return (
    <GenericModal modalName={ModalName.ARCHIVE_RECIPE}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {archived ? 'Unarchive' : 'Archive'} this Recipe Group
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Do you really want to {archived ? 'unarchive' : 'archive'} this
            recipe group?
          </p>
        </div>
        <div className="mt-5 flex justify-end">
          <button
            onClick={() => {
              modalStore?.closeModal();
            }}
            type="button"
            name="cancel"
            className="mr-4 inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
          >
            Cancel
          </button>
          <button
            onClick={async () => {
              handleArchiving();
            }}
            type="button"
            className={`inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md  bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm ${
              disabled && 'cursor-wait'
            }`}
          >
            <ArchiveBoxIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />{' '}
            {archived ? 'Unarchive' : 'Archive'} recipe
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default mobxify(ArchiveRecipeModal, 'modalStore', 'recipeStore');
