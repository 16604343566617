import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Gleap from 'gleap';

import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './util/ErrorBoundary';

import 'react-tooltip/dist/react-tooltip.css';

if (process.env.NODE_ENV !== 'development') {
  Gleap.initialize('s3iOVgU62UvZCmwTPykHqchkhKTOp08d');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
