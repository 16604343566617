import { OrderModel } from 'models/Order.model';

export const findNextGeneration = (
  orders: OrderModel[],
  previousGeneration: OrderModel[],
): OrderModel[] => {
  if (orders.length === 0) return [];
  if (previousGeneration.length === 0) return orders.filter(_orderHasNoParents);

  const nextGeneration: OrderModel[] = orders.filter(
    _orderIsInNextGeneration(previousGeneration),
  );

  return nextGeneration;
};

export const generateTreemap = (allOrders?: OrderModel[]): OrderModel[][] => {
  let availableOrders: OrderModel[] | undefined = allOrders;

  if (!availableOrders) return [];
  if (availableOrders.length === 0) return [];

  const treeMap: OrderModel[][] = [];
  let oldGeneration: OrderModel[] = [];
  while (availableOrders.length > 0) {
    const newGeneration = findNextGeneration(availableOrders, oldGeneration);

    if (newGeneration.length === 0 && availableOrders.length > 0)
      throw new Error('Some orders are not correctly connected');

    oldGeneration = newGeneration;
    availableOrders = availableOrders.filter(_notInArray(newGeneration));
    treeMap.push(newGeneration);
  }

  return treeMap;
};

export const findParents = (
  referenceOrder: OrderModel,
  allOrders: OrderModel[],
  includeReferenceOrder = false,
) => {
  if (!referenceOrder.parents) return [];
  if (referenceOrder.parents.length === 0) return [];

  let referencedOrders = [referenceOrder];
  const orderParents: OrderModel[] = [];

  while (referencedOrders.length > 0) {
    const parents = allOrders.filter((o) =>
      referencedOrders.some((rO) => rO.parents?.includes(o._id)),
    );

    orderParents.push(...parents);
    referencedOrders = parents;
  }

  if (includeReferenceOrder) return orderParents;

  return orderParents.filter((o) => o._id !== referenceOrder._id);
};

function _orderHasNoParents(order: OrderModel) {
  return !order.parents || order.parents.length === 0;
}
function _orderIsInNextGeneration(previousGeneration: OrderModel[]) {
  return (order: OrderModel) =>
    previousGeneration.some((pG) => pG.children?.includes(order._id));
}
function _notInArray<T>(array: T[], key?: keyof T) {
  return (value: T) =>
    !array.find((a) => (key ? a[key] === value[key] : a === value));
}
