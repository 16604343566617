export enum WEBSOCKETCOMMANDS {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export enum WEBSOCKETCHANNELS {
  ANALYTICS = 'AnalyticsController',
  CHAT = 'ChatController',
  PLANT = 'PlantController',
  PLANT_GROUP = 'PlantGroupsController',
  ORDER = 'OrderController',
  ORDER_GROUP = 'OrderGroupController',
  FARM = 'FarmController',
  WAREHOUSE = 'WarehouseController',
  RECIPE = 'RecipeController',
  RECIPE_GROUP = 'RecipeGroupController',
  NUTRITION_SOLUTION = 'NutritionSolutionController',
  NUTRITION_SOLUTION_GROUP = 'NutritionsolutionGroupController',
}
