import { XMarkIcon } from '@heroicons/react/24/outline';
import CreateWarehouseItemDto from 'dtos/CreateWarehouseItem.dto';
import ModalName from 'enums/ModalName.enum';
import WAREHOUSE_ITEM_TYPE from 'enums/WarehouseItemType.enum';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { useParams } from 'react-router';
import { CloudAppParams } from 'routes';
import warehouseStore from 'stores/warehouse.store';
import GenericModal from '../GenericModal';
import { ValidatedInputLabeled } from '../InputValidation/ValidatedInputLabel';
import WarehouseModalOption from './WarehouseModalOption';
import modalStore from 'stores/modal.store';
import plantStore from 'stores/plant.store';
import typeStore from 'stores/type.store';
import mobxify from 'util/hocs/mobxify';

const WarehouseModal = () => {
  const methods = useForm();
  const params = useParams<CloudAppParams>();
  const itemType = params?.itemType as WAREHOUSE_ITEM_TYPE;
  const [loading, setLoading] = useState(false);
  const editMode = !!modalStore?.customData;

  const createNewItem = async (data: FieldValues) => {
    const item: CreateWarehouseItemDto = {
      name: data.selectedItem.label,
      alertOn: data.alertOn,
      type: itemType,
      amount: data.amount,
    };
    switch (itemType) {
      case WAREHOUSE_ITEM_TYPE.PLANT:
        item['plant'] = data.selectedItem._id;
        break;
      case WAREHOUSE_ITEM_TYPE.SOIL_TYPE:
        item['soilType'] = data.selectedItem._id;
        break;
      case WAREHOUSE_ITEM_TYPE.OXYGENATION_METHOD:
        item['oxygenMethod'] = data.selectedItem._id;
        break;
      case WAREHOUSE_ITEM_TYPE.LIGHT_TYPE:
        item['lightType'] = data.selectedItem._id;
        break;
      default:
        break;
    }
    await warehouseStore.create(item);
  };
  const loadForm = () => {
    if (modalStore?.customData.plant) {
      methods.setValue('selectedItem', {
        label: modalStore?.customData.name,
        value: modalStore?.customData.plant,
      });
    }
    if (modalStore?.customData.soilType) {
      methods.setValue('selectedItem', {
        label: modalStore?.customData.name,
        value: modalStore?.customData.soilType,
      });
    }
    if (modalStore?.customData.oxygenMethod) {
      methods.setValue('selectedItem', {
        label: modalStore?.customData.name,
        value: modalStore?.customData.oxygenMethod,
      });
    }
    if (modalStore?.customData.lightType) {
      methods.setValue('selectedItem', {
        label: modalStore?.customData.name,
        value: modalStore?.customData.lightType,
      });
    }
    if (modalStore.customData.amount) {
      methods.setValue('amount', modalStore.customData.amount);
    }
    if (modalStore.customData.alertOn) {
      methods.setValue('alertOn', modalStore.customData.alertOn);
    }
  };

  useEffect(() => {
    if (modalStore?.currentModal !== ModalName.WAREHOUSE) return;
    if (editMode) {
      loadForm();
    } else {
      plantStore?.getAllPlants();
      typeStore?.loadSoilTypes();
    }
  }, [modalStore?.currentModal]);

  const closeModal = () => {
    methods.reset();
    modalStore?.closeModal();
  };

  const onSubmit = async (data: FieldValues) => {
    if (!itemType) throw new Error('No warehouse type provided');
    setLoading(true);
    if (editMode)
      await warehouseStore?.update(modalStore?.customData._id, data);
    else {
      await createNewItem(data);
    }
    setLoading(false);
    closeModal();
  };

  return (
    <GenericModal formMethods={methods} modalName={ModalName.WAREHOUSE}>
      <div>
        <div className="p-5">
          <div className="w-full flex justify-end">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={() => {
                closeModal();
              }}
              aria-selected={true}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div>
            <h1 className="text-2xl font-bold leading-tight text-gray-900">
              {editMode ? 'Edit a Warehouse Item' : 'Add Item to Warehouse'}
            </h1>
            <p>Select an item from the list and fill in the amount of items.</p>
          </div>
          <FormProvider {...methods}>
            <form className="mt-5" onSubmit={methods.handleSubmit(onSubmit)}>
              <WarehouseModalOption isDisabled={editMode} />
              <ValidatedInputLabeled
                label="Amount"
                valueName="amount"
                isNumber
                labelStyle="1/4"
                className="w-full flex justify-between mt-3"
                inputClassName="w-3/4"
                rules={{ require: true, min: 1 }}
              />
              <ValidatedInputLabeled
                label="Alert on"
                valueName="alertOn"
                className="w-full flex justify-between mt-3"
                inputClassName="w-3/4"
                isNumber
                rules={{ require: true, min: 1 }}
              />
              <div className="flex justify-end mt-5">
                <button
                  disabled={loading}
                  type="submit"
                  className={`relative inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                    loading && 'cursor-wait'
                  }`}
                >
                  {loading && (
                    <>
                      <Loader type="Puff" color="#fff" height={16} width={16} />
                    </>
                  )}
                  {!loading && <>Save</>}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </GenericModal>
  );
};

export default mobxify(
  WarehouseModal,
  'modalStore',
  'plantStore',
  'warehouseStore',
  'typeStore',
);
