import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/logo.png';
import { signIn } from './login.helper';
import mobxify from 'util/hocs/mobxify';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem('vertics:email');
    if (email) {
      setEmail(email);
    }

    const rememberMe = localStorage.getItem('vertics:rememberMe');
    if (rememberMe) {
      setRememberMe(rememberMe === 'true' ? true : false);
    }
  }, []);

  useEffect(() => {
    const jwt = localStorage.getItem('vertics:jwt');

    if (!jwt) return;
    navigate('/', { replace: true });
  }, []);

  const buttonDisabled = useMemo(
    () => email === '' || password === '',
    [email, password],
  );

  return (
    <div className=" h-screen w-full flex justify-center items-center">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <img src={logo} alt="logo" />
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  value={password}
                  type="password"
                  onKeyUp={async (event) => {
                    if (event.key !== 'Enter') return;
                    const success = await signIn({
                      email,
                      password,
                      rememberMe,
                    });

                    if (!success) return;
                    // navigate('/', { replace: true });
                    window.location.reload();
                  }}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(event.target.checked);
                    localStorage.setItem(
                      'vertics:rememberMe',
                      '' + event.target.checked,
                    );
                  }}
                  className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <div className="font-medium text-green-600 hover:text-green-500">
                  Forgot your password?
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={buttonDisabled}
                onClick={async () => {
                  const success = await signIn({
                    email,
                    password,
                    rememberMe,
                  });
                  if (!success) return;

                  // navigate('/', { replace: true });
                  window.location.reload();
                }}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                  buttonDisabled
                    ? 'bg-gray-400 hover:bg-gray-600 cursor-not-allowed'
                    : 'bg-green-600 hover:bg-green-700'
                }`}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mobxify(Login, 'userStore');
