import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export enum LoaderSize {
  Small = 20,
  Medium = 50,
}
interface LoadingScreenProps {
  color?: string;
  size?: LoaderSize;
}
const LoadingScreen = ({
  color = '#16a34a',
  size = LoaderSize.Medium,
}: LoadingScreenProps) => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <Loader type="Puff" color={color} height={size} width={size} />
    </div>
  );
};

export default LoadingScreen;
